//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { dictionaryMixin } from '@/mixins'
export default {
  name: 'StepperProgressBar',
  mixins: [dictionaryMixin],
  props: {
    steps: {
      type: [Array, String],
      required: false,
      default: () => []
    },
    currentStep: {
      type: Number,
      required: false,
      default: () => 0
    },
    stopLabel: {
      type: String,
      required: false,
      default: () => 'Stap'
    },
    highestStepIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      internalCurrentStep: 0,
      lastVisitedStep: 0,
      stepMargin: {
        mobile: 1.32,
        desktop: 0.57
      },
      additionalProgress: {
        mobile: 10,
        desktop: 8
      }
    }
  },
  computed: {
    computedSteps() {
      const steps = this.steps.map(
        (step, index) => ({
          text: step,
          ariaCurrent: index === this.internalCurrentStep ? 'step' : null,
          isCompleted: index <= this.lastVisitedStep
        }),
        this
      )
      steps.push({
          text: null,
          ariaCurrent: null
        })
      return steps
    },
    stepperClass() {
      return 'stepper__progressbar'
    },
    currentStepText() {
      return this.steps?.[this.currentStep]
    },
    mobilePortrait() {
      return typeof window === 'undefined' ? false : window.matchMedia('(max-width: 600px)').matches
    },
    stepMarginRatio() {
      const margin = this.mobilePortrait
        ? this.stepMargin?.mobile
        : this.stepMargin?.desktop
      return this.currentStep * margin
    },
    additionalProgressRatio() {
      return this.mobilePortrait
        ? this.additionalProgress?.mobile
        : this.additionalProgress?.desktop
    },
    stepsProgressRate() {
      const totalSteps = this.steps?.length
      const progressWidth = (this.currentStep / totalSteps * 100).toFixed(2)
      const progressRate = (progressWidth - this.stepMarginRatio) + this.additionalProgressRatio
      return progressRate >= 100 ? 100 : progressRate.toFixed(2)
    },
    styleVars() {
      return {
        '--stepper-progress-rate': this.stepsProgressRate + '%'
      }
    },
    isConfirmationStep() {
      return this.currentStep === this.steps?.length
    },
    getProgressRateClass() {
      const mainClassName = 'progress-rate' 
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--completed`]: this.isConfirmationStep
      }
      
    },
  },
  watch: {
    currentStep(step) {
      this.internalCurrentStep = step
      if(step > this.lastVisitedStep) {
        this.lastVisitedStep = step
      }
    }
  },
  mounted() {
    this.internalCurrentStep = this.currentStep
    this.lastVisitedStep = this.highestStepIndex
  },
  methods: {
    selectCurrentStep(index) {
      this.$emit('navigation', index)
      this.internalCurrentStep = index
    },
    getStepClassList(step, index) {
      const mainClassName = `${this.stepperClass}__stop`
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--visited`]: index < this.internalCurrentStep && !this.isConfirmationStep,
        [`${mainClassName}--completed`]: step?.isCompleted || this.isConfirmationStep,
        [`${mainClassName}--confirmation`]: index === this.steps.length && this.isConfirmationStep
      }
    },
    getCounterText(step, index) {
      return step.text ? index + 1 : ''
    },
    moveToStep(index) {
      if(index < this.internalCurrentStep && !this.isConfirmationStep)
        this.selectCurrentStep(index)
    },
    moveToPreviousStep(event) {
      event.preventDefault()
      if(this.currentStep > 0)
        this.selectCurrentStep(this.currentStep - 1)
    },
    moveToNextStep(event) {
      event.preventDefault()
      if(this.lastVisitedStep > this.currentStep)
        this.$emit('navigateStepToNext')
    }
  }
}
