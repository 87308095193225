import { pathOr } from 'ramda'
import Defaults from '../constants/defaults'

export const dictionaryMixin = {
  inject: ['dictionaryService'],
  methods: {
    phrase(key, fallback) {
      return this.phrases[key] || fallback || 'Dit veld bevat errors'
    }
  },
  computed: {
    phrases() {
      return pathOr(Defaults.defaultPhrases, ['dictionaryService', 'phrases'], this)
    }
  }
}
