//
//
//
//
//
//

export default {
  name: 'JwPlayer',
  props: {
    placeholder: { type: String, required: true },
    playerId: { type: String, required: true }
  }
}
