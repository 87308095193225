//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading } from '@/components/elements/CycleHeading'
import { CycleButtonLink } from '@/components/cycle-link'
import { CycleHtml } from '@/components/base'

export default {
  name: 'CycleHeaderPanel',
  components: {
    CycleHeading,
    CycleHtml,
    CycleButtonLink
  },
  props: {
    title: { type: String },
    badgeText: { type: String },
    bodyText: { type: String },
    links: { type: Array },
    alternative: {
      type: Boolean,
      default: false
    },
    panelFooterContent: { type: String }
  },
  computed: {
    hasLinks() {
      return this.links?.length > 0
    },
    classList() {
      return {
        ['panel']: true,
        ['panel--hero']: !this.alternative,
        ['panel--hero-heading']: this.alternative
      }
    },
    isFooterVisible() {
      return !this.alternative && (!!this.panelFooterContent || this.hasLinks)
    },
    headerClassName() {
      return {
        ['panel__header']: true,
        ['panel__header--badge']: !!this.badgeText
      }
    }
  },
  methods: {
    href(item) {
      return item?.href
    },
    target(item) {
      return item?.target
    }
  }
}
