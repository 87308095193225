//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleCurrency, CycleDate } from '../../filters'
import { intlMixin } from '../../mixins'

const isAmountRegex = new RegExp('^amount$', 'i')
const isDateRegex = new RegExp('^date$', 'i')

export default {
  name: 'CycleReceipt',
  filters: { CycleCurrency, CycleDate },
  mixins: [intlMixin],
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    heading: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    highlighted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    receiptClass() {
      const _className = 'receipt'
      return {
        [_className]: true,
        [`${_className}--highlighted`]: this.highlighted
      }
    },
    receiptItems() {
      return this.data
    }
  },
  methods: {
    isAmount(type) {
      return isAmountRegex.test(type)
    },
    isDate(type) {
      return isDateRegex.test(type)
    },
    valueItemClass(item) {
      const _className = 'receipt__value'
      return {
        [_className]: true,
        [`${_className}--price`]: item.Type === 'amount' && item.value !== null
      }
    },
    labelClass(item) {
      const _className = 'receipt__label'
      return {
        [_className]: true,
        [`${_className}--highlighted`]: item.packages
      }
    }
  }
}
