//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleHtml from '../../../base/CycleHtml'
import { CycleCurrency } from '../../../../filters/cycle-currency/cycle-currency'
import { intlMixin } from '../../../../mixins/intl'
import ModalTooltip from '@/components/molecules/ModalTooltip'

export default {
  name: 'CycleCheckoutItem',
  filters: {
    CycleCurrency
  },
  components: { ModalTooltip, CycleHtml },
  mixins: [intlMixin],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    price: {
      type: [String, Number],
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    modelValue: {
      type: [Array, Boolean],
      required: false
    }
  },
  computed: {
    checked() {
      if (this.multiple) {
        return this.modelValue.includes(this.value) || this.selected
      }
      return this.modelValue || this.selected
    },
    multiple() {
      return this.value !== undefined
    },
    name() {
      return this.$vnode.data.model ? this.$vnode.data.model.expression : ''
    }
  },
  methods: {
    control(event) {
      if (/tooltip/gi.test(event.target.className) || event.target.readonly) {
        event.preventDefault()
      }
    },
    updateValue(event) {
      const isChecked = event.target.checked
      let newValue = isChecked
      if (this.multiple) {
        newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
      }
      this.$emit('change', newValue)
    }
  }
}
