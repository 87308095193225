//
//
//
//
//
//
//
//
//

/* eslint-disable no-useless-escape */

import { debounce } from 'lodash'
import { uuid4 } from '@/services/UUID'
import { document } from '@/globals'

function generateGuid() {
  return uuid4()
}

export default {
  name: 'IframeClean',
  props: {
    href: {
      type: String,
      required: true
    },
    formMethod: {
      type: String,
      required: false,
      default: 'GET'
    },
    formPath: {
      type: String,
      required: false
    },
    formParameters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      iframeEl: null,
      iframeLoadedMessage: `IFRAME_LOADED_${generateGuid()}`,
      iframeOnReadyStateChangeMessage: `IFRAME_ON_READ_STATE_CHANGE_${generateGuid()}`,
      iframeId: 'cycle_iframe'
    }
  },
  watch: {
    href() {
      this.reinitIframe(this)
    }
  },
  mounted() {
    this.listenForEvents()
    this.initIframe()
  },
  methods: {
    removeIframe() {
      while (this.$el.firstChild) {
        this.$el.removeChild(this.$el.firstChild)
      }
    },
    setIframeUrl() {
      const iframeDoc = this.iframeEl?.contentWindow?.document
      if (iframeDoc !== undefined) {
        iframeDoc.open().write(
          `
          <body onload="window.location.href='${this.href}'; parent.postMessage('${this.iframeLoadedMessage}', '*')">
          </body>
          `
        )
        iframeDoc.close() //iframe onload event happens
      }
    },
    reinitIframe: debounce((vm) => {
      vm.removeIframe()
      vm.initIframe()
    }, 200),
    initIframe() {
      this.iframeEl = document.createElement('iframe')
      this.iframeEl.setAttribute('id', this.iframeId)
      this.iframeEl.setAttribute('name', this.iframeId)
      this.iframeEl.setAttribute('crossorigin', 'anonymous')
      this.iframeEl.setAttribute('target', '_parent')
      this.iframeEl.setAttribute('style', 'visibility: hidden; position: absolute; top: -99999px; ')
      this.iframeEl.width = '100%'

      this.$el.appendChild(this.iframeEl)

      this.setIframeUrl()
    },
    listenForEvents() {
      window.addEventListener(
        'message',
        (event) => {
          if (event.data === this.iframeLoadedMessage) {
            this.createAndSubmitForm()
            this.$emit('iframe-load')
            this.iframeEl.setAttribute('style', 'visibility: visible;overflow:hidden;')
            this.iframeEl.style.height = '100vh'
          }

          if (event.data === this.iframeOnReadyStateChangeMessage) {
            this.$emit('document-load')
            this.$emit('load')
          }

          if (event.data.height) {
            this.iframeEl.style.height = event.data.height
          }
        },
        false
      )
    },
    createAndSubmitForm() {
      if (this.formPath) {
        const form = document.createElement('form')
        form.setAttribute('target', this.iframeId)
        form.method = this.formMethod
        form.action = this.formPath

        if (this.formParameters) {
          for (const [key, value] of Object.entries(this.formParameters)) {
            const hiddenField = document.createElement('input')
            hiddenField.type = 'hidden'
            hiddenField.name = key
            hiddenField.value = value

            form.appendChild(hiddenField)
          }
        }

        document.body.appendChild(form)
        form.submit()
      }
    }
  }
}
