<template>
  <CycleFormPage :on-submit="handleSubmit" :has-prev-button="false">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.setup.title" />
      </CycleHeading>
    </template>
    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.setup.nextButton" />
      </CycleButton>
    </template>
    <CycleFormSet>
      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.setup.insuredAmount.label" />

          <ProvidedTooltip
            heading-name="steps.setup.insuredAmount.label"
            body-name="steps.setup.insuredAmount.tooltip"
          />
        </template>

        <CycleTextInput
          v-model="formData.insuredAmount"
          required
          integer
          :min-value="validationConstants.minimalInsuredAmount"
          :max-value="validationConstants.maximalInsuredAmount"
        />

        <template #error-minValue>
          {{
            phrase(
              'wedding-funnel-error-message-WE004',
              `Het te verzekeren bedrag moet hoger zijn dan € ${validationConstants.minimalInsuredAmount}.`
            )
          }}
        </template>

        <template #error-maxValue>
          {{
            phrase(
              'wedding-funnel-error-message-WE001',
              `Het te verzekeren bedrag mag niet hoger zijn dan € ${validationConstants.maximalInsuredAmount}.`
            )
          }}
        </template>
      </CycleInputContainer>

      <CycleInputContainer render-list>
        <template #label>
          <ProvidedText name="steps.setup.weddingDate.label" />
        </template>

        <CycleDateInput
          v-model="formData.weddingDate"
          required
          :max="maxStartDate"
          :min="minStartDate"
        />
        <template #error-min>
          {{
            phrase(
              'wedding-funnel-error-message-WE002',
              `De trouwdatum moet minimaal ${validationConstants.minimalWeddingDateInDays} dagen in de toekomst liggen.`
            )
          }}
        </template>
        <template #error-max>
          {{
            phrase(
              'wedding-funnel-error-message-WE003',
              `De datum mag niet meer dan ${validationConstants.maximalWeddingDateInYears} jaar in de toekomst liggen.`
            )
          }}
        </template>
      </CycleInputContainer>

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.setup.weddingCountry.label" />

          <ProvidedTooltip
            heading-name="steps.setup.weddingCountry.label"
            body-name="steps.setup.weddingCountry.tooltip"
          />
        </template>

        <CycleSelect v-model="formData.weddingCountry" required>
          <template #option>
            <CycleOption
              v-for="country in countries"
              :key="country.key"
              :value="country"
              v-text="country.value"
            />
          </template>
        </CycleSelect>
      </CycleInputContainer>

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.setup.weddingPartyCountry.label" />

          <ProvidedTooltip
            heading-name="steps.setup.weddingPartyCountry.label"
            body-name="steps.setup.weddingPartyCountry.tooltip"
          />
        </template>

        <CycleSelect v-model="formData.weddingPartyCountry" required>
          <template #option>
            <CycleOption
              v-for="country in countries"
              :key="country.key"
              :value="country"
              v-text="country.value"
            />
          </template>
        </CycleSelect>
      </CycleInputContainer>
    </CycleFormSet>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.setup.nextButton" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { addDays, addYears, format } from 'date-fns'
import {
  CycleDateInput,
  CycleHeading,
  CycleOption,
  CycleRadioInput,
  CycleSelect,
  CycleTextInput
} from '@/components/elements'
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { dictionaryMixin, intlMixin } from '@/mixins'
import { CycleFormSet } from '@/components/functional'

export default {
  name: 'WeddingStepSetup',
  inject: ['formData', 'textContent', 'validationConstants', 'countries'],
  components: {
    CycleSelect,
    CycleOption,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedTooltip,
    CycleInputContainer,
    CycleRadioInput,
    CycleTextInput,
    CycleDateInput
  },
  mixins: [intlMixin, dictionaryMixin],
  computed: {
    minStartDate() {
      return format(
        addDays(new Date(), this.validationConstants.minimalWeddingDateInDays),
        'yyyy-MM-dd'
      )
    },
    maxStartDate() {
      return format(
        addYears(new Date(), this.validationConstants.maximalWeddingDateInYears),
        'yyyy-MM-dd'
      )
    }
  },
  created() {
    if (typeof this.formData.weddingCountry != Object) {
      this.formData.weddingCountry = this.countries.find(
        (e) => e.key == this.formData.weddingCountry
      )
    }
    if (typeof this.formData.weddingPartyCountry != Object) {
      this.formData.weddingPartyCountry = this.countries.find(
        (e) => e.key == this.formData.weddingPartyCountry
      )
    }
  },
  methods: {
    handleSubmit() {
      const { insuredAmount, weddingDate, weddingCountry, weddingPartyCountry } = this.formData
      this.$emit('submit', {
        insuredAmount,
        weddingDate,
        weddingCountry,
        weddingPartyCountry
      })
    }
  }
}
</script>
