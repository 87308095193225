//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { FamilyType } from '../../constants'

import { ProductPremium } from '../../models'

export default {
  name: 'LiabilityStepOverviewChange',
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  inject: ['formData', 'textContent'],
  mixins: [intlMixin],
  props: {
    familyType: {
      required: true,
      type: String
    },
    insuredAmount: {
      required: true,
      type: String
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    startDate: {
      required: false,
      type: [String, Date]
    }
  },
  computed: {
    nameForFamilyType() {
      return `liability.steps.overview.content.familyType.${this.familyType}`
    },
    isValidFamilyType() {
      return this.familyType === FamilyType.Single || this.familyType === FamilyType.Multiple
    }
  }
}
