<template>
  <CycleWithQueryParam query-key="insurance">
    <template #default="policyNumber">
      <CycleChangePolicy
        v-if="portfolioInfo"
        :text-content="textContent"
        :reasons="types"
        :policy="policy(policyNumber)"
        @submit="handlePolicyChange"
        :submit-state="submitState"
      />
    </template>
  </CycleWithQueryParam>
</template>

<script>
import { pick, omit } from 'lodash'

import { jssMixin } from '../../../mixins'
import { reducePortfolio } from '../../../services/Insurance'
import { CycleWithQueryParam } from '../../base'
import CycleChangePolicy from '../../functional/forms/CycleChangePolicy/CycleChangePolicy'

import portfolioInfo from './queries'
import changePolicy from './changePolicy'

export default {
  name: 'CycleChangePolicySitecore',
  components: { CycleWithQueryParam, CycleChangePolicy },
  mixins: [jssMixin],
  props: {
    // Start translations
    heading: {
      type: String,
      required: false,
      default: ''
    },
    nextButtonText: {
      type: String,
      required: false,
      default: ''
    },
    intro: {
      type: String,
      required: false,
      default: ''
    },
    typeLabel: {
      type: String,
      required: false,
      default: ''
    },
    descriptionLabel: {
      type: String,
      required: false,
      default: ''
    },
    changeDateLabel: {
      type: String,
      required: false,
      default: ''
    },
    changeDateTooltip: {
      type: String,
      required: false,
      default: ''
    },
    postSucceededMessageText: {
      type: String,
      required: false,
      default: 'Bedankt'
    },
    postFailedMessageText: {
      type: String,
      required: false,
      default: 'Probeer het later opnieuw.'
    },
    // End translations
    types: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      portfolioInfo: false,
      submitState: null
    }
  },
  computed: {
    textContent() {
      return {
        ...pick(this, [
          'heading',
          'nextButtonText',
          'intro',
          'descriptionLabel',
          'changeDateLabel',
          'changeDateTooltip',
          'postSucceededMessageText',
          'postFailedMessageText'
        ]),
        reasonLabel: this.typeLabel
      }
    }
  },
  beforeMount() {
    this.preload()
  },
  methods: {
    handlePolicyChange(formValues) {
      this.submitState = this.gql.mutate(changePolicy, {
        formValues: { ...omit(formValues, ['reason']), type: formValues.reason } // because fuck front-end devs, that's why
      })
    },
    policy(policyNumberEncrypted) {
      const {
        sourceSystem: {
          system: { value: sourceSystem }
        },
        policyUniqueIdentifier,
        product: { name: business },
        number: policyNumber
      } = reducePortfolio(this.portfolioInfo)[policyNumberEncrypted]

      return { policyNumber, sourceSystem, business, policyUniqueIdentifier }
    },
    async preload() {
      try {
        this.portfolioInfo = (await this.gql.query(portfolioInfo)).data.portfolioInfo
      } catch {
        // TODO: cry, cry long and hard
      }
    }
  }
}
</script>
