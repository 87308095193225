//
//
//
//
//
//
//
//
//
//
//
//

import CycleCard from '../card/CycleCard'
import CycleHeading from '../elements/CycleHeading/CycleHeading'

export default {
  name: 'CycleConfirmation',
  components: { CycleCard, CycleHeading },
  props: {
    heading: {
      type: String,
      required: false
    }
  }
}
