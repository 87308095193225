
import { size } from 'lodash'

import ProvidedText from './ProvidedText'

export default {
  name: 'TextWithPlaceholder',
  props: {
    placeholderName: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    }
  },
  render(createElement) {
    if (size(this.$slots.default) > 0) {
      return this.$slots.default[0]
    }
    if (!this.text) {
      return createElement(ProvidedText, {
        props: { name: this.placeholderName }
      })
    }

    return this._v(this.text)
  }
}
