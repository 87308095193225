<template>
  <CycleCard>
    <template #header>
      <CycleHeading>
        <ProvidedText name="personalData.header" />
      </CycleHeading>
    </template>

    <template #body>
      <InformationLines>
        <NamedInformation>
          <ProvidedText name="personalData.labels.name" />
          <PersonName :person="person" v-if="person" />
        </NamedInformation>
        <NamedInformation>
          <ProvidedText name="personalData.labels.address" />
          <AddressLine :address="address" v-if="address" />
        </NamedInformation>
        <NamedInformation>
          <ProvidedText name="personalData.labels.dateOfBirth" />
          <PersonDateOfBirth :person="person" v-if="person" />
        </NamedInformation>
        <NamedInformation v-if="showPostalAddress" data-testid="postal-address-data">
          <ProvidedText name="personalData.labels.postalAddress" />
          <AddressLine :address="postalAddress" v-if="postalAddress" />
        </NamedInformation>
      </InformationLines>
    </template>

    <template #footer>
      <CycleNav buttons>
        <CycleButtonLink :href="editRoute.path" modifiers="cta, secondary">
          <ProvidedText name="personalData.labels.edit" />
        </CycleButtonLink>
      </CycleNav>
    </template>
  </CycleCard>
</template>

<script>
/** old */
import { CycleCard } from '@/components/card'
import { CycleHeading } from '@/components/elements'
import { CycleButtonLink } from '@/components/cycle-link'

/** new, but relative */
import { Route, Address, Person } from '@/models'
import { PersonDateOfBirth } from '../../Person'
import ProvidedText from '../../Content/ProvidedText'
import { CycleNav } from '@/components/cycle-nav'
import { AddressLine, InformationLines, NamedInformation, PersonName } from '@/components/atoms'

export default {
  name: 'PersonalData',
  components: {
    AddressLine,
    CycleButtonLink,
    CycleCard,
    CycleHeading,
    CycleNav,
    InformationLines,
    NamedInformation,
    PersonDateOfBirth,
    PersonName,
    ProvidedText
  },
  props: {
    person: {
      type: Person,
      required: false
    },
    address: {
      type: Address,
      required: false
    },
    postalAddress: {
      type: Address,
      required: false
    },
    showPostalAddress: {
      type: Boolean,
      required: false
    },
    dateOfBirth: {
      type: String,
      required: false
    },
    editRoute: {
      type: Route,
      required: true
    }
  }
}
</script>
