export const buildKeyValueParams = (formdata, arr) => {
  const defaultParamsApplicant = [
    {
      key: 'Action',
      value: 'Nieuw'
    },
    {
      key: 'CollectionMethod',
      value: 'VM'
    }
  ]
  const kvApplicant = {
    CarMilage: 'distanceperyear',
    PostalCode: 'zipcode',
    DateOfBirth: 'birthdate',
    ClaimFreeYears: 'claimfreeyears',
    AmountAccessories: 'accessories',
    LicencePlate: 'licenseplate',
    AccountNumber: 'iban',
    SecurityClass: 'carsecurity',
    SignCode: 'signCode'
  }
  const kvCoverages = {
    legalAid: {
      CarAdditionalCoverageLegalAid: 'true',
      CarAdditionalCoverageLegalAidCoverage: 'Basisdekking',
      CarAdditionalCoverageLegalAidDiscount: '0',
      CarAdditionalCoverageLegalAidOwnRisk: '0',
      CarAdditionalCoverageLegalAidReasonForDiscount: ''
    },
    passengerDamage: {
      CarAdditionalCoveragePassengerDamage: 'true',
      CarAdditionalCoveragePassengerDamageDiscount: '0',
      CarAdditionalCoveragePassengerDamageOwnRisk: '0',
      CarAdditionalCoveragePassengerDamageReasonForDiscount: ''
    }
  }
  const kvDeviateDriver = {
    DeviatingRegularDriver: 'true',
    DeviatingRegularDriverDateOfBirth: 'birthdate',
    DeviatingRegularDriverName: 'fullName',
    DeviatingRegularDriverPostalCode: 'zipcode',
    DeviatingRegularDriverAddress: '',
    DeviatingRegularDriverTown: 'city'
  }
  const kvDeviateLicenseOwner = {
    DeviatingLicenceplateHolder: 'true',
    DeviatingLicenceplateHolderDateOfBirth: 'birthdate',
    DeviatingLicenceplateHolderName: 'fullName',
    DeviatingLicenceplateHolderPostalCode: 'zipcode',
    DeviatingLicenceplateHolderAddress: '',
    DeviatingLicenceplateHolderTown: 'city'
  }
  const accountNumberHandler = (value) => value.trim().replace(/ /g, '')
  const addressHandler = (value) =>
    `${value.street} ${value.housenumber} ${value.houseNumberAddition || ''}`

  const applicantParams = arr.map((key) => {
    let paramValue = formdata[kvApplicant[key]]
    switch (key) {
      case 'AccountNumber':
        paramValue = accountNumberHandler(paramValue)
        break
      case 'AmountAccessories':
      case 'ClaimFreeYears':
        paramValue = String(paramValue)
        break
    }
    return {
      key,
      value: paramValue
    }
  })
  const coveragesParams = coveragesParamsMapper(formdata, kvCoverages)
  const driverParams = driverParamsMapper(formdata, kvDeviateDriver, addressHandler)
  const licenseownerParams = licenseownerParamsMapper(
    formdata,
    kvDeviateLicenseOwner,
    addressHandler
  )

  return [
    ...applicantParams,
    ...defaultParamsApplicant,
    ...coveragesParams,
    ...driverParams,
    ...licenseownerParams
  ]
}

export const buildFinalQuestionAnswers = (finalQuestions) => {
  return Object.keys(finalQuestions).reduce((accumulator, item) => {
    // Checkboxes return arrays, for now we only need the radio (string) responses
    let response = finalQuestions[item]
    if (typeof response === 'string') {
      accumulator.push({ number: item, answer: response })
    }
    return accumulator
  }, [])
}

export const buildVehicleObject = (vehicle = {}) =>
  vehicleKeys.reduce(
    (acc, key) =>
      Object.prototype.hasOwnProperty.call(vehicle, key) && vehicle[key] !== null
        ? Object.assign(acc, {
            [key]: typeof vehicle[key] === 'number' ? `${vehicle[key]}` : vehicle[key]
          })
        : acc,
    {}
  )

const vehicleKeys = [
  'amountAccessories',
  'brand',
  'model',
  'version',
  'constructionMonth',
  'constructionYear',
  'weight',
  'loadCapacity',
  'fuelType',
  'catalogValue',
  'listPriceIncludingVAT',
  'dayValue',
  'objectCode',
  'bodyType',
  'acceleration',
  'numberOfDoors',
  'rechargeable',
  'enginePower',
  'roofType',
  'cylinderCapacity',
  'driveTrain',
  'transMission',
  'maximumSpeed',
  'cO2Emissions',
  'aBS',
  'issueDatePart1',
  'issueDatePart2',
  'amountVAT',
  'amountBPM',
  'imported'
]

const coveragesParamsMapper = ({ coverages }, kvCoverages) => {
  let paramsArr = []
  if (coverages.length > 0) {
    coverages.forEach((coverage) => {
      if (coverage) {
        Object.keys(kvCoverages[coverage]).forEach((key) => {
          let paramsValue = kvCoverages[coverage][key]
          paramsArr.push({
            key,
            value: paramsValue
          })
        })
      }
    })
  }
  return paramsArr
}

const driverParamsMapper = ({ regulardriver, driver }, kvDeviateDriver, addressHandler) => {
  let paramsArr = []
  if (!regulardriver && regulardriver !== undefined) {
    Object.keys(kvDeviateDriver).forEach((key) => {
      let paramsValue = driver[kvDeviateDriver[key]]
      if (key === 'DeviatingRegularDriverAddress') {
        paramsValue = addressHandler(driver)
      }
      if (key === 'DeviatingRegularDriver') {
        paramsValue = kvDeviateDriver[key]
      }
      paramsArr.push({
        key,
        value: paramsValue
      })
    })
  }
  return paramsArr
}

const licenseownerParamsMapper = (
  { licenseholder, licenseowner },
  kvDeviateLicenseOwner,
  addressHandler
) => {
  let paramsArr = []
  if (!licenseholder && licenseholder !== undefined) {
    Object.keys(kvDeviateLicenseOwner).forEach((key) => {
      let paramsValue = licenseowner[kvDeviateLicenseOwner[key]]
      if (key === 'DeviatingLicenceplateHolderAddress') {
        paramsValue = addressHandler(licenseowner)
      }
      if (key === 'DeviatingLicenceplateHolder') {
        paramsValue = kvDeviateLicenseOwner[key]
      }
      paramsArr.push({
        key,
        value: paramsValue
      })
    })
  }
  return paramsArr
}
