import { OverviewGroupModel, OverviewEntryTypes } from '../../../shared'

export function payment({ textContent, formData }) {
  return new OverviewGroupModel({
    title: textContent.paymenttypeTitle,
    entries: [
      {
        label: textContent.startdateTitle,
        value: formData.startdate,
        type: OverviewEntryTypes.DATE
      },
      {
        label: textContent.ibanLabel,
        value: formData.iban
      },
      {
        label: textContent.paymenttermLabel,
        value: 'Maand'
      },
      {
        label: textContent.paymenttypeLabel,
        value: 'Automatische Incasso'
      }
    ]
  })
}
