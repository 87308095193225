//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleUidMixin } from '@/mixins'
import { NavigationItem } from '@/components/elements/NavigationItem'

export default {
  name: 'NavigationSubGroup',
  mixins: [CycleUidMixin],
  components: {
    NavigationItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    subgroupHeader: {
      type: String
    }
  },
  computed: {
    hasSubGroupItems() {
      return this.items?.length > 0
    },
    subgroupNavigationToggleId() {
      return `toggle_${this._cycleUid}`
    }
  },
  methods: {
    getHref(item) {
      return item?.fields?.Link?.value?.href || ''
    },
    getTitle(item) {
      return item?.fields?.Title?.value || ''
    },
    isActive(item) {
      return !!item?.fields?.CurrentItem
    }
  }
}
