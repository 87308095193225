//
//
//
//
//
//

import CycleLink from './CycleLink'
import { bemModifiers } from './constants.js'
import CycleBaseComponent from '../base/CycleBaseComponent'

export default {
  name: 'CycleButtonLink',
  components: { CycleLink },
  extends: CycleBaseComponent,
  props: {
    href: {
      type: String,
      required: false,
      default: ''
    },
    target: {
      type: String,
      required: false,
      default: ''
    },
    ctaIcon: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      bem: {
        defaultModifiers: 'primary',
        allowedModifiers: bemModifiers,
        block: 'btn'
      }
    }
  },
  computed: {
    iconClass() {
      return {
        [`btn--cta-icon-${this.ctaIcon}`]: !!this.ctaIcon
      }
    }
  }
}
