<template>
  <p>
    {{ legacyText }} <a :href="href" target="_blank">{{ legacyLinkText }}</a>
  </p>
</template>

<script>
export default {
  name: 'LinkToLegacy',
  props: {
    href: String,
    legacyLinkText: {
      type: String,
      default: 'Log dan in op uw oude omgeving.'
    },
    legacyText: {
      type: String,
      default: 'Wilt u uw verzekering inzien, wijzigen of schade melden?'
    }
  }
}
</script>
