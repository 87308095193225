//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading } from '@/components/elements'
import { CycleFormPage } from '@/components/functional/forms'
import { ProvidedText } from '../Content'

export default {
  name: 'StepFinalQuestions',
  components: {
    CycleFormPage,
    CycleHeading,
    ProvidedText
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    stepTitle: {
      type: String,
      default: 'steps.finalQuestions.title'
    },
    isInvalid: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
