<template>
  <CycleFormConfirmation>
    <template #heading>{{ errorTitle }}</template>
    {{ failureText }}
  </CycleFormConfirmation>
</template>
<script>
import CycleFormConfirmation from '../CycleFormConfirmation/CycleFormConfirmation'
import { dictionaryMixin } from '../../../../mixins'
export default {
  name: 'CycleGraphqlErrorNotification',
  components: { CycleFormConfirmation },
  mixins: [dictionaryMixin],
  computed: {
    failureText() {
      return this.phrase('error-messages-funnels-001')
    },
    errorTitle() {
      return this.phrase('error-messages-general-001')
    }
  }
}
</script>
