//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LanguageSwitch from '../../language-switch/LanguageSwitch'

export default {
  name: 'NavigationItem',
  components: {
    LanguageSwitch
  },
  props: {
    href: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    showAsButton: {
      type: Boolean,
      required: false
    },
    isLanguageSwitch: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    menuItemClass() {
      return {
        navigation__item: true,
        'navigation__item--login': this.showAsButton,
        'navigation__item--active': this.isActive
      }
    }
  },
  methods: {
    closeMenu() {
      if (this.href.includes('#')) {
        const mobileMenuCheckbox = document?.querySelector('.nav-toggle--hamburger')
        if (mobileMenuCheckbox) {
          mobileMenuCheckbox.checked = false
        }
      }
    }
  }
}
