export const isJSON = (text) => {
  if (typeof text !== 'string') {
    return false
  }
  try {
    const json = JSON.parse(text)
    return !!json && typeof json === 'object'
  } catch (error) {
    return false
  }
}
