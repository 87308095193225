//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleFormSet from '../../../forms/CycleFormSet/CycleFormSet'
import { CycleInputContainer } from '../../../forms/CycleInputContainer'
import { CycleTextInput, CycleRadioInput } from '../../../../elements'
import { CycleDateInput } from '../../../../elements/forms'
import CyclePostcodeCheckDriver from './CyclePostcodeCheckDriver'
import { pathOr } from 'ramda'
import { dictionaryMixin } from '../../../../../mixins'

export default {
  name: 'RegularDriver',
  components: {
    CycleFormSet,
    CContainer: CycleInputContainer,
    CDate: CycleDateInput,
    CText: CycleTextInput,
    CRadio: CycleRadioInput,
    CyclePostcodeCheckDriver
  },
  mixins: [dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
    min: {
      type: [Date, String],
      default: ''
    },
    max: {
      type: [Date, String],
      default: ''
    }
  },
  data() {
    return {
      postcodeFound: undefined
    }
  },
  methods: {
    checkPostcode() {
      if (this.$jss) {
        this.$jss.gql
          .queryByName('addressFromPostalCodeAndHouseNumber', {
            inputModel: {
              postalCode: this.formData.driver.zipcode,
              houseNumber: this.formData.driver.housenumber
            }
          })
          .then((res) => {
            const foundStreet = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'street'],
              res
            )
            const foundCity = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'town'],
              res
            )
            if (foundStreet && foundCity) {
              this.postcodeFound = true
              this.formData.driver.street = foundStreet
              this.formData.driver.city = foundCity
            } else {
              this.postcodeFound = false
            }
          })
          .catch(() => {
            this.postcodeFound = false
          })
      } else {
        this.postcodeFound = false
      }
    }
  }
}
