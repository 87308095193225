let storage

if (typeof window !== 'undefined' && window.localStorage) {
  storage = window.localStorage
} else {
  const store = {}
  storage = {
    setItem: (key, value) => {
      store[key] = value
    },
    getItem: (key) => {
      return store[key]
    },
    removeItem: (key) => {
      delete store[key]
    }
    // TODO: implement more of the API as the need arises. -- Maarten Bicknese 16/7/19
  }
}

export default storage
