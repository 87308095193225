<template>
  <CycleSimpleForm
    :form-submit-action="submitForm"
    :has-next-button="true"
    :next-button-text="textContent.sendFormLabel"
  >
    <CycleHtml :html="textContent.formIntroText" />
    <hr />
    <CycleFormSet :heading="textContent.personalInfoHeading">
      <CycleInputContainer :label="textContent.nameLabel">
        <CycleTextInput required v-model="formData.name" />
      </CycleInputContainer>
      <CycleInputContainer :label="textContent.insuredNumberLabel">
        <CycleTextInput
          required
          min-length="7"
          max-length="10"
          numeric
          v-model="formData.insuredNumber"
        />
      </CycleInputContainer>
      <CycleInputContainer :label="textContent.dateOfBirthLabel">
        <CycleDateInput required date v-model="formData.dateOfBirth" />
      </CycleInputContainer>

      <CycleInputContainer :label="textContent.postalCodeLabel">
        <CycleTextInput
          required
          postcode
          v-model="formData.postalCode"
          :placeholder="textContent.postalCodePlaceholder"
        />
      </CycleInputContainer>
      <CycleInputContainer :label="textContent.houseNumberLabel">
        <CycleTextInput required numeric v-model="formData.houseNumber" />
        <CycleTextInput
          v-model="formData.houseNumberAddition"
          :placeholder="textContent.houseNumberAdditionPlaceholder"
        />
      </CycleInputContainer>
    </CycleFormSet>
    <CycleFormSet :heading="textContent.contactInfoHeading">
      <CycleInputContainer :label="textContent.phoneNumberLabel">
        <CycleNumberInput v-model="formData.phoneNumber" id="phone" required phone />
      </CycleInputContainer>
      <CycleInputContainer :label="textContent.emailAddressLabel">
        <CycleTextInput required email v-model="formData.emailAddress" />
      </CycleInputContainer>
      <CycleInputContainer :label="textContent.emailAddressToValidateLabel">
        <CycleTextInput
          required
          email
          :same-as="'emailAddress'"
          v-model="formData.emailAddressToValidate"
        />
      </CycleInputContainer>
    </CycleFormSet>
    <CycleFormSet :heading="textContent.paymentMethodInfoHeading">
      <CycleInputContainer :label="textContent.paymentMethodLabel">
        <CycleRadioInput
          required
          :label="textContent.incassoOptionText"
          v-model="formData.paymentMethod"
          value="DirectDebit"
        />
        <CycleRadioInput
          required
          :label="textContent.selfPaymentOptionText"
          v-model="formData.paymentMethod"
          value="WireTransfer"
        />
      </CycleInputContainer>
      <CycleHtml v-if="incassoPaymentChosen" :html="textContent.incassoInfoText" />
      <CycleHtml v-if="selfPaymentChosen" :html="textContent.selfPaymentInfoText" />
    </CycleFormSet>
    <CycleFormSet :heading="textContent.paymentInfoHeading" v-if="incassoPaymentChosen">
      <CycleInputContainer>
        <template slot="label">
          {{ textContent.ibanLabel }}
          <CycleTooltip slot="label">
            <template slot="tooltip-heading">
              {{ textContent.ibanTooltipHeading }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.ibanTooltipText" />
            </template>
          </CycleTooltip>
        </template>
        <CycleTextInput
          required
          iban
          v-model="formData.iban"
          :placeholder="textContent.ibanPlaceholder"
        />
      </CycleInputContainer>
      <CycleInputContainer>
        <template slot="label">
          {{ textContent.bicLabel }}
          <CycleTooltip slot="label">
            <template slot="tooltip-heading">{{ textContent.bicTooltipHeading }}</template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.bicTooltipText" />
            </template>
          </CycleTooltip>
        </template>
        <CycleTextInput bic v-model="formData.bic" :placeholder="textContent.bicPlaceholder" />
      </CycleInputContainer>
    </CycleFormSet>
    <template #confirmation-confirmed>
      <CycleHtml :html="textContent.postSucceededMessageText" />
    </template>
    <template #confirmation-rejected>
      <CycleHtml :html="textContent.postFailedMessageText" />
    </template>
  </CycleSimpleForm>
</template>

<script>
import { pathOr } from 'ramda'
import { logError } from '../../services/Logging'
import CycleConfirmation from '../cycle-confirmation/CycleConfirmation'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import CycleSimpleForm from '../functional/forms/CycleSimpleForm/CycleSimpleForm'
import CycleButton from '../elements/CycleButton/CycleButton'
import CycleRadioInput from '../elements/forms/CycleRadioInput/CycleRadioInput'
import { CycleDateInput } from '../elements/forms'
import CycleInputContainer from '../functional/forms/CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../elements/forms/CycleNumberInput/CycleNumberInput'
import CycleFormSet from '../functional/forms/CycleFormSet/CycleFormSet'
import CycleTooltip from '../cycle-tooltip/CycleTooltip'
import CycleHtml from '../base/CycleHtml'

export default {
  name: 'CyclePremiumPaymentArrangementRequest',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleTextInput,
    CycleRadioInput,
    CycleNumberInput,
    CycleButton,
    CycleSimpleForm,
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleConfirmation,
    CycleTooltip
  },
  props: {
    textContent: { type: Object, required: true },
    formSubmitAction: { type: Function, required: true }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        name: null,
        insuredNumber: null,
        dateOfBirth: null,
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        phoneNumber: null,
        emailAddress: null,
        emailAddressToValidate: null,
        paymentMethod: null,
        iban: null,
        bic: null
      },
      isFormPosted: null,
      postSucceeded: null
    }
  },
  computed: {
    incassoPaymentChosen() {
      return this.formData.paymentMethod === 'DirectDebit'
    },
    selfPaymentChosen() {
      return this.formData.paymentMethod === 'WireTransfer'
    }
  },
  methods: {
    submitForm() {
      this.isFormPosted = true
      return this.formSubmitAction(this.formData)
        .then((response) => {
          this.postSucceeded = pathOr(
            false,
            ['data', 'requestPremiumPaymentArrangement', 'success'],
            response
          )
        })
        .catch((err) => {
          this.postSucceeded = false
          logError(err)
        })
    }
  }
}
</script>
