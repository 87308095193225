//
//
//
//
//
//
//
//

import { CycleSimpleNavigation } from '@/components/organisms'
import { labels, labelProps } from './labels'
import { intlMixin } from '@/mixins'
import { pick } from 'lodash'

export default {
  name: 'CycleSimpleNavigationSitecore',
  provide() {
    return { formData: this.formData, textContent: this.textContent }
  },
  components: {
    CycleSimpleNavigation
  },
  mixins: [intlMixin],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    isRenderList: {
      type: Boolean
    },
    isTileNavigation: {
      type: Boolean,
      default: false
    },
    queryKey: {
      type: String
    },
    ...labelProps
  },
  data() {
    return {
      formData: {}
    }
  },
  computed: {
    queryString() {
      return this.$route.query[this.queryKey]
    },
    navigationOptions() {
      const navigationOptions = this.options
        .filter((option) => option.fields && option.fields.link && option.fields.link.value)
        .map((option) => option.fields.link.value)
      if (!this.queryString) {
        return navigationOptions
      }

      const isLocaleActiveRegEx = new RegExp(`${this.locale}`)
      const addLocale = (inputUrl, outputURL) => {
        if (isLocaleActiveRegEx.test(inputUrl)) {
          return outputURL
        }

        return `/${this.locale}${outputURL}`
      }

      return navigationOptions.map((value) => ({
        ...value,
        href: addLocale(
          value.href,
          `${value.href}?${this.queryKey}=${encodeURIComponent(this.queryString)}`
        )
      }))
    },
    textContent() {
      return {
        ...pick(this, labels)
      }
    }
  }
}
