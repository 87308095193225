<template>
  <li :class="menuItemClass">
    <input
      type="radio"
      class="nav-toggle"
      :id="navigationToggleInputId"
      :name="navigationHiddenInputId"
    />
    <label
      :class="classNameForLabel"
      :for="navigationToggleInputId"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <span>{{ name }}</span></label
    >
    <ul class="navigation__sub" v-if="hasAnySubItems">
      <template v-if="hasNavigationItemsWithSubItems">
        <li class="navigation__item">
          <label class="nav-trigger nav-trigger--back" :for="navigationToggleInputId">
            {{ name }}</label
          >
        </li>
        <NavigationGroup
          v-for="item in navigationItemsWithSubItems"
          :key="item.id"
          :items="getItems(item)"
          :group-link="getGroupLink(item)"
        />
      </template>
      <template v-if="hasNavigationItemsWithNoSubItems">
        <li class="navigation__item">
          <label class="nav-trigger nav-trigger--back" :for="navigationToggleInputId">
            {{ name }}</label
          >
        </li>

        <NavigationItem
          v-for="item in navigationItemsWithNoSubItems"
          :key="item.id"
          :href="href(item)"
          :title="title(item)"
          :show-as-button="false"
          :is-active="isActive(item)"
        />
      </template>
    </ul>
  </li>
</template>

<script>
import { CycleUidMixin } from '@/mixins'
import { NavigationItem } from '@/components/elements/NavigationItem'
import NavigationGroup from './NavigationGroup.vue'

export default {
  name: 'SubNavigationItem',
  mixins: [CycleUidMixin],
  components: {
    NavigationGroup,
    NavigationItem
  },
  props: {
    name: {
      type: String,
      required: true
    },
    navigationHiddenInputId: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    navigationItemHasAnActiveSubItem() {
      return this.navigationItemsWithSubItems?.some((navigationGroup) =>
        navigationGroup?.fields?.items?.some((navigationSubGroup) =>
          navigationSubGroup?.fields?.items?.some(this.isActive)
        )
      )
    },
    menuItemClass() {
      const mainClassName = 'navigation__item'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--dropdown`]: true,
        [`${mainClassName}--active`]: this.navigationItemHasAnActiveSubItem,
        [`${mainClassName}--login`]: this.hasNavigationItemsWithNoSubItems
      }
    },
    classNameForLabel() {
      return {
        'nav-trigger nav-trigger--next': true,
        // this will show an icon only in desktop (for mobile, we get icon + name of the navigation item)
        'nav-trigger--login': this.hasNavigationItemsWithNoSubItems
      }
    },
    navigationToggleInputId() {
      return `toggle_${this._cycleUid}`
    },
    navigationItemsWithSubItems() {
      return this.items?.filter(this.hasSubItems) ?? []
    },
    navigationItemsWithNoSubItems() {
      return this.items?.filter((item) => !this.hasSubItems(item)) ?? []
    },
    hasNavigationItemsWithSubItems() {
      return this.navigationItemsWithSubItems?.length > 0
    },
    hasNavigationItemsWithNoSubItems() {
      return this.navigationItemsWithNoSubItems?.length > 0
    },
    hasAnySubItems() {
      return this.hasNavigationItemsWithSubItems || this.hasNavigationItemsWithNoSubItems
    }
  },
  methods: {
    getItems(item) {
      return item?.fields?.items ?? []
    },
    getGroupLink(item) {
      return item?.fields?.GroupLink ?? {}
    },
    hasSubItems(item) {
      return !!item?.fields?.items?.length
    },
    href(item) {
      return item?.fields?.Link?.value?.href
    },
    title(item) {
      return item?.fields?.Title?.value
    },
    isActive(item) {
      return !!item?.fields?.CurrentItem
    }
  }
}
</script>
