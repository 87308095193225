<template>
  <CycleCard modifiers="bordered">
    <template #header>
      <CycleHeading :weight="3"><slot name="heading" /></CycleHeading>
    </template>
    <template #body>
      <div class="radio-list">
        <label class="radio-list__label">
          <input type="radio" v-model="consent" :value="true" />
          <slot name="accept-text"></slot>
        </label>
        <label class="radio-list__label">
          <input type="radio" v-model="consent" :value="false" />
          <slot name="decline-text"></slot>
        </label>
      </div>
    </template>
  </CycleCard>
</template>

<script>
import CycleCard from '../card/CycleCard'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import { COOKIE_CONSENT_KEY } from '../../settings'
import { storage } from '../../globals'

export default {
  name: 'CycleCookieToggle',
  components: {
    CycleCard,
    CycleHeading
  },
  data: () => ({
    consent: storage.getItem(COOKIE_CONSENT_KEY)
  }),
  watch: {
    consent() {
      this.changeCookieConsent(this.consent)
    }
  },
  methods: {
    changeCookieConsent(consent) {
      this.consent = consent
      storage.setItem(COOKIE_CONSENT_KEY, consent)

      const cookieDate = new Date()
      cookieDate.setFullYear(cookieDate.getFullYear() + 1)
      document.cookie = `${COOKIE_CONSENT_KEY}=${consent}; expires=${cookieDate.toUTCString()};`
    }
  }
}
</script>
