//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleCheckboxInput } from '@/components/elements/forms'
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, PersonalDataForm, ContactDetailsForm } from '@/components/organisms'
import { Person, ContactDetails, Address } from '@/models'

export default {
  name: 'FunnelTravelStep3',
  inject: ['formData', 'textContent'],
  components: {
    CycleFormPage,
    ContactDetailsForm,
    CycleButton,
    CycleCheckboxInput,
    CycleHeading,
    CycleInputContainer,
    PersonalDataForm,
    ProvidedText
  },
  props: {
    isVerifiedEmailAddress: {
      type: Boolean
    }
  },
  data() {
    return {
      approvals: ['mailingList'],
      person: new Person(),
      contactDetails: new ContactDetails()
    }
  },
  computed: {
    mailingListKey() {
      return 'mailingList'
    }
  },
  methods: {
    handleSubmit() {
      const address = new Address(
        this.formData.zipcode,
        this.formData.housenumber,
        this.formData.housenumberAddition,
        this.formData.street,
        this.formData.city
      )
      this.$emit('submit', {
        person: this.person,
        contactDetails: this.contactDetails,
        address,
        approvedMailingList: this.approvals.includes(this.mailingListKey)
      })
    }
  }
}
