//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleFormSet from '../CycleFormSet/CycleFormSet'
import CycleInputContainer from '../CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleTextarea from '../../../elements/forms/CycleTextarea/CycleTextarea'
import CycleRadioInput from '../../../elements/forms/CycleRadioInput/CycleRadioInput'
import { CycleDateInput } from '../../../elements/forms'
import CycleButton from '../../../elements/CycleButton/CycleButton'
import CycleSelect from '../../../elements/forms/CycleSelect/CycleSelect'
import CycleOption from '../../../elements/forms/CycleSelect/CycleOption/CycleOption'
import CycleConfirmation from '../../../cycle-confirmation/CycleConfirmation'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import CycleCheckout from '../../CycleCheckout/CycleCheckout'
import CycleToolTip from '../../../cycle-tooltip/CycleTooltip'
import CycleCheckboxInput from '../../../elements/forms/CycleCheckboxInput/CycleCheckboxInput'
import CycleHtml from '../../../base/CycleHtml'

export default {
  name: 'CycleContactForm',
  components: {
    CycleSimpleForm,
    CycleFormSet,
    CycleInputContainer,
    CycleTextInput,
    CycleTextarea,
    CycleRadioInput,
    CycleDateInput,
    CycleButton,
    CycleSelect,
    CycleOption,
    CycleConfirmation,
    CycleCheckout,
    CycleToolTip,
    CycleCheckboxInput,
    CycleHtml
  },
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    changeRequestOptions: {
      type: Array,
      default: () => []
    },
    questionRequestOptions: {
      type: Array,
      default: () => []
    },
    formSubmitAction: {
      type: Function,
      required: true
    }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        descriptionText: null,
        name: null,
        email: null,
        phoneNumber: null,
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        mailId: null
      },
      requestedHelp: null,
      isFormPosted: true,
      postSucceeded: false,
      submitSuccess: null
    }
  },
  computed: {
    descriptionHeading() {
      if (this.requestedHelpQuestion) {
        return this.textContent.setQuestionHeading
      }
      if (this.requestedHelpChange) {
        return this.textContent.setChangeHeading
      }
      return ''
    },
    descriptionLabel() {
      if (this.requestedHelpQuestion) {
        return this.textContent.questionLabel
      }
      if (this.requestedHelpChange) {
        return this.textContent.changeLabel
      }
      return ''
    },
    requestedHelpQuestion() {
      return this.isSelectedOption(this.requestedHelp, 'question')
    },
    requestedHelpChange() {
      return this.isSelectedOption(this.requestedHelp, 'change')
    },
    requestedHelpDamage() {
      return this.isSelectedOption(this.requestedHelp, 'damage')
    },
    setCurrentCustomer() {
      return this.isSelectedOption(this.formData.currentCustomer, 'Ja')
    },
    shouldFormBeVisible() {
      return this.formData.mailId !== null && this.isRequestSelected
    },
    isRequestSelected() {
      return this.requestedHelp !== null
    }
  },
  watch: {
    requestedHelp(newVal) {
      if (newVal) {
        this.formData.mailId = null
      }
    }
  },
  methods: {
    isSelectedOption(source, value) {
      return source === value
    },
    formSubmit({ ...rest }) {
      const formData = {
        ...rest
      }
      return this.formSubmitAction(formData).then(() => {
        this.submitSuccess = true
      })
    }
  }
}
