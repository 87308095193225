//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { get } from 'lodash'
import { ProvidedText, ProvidedHtml, PaymentDetailsForm } from '@/components/organisms'
import { PaymentDetails, PaymentTerm } from '@/models'

import { dictionaryMixin } from '@/mixins'
import { ProductPremium } from '../../models'

export default {
  name: 'WeddingStepPayment',
  inject: ['formData', 'textContent'],
  components: {
    CycleButton,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText
  },
  mixins: [dictionaryMixin],
  props: {
    premium: {
      type: ProductPremium,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    paymentDetails() {
      const init = {
        paymentTerm: PaymentTerm.Single,
        premium: this.premium?.totalPremium,
        iban: get(this.value, ['paymentDetails', 'iban'], undefined)
      }
      return new PaymentDetails(init)
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
