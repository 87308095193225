//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash'
import escapeRegexp from 'escape-string-regexp'
import { CycleIcon } from '@/components/elements/CycleIcon'
import { CycleHtml } from '@/components/base'

export default {
  name: 'CycleAutoComplete',
  components: {
    CycleIcon,
    CycleHtml
  },
  inject: { image: { default: false }, textContent: 'textContent' },
  props: {
    isLoading: {
      type: Boolean
    },
    minChars: {
      type: Number,
      default: 3
    },
    baseClassName: {
      type: String,
      default: 'collectivitypicker'
    },
    inputNameAttribute: {
      type: String,
      default: 'collectivity'
    },
    results: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isOpen: false,
      search: '',
      focusedItemIndex: -1,
      loadedWithNoResults: false,
      isActiveStyle: {
        backgroundColor: '#c9cac8',
        color: '#ffffff'
      }
    }
  },
  computed: {
    imageAlt() {
      return this.image?.alt || this.image?.title || this.image?.name || ''
    },
    imageTitle() {
      return this.image?.title || this.image?.name || this.image?.alt || ''
    },
    stateClassName() {
      return {
        [this.baseClassName]: true,
        [`${this.baseClassName}--open`]: this.isOpen,
        [`${this.baseClassName}--input`]: !!this.search.length
      }
    },
    highlightedResults() {
      const escapedSearch = escapeRegexp(this.search)
      const highlightRegExp = new RegExp(`(${escapedSearch})`, 'gi')

      return this.results
        .filter((item) => item?.name?.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        .map((result) => {
          const highlightedName = result.name.replace(
            highlightRegExp,
            '<span class="collectivitypicker__highlight">$&</span>'
          )
          const baseClassName = this.baseClassName
          return { ...result, highlightedName, baseClassName }
        })
    },
    searchTermHasMinimumThreeCharacters() {
      return this.search.length >= this.minChars
    }
  },
  watch: {
    highlightedResults(newVal) {
      this.loadedWithNoResults = !newVal || newVal.length === 0
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside)
  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    onClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    },
    async pick(item) {
      this.search = item.name

      await this.$nextTick()
      this.isOpen = false
      this.$emit('input', item.value)
    },
    onInput() {
      if (this.search.length < this.minChars) {
        this.isOpen = false
        return
      }
      this.emitSearch()
    },
    emitSearch: debounce(function () {
      this.$emit('search', this.search)
      this.isOpen = true
    }, 500),
    clearInput() {
      this.search = ''
      this.$emit('input', null)
      this.isOpen = false
    },
    onKeyDown() {
      if (this.focusedItemIndex < this.highlightedResults.length - 1) {
        this.focusedItemIndex = this.focusedItemIndex + 1
      } else {
        this.focusedItemIndex = 0
      }
    },
    onKeyUp() {
      if (this.focusedItemIndex > 0) {
        this.focusedItemIndex = this.focusedItemIndex - 1
      } else {
        this.focusedItemIndex = this.highlightedResults.length - 1
      }
    },
    onEnter() {
      if (this.isOpen && this.focusedItemIndex > -1) {
        this.pick(this.highlightedResults[this.focusedItemIndex])
        this.focusedItemIndex = -1
      }
    }
  }
}
