<template>
  <CycleFormPage :on-submit="handleSubmit">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.payment.title" />
      </CycleHeading>
    </template>

    <PaymentDetailsForm
      premium-info="paymentDetails.content.premiumInfo"
      :payment-details="paymentDetails"
      :existing-customer="false"
    />

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.payment.nextButton" />
      </CycleButton>
    </template>
    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="steps.payment.backButton" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { get } from 'lodash'
import { ProvidedText, ProvidedHtml, PaymentDetailsForm } from '@/components/organisms'
import { PaymentDetails, PaymentTerm } from '@/models'

import { dictionaryMixin } from '@/mixins'
import { ProductPremium } from '../../models'

export default {
  name: 'WeddingStepPayment',
  inject: ['formData', 'textContent'],
  components: {
    CycleButton,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText
  },
  mixins: [dictionaryMixin],
  props: {
    premium: {
      type: ProductPremium,
      required: false
    },
    value: {
      type: Object,
      required: false
    }
  },
  computed: {
    paymentDetails() {
      const init = {
        paymentTerm: PaymentTerm.Single,
        premium: this.premium?.totalPremium,
        iban: get(this.value, ['paymentDetails', 'iban'], undefined)
      }
      return new PaymentDetails(init)
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
</script>
