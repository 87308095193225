//
//
//

import CycleIframeSitecore from '../iframe/CycleIframeSitecore'
import { pathOr } from 'ramda'
export default {
  name: 'CycleAnvaOnlineIframeSitecore',
  components: { CycleIframeSitecore },
  props: {
    link: {
      type: Object,
      required: false
    },
    user: {
      type: String,
      required: false,
      default: undefined
    }
  },
  computed: {
    parsedLink() {
      const user = pathOr(undefined, ['user'], this)
      let href = pathOr(undefined, ['link', 'href'], this)
      if (href !== undefined) {
        if (user !== undefined) {
          if (href.indexOf('?') >= 0) {
            href += '&'
          } else {
            href += '?'
          }
          href += 'isBestaandeKlant=true'
        }
      }
      return { href }
    }
  }
}
