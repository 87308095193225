<template>
  <CycleList v-if="items" modifiers="bar">
    <CycleListItem v-for="item in items" :key="item.id">
      <cycle-link
        v-if="item.fields.Link.value.href"
        :href="item.fields.Link.value.href"
        :target="item.fields.Link.value.target"
        >{{ item.fields.Title.value }}</cycle-link
      >
      <p v-else>{{ item.fields.Title.value }}</p>
    </CycleListItem>
  </CycleList>
</template>

<script>
import { CycleLink } from '../cycle-link'
import { CycleList, CycleListItem } from '../cycle-list/'
export default {
  name: 'CycleMenuLinksBar',
  components: {
    'cycle-link': CycleLink,
    CycleList,
    CycleListItem
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: null
    }
  }
}
</script>

<style></style>
