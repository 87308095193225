//
//
//
//
//
//
//
//

import CycleButton from '../elements/CycleButton/CycleButton'
import CycleIcon from '../elements/CycleIcon/CycleIcon'
import { CycleBaseComponent } from '@/components/base'

export default {
  name: 'IconButtonVue',
  components: { CycleIcon, CycleButton },
  extends: CycleBaseComponent,
  props: {
    buttonModifiers: {
      type: String
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  }
}
