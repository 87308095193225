//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValueTransition from './ValueTransition.vue'
import { CycleHtml } from '@/components/base'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'
import CoveragePremiumPrice from './CoveragePremiumPrice.vue'
import ModalTooltip from '@/components/molecules/ModalTooltip'

export default {
  name: 'TotalPremium',
  filters: {
    CycleCurrency
  },
  components: {
    ValueTransition,
    CycleHtml,
    CoveragePremiumPrice,
    ModalTooltip
  },
  mixins: [intlMixin],
  props: {
    premiumLabel: {
      type: String,
      required: false,
      default: ''
    },
    premiumInfo: {
      type: String,
      required: false,
      default: ''
    },
    premium: {
      type: [Number, String],
      required: false
    },
    transitionParams: {
      type: Object,
      default: () => ({})
    },
    displayTotalPremiumWithProvision: {
      type: Boolean,
      required: false
    },
    paymentTerm: {
      type: String,
      required: false,
      default: 'p/m'
    },
    tooltip: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    }
  },
  computed: {
    hasTransitionParams() {
      return !!this.transitionParams?.intervalTime
    },
    hasPremium() {
      return !!this.premium
    }
  }
}
