
import { pathOr } from 'ramda'
export default {
  name: 'CycleClientRendering',
  render() {
    const parent = this.$parent
    const defaultSlot = pathOr([], ['$slots', 'default'], this)
    if (parent._isMounted) {
      return defaultSlot.length > 0 ? defaultSlot[0] : null
    }
    parent.$once('hook:mounted', () => {
      parent.$forceUpdate()
    })
    return defaultSlot.length > 0 ? defaultSlot[0] : null
  }
}
