//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading } from '@/components/elements'
import { CycleFormPage } from '@/components/functional/forms'
import { ProvidedText } from '@/components/organisms'

export default {
  name: 'FunnelTravelStep5',
  inject: ['formData', 'textContent'],
  components: {
    CycleFormPage,
    CycleHeading,
    ProvidedText
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
