<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <component :is="tagName" v-if="sanitizedHtml" v-html="sanitizedHtml" v-on="$listeners" />
</template>

<script>
import xss from 'xss'
import * as xssDefaults from 'xss/lib/default'

let xssConfig = {
  whiteList: {
    ...xssDefaults.whiteList,
    label: []
  },
  stripIgnoreTagBody: true
}

;['a', 'div', 'span'].forEach((tag) => xssConfig.whiteList[tag].push('style'))
Object.values(xssConfig.whiteList).forEach((item) => {
  if (!item.includes('class')) {
    item.push('class')
  }
  if (!item.includes('data-script')) {
    item.push('data-script')
  }
})

export default {
  name: 'CycleHtml',
  props: {
    html: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      required: false
    }
  },
  computed: {
    tagName() {
      return this.tag ?? (this.inline ? 'span' : 'div')
    },
    sanitizedHtml() {
      const xssInstance = new xss.FilterXSS(xssConfig)
      return xssInstance.process(this.html)
    }
  }
}
</script>
