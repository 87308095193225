<template>
  <div :data-status="status" class="confirmation">
    <CycleHeading v-if="hasHeadingSlot"><slot name="heading" /></CycleHeading>
    <p class="confirmation__content">
      <slot></slot>
    </p>
  </div>
</template>

<script>
import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
export default {
  name: 'CycleFormConfirmation',
  components: { CycleHeading },
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasHeadingSlot() {
      return !!this.$slots.heading
    }
  }
}
</script>
