import { pick } from 'lodash'

export const labelsWithDefaults = {
  page1title: 'Uw situatie',
  page2title: 'Keuze dekking',
  page3title: 'Uw gegevens',
  page4title: 'Uw betaalgegevens',
  page5title: 'Slotvragen',
  page6title: 'Overzicht & aanvragen',
  page1NextButton: 'Premie berekenen',
  page2NextButton: 'Verzeker mij',
  page3NextButton: 'Naar betaalgegevens',
  page4NextButton: 'Naar slotvragen',
  page5NextButton: 'Overzicht en aanvragen',
  page6NextButton: 'Aanvragen',
  page2PrevButton: 'Vorige',
  page3PrevButton: 'Vorige stap',
  page4PrevButton: 'Vorige stap',
  page5PrevButton: 'Vorige stap',
  page6PrevButton: 'Vorige stap',

  traveltypeLabel: 'Soort verzekering',
  traveltypeTooltip: '',
  traveltypeLong: 'Doorlopende reisverzekering',
  traveltypeShort: 'Kortlopende reisverzekering',

  householdCompositionLabel: 'Samenstelling huishouden',
  householdCompositionTooltip: '',
  householdCompositionSingle: 'Eén persoon',
  householdCompositionMultiple: 'Meerdere personen',

  returndateLabel: 'Wanneer komt u thuis?',
  returndateTooltip: '',
  departureDateLabel: 'Wanneer vertrekt u?',
  departureDateTooltip: '',

  basicCoverageLabel: 'Reisverzekering basisdekking',
  basicCoverageTooltip: '',
  basicCoverageMultiTripDescription:
    'Met onze reisverzekering bent u standaard verzekerd van hulp en voor onverwachte kosten op reis. Bijvoorbeeld bij ziekte, een ongeluk of overlijden. U heeft standaard een wereldwijde dekking.<br>Aantal aaneengesloten dagen:',
  basicCoverageSingleTripDescription:
    'Met onze reisverzekering bent u standaard verzekerd van hulp en voor onverwachte kosten op reis. Bijvoorbeeld bij ziekte, een ongeluk of overlijden. U heeft standaard een wereldwijde dekking.',
  basicCoverage60DaysLabel: '60 dagen',
  basicCoverage180DaysLabel: '180 dagen',
  basicCoverage360DaysLabel: '360 dagen',

  totalAmountMultiTripText: 'U betaalt per maand',
  totalAmountSingleTripText: 'U betaalt eenmalig',
  totalAmountMultiTripSubtext:
    'Dit bedrag is inclusief 21% assurantiebelasting en prolongatiekosten.',
  totalAmountSingleTripSubtext: 'Dit bedrag is inclusief 21% assurantiebelasting.',

  travelLugageLabel: 'Bagage',
  travelLugageTooltip: '',
  travelLugageDescription: '',
  travelLugageStandardLabel: 'Standaard EUR 3.000 per persoon',
  travelLugageExtendedLabel: 'Standaard EUR 5.000 per persoon',

  travelMedicalCostsLabel: 'Geneeskundige kosten',
  travelMedicalCostsTooltip: '',
  travelMedicalCostsDescription:
    'Wij adviseren altijd de dekking Geneeskundige kosten mee te verzekeren. Uw zorgverzekering vergoedt namelijkniet altijd alle geneeskundige kosten in het buitenland.',

  travelReplacementTransportAndRepatriationLabel: 'vervangend vervoer en repatriering',
  travelReplacementTransportAndRepatriationTooltip: '',
  travelReplacementTransportAndRepatriationDescription: 'test',

  travelSpecialSportsLabel: 'Bijzondere sporten',
  travelSpecialSportsTooltip: '',
  travelSpecialSportsDescription: '',

  travelAccidentsLabel: 'Ongevallen',
  travelAccidentsTooltip: '',
  travelAccidentsDescription: '',
  travelAccidentsStandardLabel: 'Standaard',
  travelAccidentsExtendedLabel: 'Standaard + Bijzondere sporten',

  travelCancellationLabel: 'Annuleringskosten',
  travelCancellationMultiTripTooltip: '',
  travelCancellationMultiTripDescription: '',
  travelCancellationSingleTripTooltip: '',
  travelCancellationSingleTripDescription: '',
  travelCancellation1500Label: 'EUR 1.500 per persoon',
  travelCancellation3000Label: 'EUR 3.000 per persoon',

  travelSum: 'Reiskosten',
  travelSumTooltip: '',

  bookingDate: 'Boekingsdatum',
  bookingDateTooltip: '',

  travelExcessDeductibleRentalCarLabel: "Afkoop eigen risico van huurauto's",
  travelExcessDeductibleRentalCarTooltip: '',
  travelExcessDeductibleRentalCarDescription: '',

  contactDetailsHeader: 'Contactgegevens aanvrager',
  personalDataHeader: 'Gegevens aanvrager',
  salutationLabel: 'Aanhef',
  salutationTooltip: '',
  salutationOptionMale: 'De heer',
  salutationOptionFemale: 'Mevrouw',
  initialsLabel: 'Voorletter(s)',
  initialsTooltip: '',
  prepositionLabel: 'Tussenvoegsel ',
  prepositionTooltip: '',
  lastnameLabel: 'Achternaam',
  lastnameTooltip: '',
  birthdateLabel: 'Geboortedatum',
  birthdateTooltip: '',
  zipcodeLabel: 'Postcode',
  zipcodeTooltip: '',
  housenumberLabel: 'Huisnummer en toevoeging',
  housenumberTooltip: '',
  streetLabel: 'Straat',
  streetTooltip: '',
  cityLabel: 'Woonplaats',
  cityTooltip: '',
  phonenumberLabel: 'Telefoonnummer',
  phonenumberTooltip: '',
  emailaddressLabel: 'E-mailadres',
  emailaddressTooltip: '',
  emailaddressRepeatLabel: 'Herhaal uw e-mailadres',
  emailaddressRepeatTooltip: '',
  emailaddressSubtext: 'Op dit adres ontvangt u een bevestiging van uw aanvraag.',
  mailinglistLabel: 'Inschrijving nieuwsbrief ',
  mailinglistText: 'Wilt u per e-mail op de hoogte blijven van speciale acties en ander nieuws?',

  startdateTitle: 'Gewenste ingangsdatum',
  startdateTooltip: '',
  startdateText:
    'In het bevestigingsscherm leest u direct of u verzekerd bent op de gekozen ingangsdatum',
  paymenttypeTitle: 'Uw rekeninggegevens en betaaltermijn',
  paymenttypeText: 'Betaalwijze via automatische incasso',
  paymenTermMonthlyLabel: 'U betaalt per maand',
  paymentTermSingleLabel: 'U betaalt eenmalig',
  ibanLabel: 'Bankrekening (IBAN)',
  ibanTooltip: '',
  premiumInfoTextMultiTrip:
    'Dit bedrag is inclusief 21% assurantiebelasting en prolongatiekosten. We schrijven dit bedrag automatisch van uw rekening af.',
  premiumInfoTextSingleTrip:
    'Dit bedrag is inclusief 21% assurantiebelasting. We schrijven dit bedrag automatisch van uw rekening af.',
  premiumTaxesHeading: 'Prolongatiekosten',
  premiumTaxesBody:
    'Elke keer dat u premie betaalt, brengen wij prolongatiekosten in rekening. We starten daarmee vanaf de tweede premiebetaling. Per jaar betaalt u maximaal € 2,04 prolongatiekosten.',
  cancellationWarning:
    'De boekingsdatum ligt meer dan zeven dagen in het verleden. Hierdoor heeft u geen dekking bij annulering door ziekte van verzekerden of familieleden die voor het afsluiten van de verzekering al voorkwam.',
  specialSportsWarning:
    'U heeft gekozen voor de dekking Ongevallen Standaard + Bijzondere sporten. Voor een optimale bescherming raden wij u aan om ook de dekking Bijzondere sporten af te sluiten.',
  travelLengthWarning:
    'U gaat langer dan 20 dagen aaneengesloten op reis. Een doorlopende reisverzekering kan voordeliger zijn.',

  startChangeDateLabel: 'Gewenste wijzigingsdatum',
  startChangeDateTooltip: ''
}

export const labels = Object.keys(labelsWithDefaults)
export const labelProps = Object.keys(labelsWithDefaults).reduce(
  (accumulator, label) =>
    Object.assign(accumulator, {
      [label]: {
        type: String,
        required: false,
        default: labelsWithDefaults[label]
      }
    }),
  {}
)
export const textMap = (context) => ({
  ...pick(context, labels),
  personalData: {
    header: context.personalDataHeader,
    labels: {
      salutation: context.salutationLabel,
      salutationOption: {
        M: context.salutationOptionMale,
        V: context.salutationOptionFemale
      },
      initials: context.initialsLabel,
      preposition: context.prepositionLabel,
      lastName: context.lastnameLabel,
      dateOfBirth: context.birthdateLabel
    },
    tooltips: {
      salutation: context.salutationTooltip,
      initials: context.initialsTooltip,
      preposition: context.prepositionTooltip,
      lastName: context.lastnameTooltip,
      dateOfBirth: context.birthdateTooltip
    }
  },
  contactDetails: {
    header: context.contactDetailsHeader,
    emailAddress: {
      subtext: context.emailaddressSubtext
    },
    labels: {
      phoneNumber: context.phonenumberLabel,
      emailAddress: context.emailaddressLabel,
      emailAddressVerify: context.emailaddressRepeatLabel,
      mailingListLabel: context.mailinglistLabel,
      mailingListText: context.mailinglistText
    },
    tooltips: {
      phoneNumber: context.phonenumberTooltip,
      emailAddress: context.emailaddressTooltip,
      emailAddressVerify: context.emailaddressRepeatTooltip
    }
  },
  payment: {
    header: context.page4title,
    content: {
      startDate: context.startdateText
    },
    labels: {
      startDate: context.startdateLabel,
      next: context.page4NextButton
    },
    headings: {
      startDate: context.startdateTitle
    }
  },
  paymentDetails: {
    header: context.paymenttypeTitle,
    headings: {
      premiumTaxes: context.premiumTaxesHeading
    },
    content: {
      paymentType: context.paymenttypeText
    },
    labels: {
      iban: context.ibanLabel,
      paymentTermMonth: context.paymenTermMonthlyLabel,
      paymentTermSingle: context.paymentTermSingleLabel
    },
    tooltips: {
      iban: context.ibanTooltip,
      premiumTaxes: context.premiumTaxesBody
    }
  },
  finalQuestions: {
    header: context.page5title
  },
  overview: {
    header: context.page6title,
    intro: context.overviewText,
    general: {
      heading: 'Algemeen',
      traveltype: context.traveltypeLabel,
      traveltypeLong: context.traveltypeLong,
      traveltypeShort: context.traveltypeShort,
      householdComposition: context.householdCompositionLabel,
      departureDate: context.departureDateLabel,
      returnDate: context.returndateLabel,
      baseCoverage: context.basicCoverageLabel,
      luggage: context.travelLugageLabel,
      medicalCosts: context.travelMedicalCostsLabel,
      replacementTransportAndRepatriation: context.travelReplacementTransportAndRepatriationLabel,
      specialSports: context.travelSpecialSportsLabel,
      accidents: context.travelAccidentsLabel,
      cancellation: context.travelCancellationLabel,
      excessDeductibleRentalCar: context.travelExcessDeductibleRentalCarLabel,
      paymentTermMonthly: context.totalAmountMultiTripText,
      paymentTermSingle: context.totalAmountSingleTripText,
      bookingDate: context.bookingDate,
      travelSum: context.travelSum,
      startChangeDateLabel: context.startChangeDateLabel
    },
    personalData: {
      heading: context.page3title,
      salutation: context.salutationLabel,
      salutationOptionMale: context.salutationOptionMale,
      salutationOptionFemale: context.salutationOptionFemale,
      initials: context.initialsLabel,
      tussenvoegsel: context.prepositionLabel,
      lastName: context.lastnameLabel,
      dateOfBirth: context.birthdateLabel,
      postalCode: context.zipcodeLabel,
      houseNumber: context.housenumberLabel,
      street: context.streetLabel,
      city: context.cityLabel,
      mobileNumber: context.phonenumberLabel,
      telephoneNumber: context.phonenumberLabel,
      emailAddress: context.emailaddressLabel,
      mailingList: context.mailinglistLabel,
      mailingListYes: 'Ja',
      mailingListNo: 'Nee'
    },
    paymentDetails: {
      heading: context.page4title,
      startDate: context.startdateTitle,
      iban: context.ibanLabel,
      paymentTerm: 'Betaaltermijn',
      paymentTermMonth: 'Maand',
      paymentTermSingle: 'Eenmalig',
      paymentType: 'Betaalwijze',
      paymentAutomaticDebit: 'Automatische incasso'
    },
    coverages: {
      baseCoverage: {
        60: context.basicCoverage60DaysLabel,
        180: context.basicCoverage180DaysLabel,
        360: context.basicCoverage360DaysLabel
      },
      luggage: {
        3000: context.travelLugageStandardLabel,
        5000: context.travelLugageExtendedLabel
      },
      cancellation: {
        1500: context.travelCancellation1500Label,
        3000: context.travelCancellation3000Label
      },
      accidents: {
        Default: context.travelAccidentsStandardLabel,
        SpecialSports: context.travelAccidentsExtendedLabel
      }
    }
  },
  warnings: {
    cancellation: context.cancellationWarning,
    specialSports: context.specialSportsWarning,
    travelLength: context.travelLengthWarning
  }
})
