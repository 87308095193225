<template>
  <article class="article">
    <CycleHtml :html="bodytext" />
    <slot />
  </article>
</template>

<script>
import { CycleHtml } from '../base'

export default {
  name: 'CycleArticle',
  components: { CycleHtml },
  props: {
    bodytext: {
      type: String,
      required: false
    }
  }
}
</script>
