export const bemModifiers = [
  'primary',
  'login',
  'link',
  'cta',
  'secondary',
  'icon',
  'icon-next',
  'tertiary',
  'pre-funnel',
  'prominent'
]
