import { OverviewGroupModel, OverviewEntryTypes } from '../../../shared'

export function cardetails({ textContent, formData, vehicleInformation }) {
  return new OverviewGroupModel({
    title: textContent.cardetailsTitle,
    entries: [
      {
        label: textContent.licenseplateLabel,
        value: formData.licenseplate
      },
      {
        label: textContent.signCodeLabel,
        value: formData.signCode
      },
      {
        label: 'Merk',
        value: vehicleInformation.brand
      },
      {
        label: 'Model',
        value: vehicleInformation.model
      },
      {
        label: 'Bouwjaar',
        value: vehicleInformation.constructionYear
      },
      {
        label: 'Uitvoering',
        value: vehicleInformation.version
      },
      {
        label: textContent.catalogueLabel,
        value: vehicleInformation.listPriceIncludingVAT,
        type: OverviewEntryTypes.CURRENCY
      },
      {
        label: textContent.onlyprivateLabel,
        value: formData.onlyprivate ? 'Ja' : 'Nee'
      }
    ]
  })
}
