<template>
  <CycleFormPage :prev-button-text="textContent.liability.steps.overview.labels.prev">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="liability.steps.overview.header" />
      </CycleHeading>
    </template>

    <ProvidedHtml name="liability.steps.overview.content.intro" />

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="liability.steps.overview.headings.generalHeading" />
      </template>

      <dl>
        <dt><ProvidedText name="liability.steps.overview.labels.familyType" /></dt>
        <dd>
          <ProvidedText v-if="isValidFamilyType" :name="nameForFamilyType" />
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.insuredAmount" /></dt>
        <dd>
          {{ insuredAmount | CycleCurrency({ locale }) }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.paymentTermMonth" /></dt>
        <dd>
          <span v-if="premium">
            {{ premium.premium | CycleCurrency({ locale }) }}
          </span>
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="liability.steps.overview.headings.personalDetailHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="liability.steps.overview.labels.salutation" /></dt>
        <dd>
          <ProvidedText name="liability.steps.overview.content.salutation.m" v-if="isMale" />
          <ProvidedText name="liability.steps.overview.content.salutation.v" v-if="isFemale" />
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.initials" /></dt>
        <dd>
          {{ person.initials }}
        </dd>
        <template v-if="person.tussenvoegsel && person.tussenvoegsel.trim()">
          <dt><ProvidedText name="liability.steps.overview.labels.tussenvoegsel" /></dt>
          <dd>
            {{ person.tussenvoegsel }}
          </dd>
        </template>
        <dt><ProvidedText name="liability.steps.overview.labels.lastName" /></dt>
        <dd>
          {{ person.lastName }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.dateOfBirth" /></dt>
        <dd>
          {{ person.dateOfBirth | CycleDate({ locale }) }}
        </dd>
        <template v-if="address">
          <dt><ProvidedText name="liability.steps.overview.labels.zipcode" /></dt>
          <dd>
            {{ address.postalCode }}
          </dd>
          <dt><ProvidedText name="liability.steps.overview.labels.houseNumber" /></dt>
          <dd>
            <FullHouseNumber :address="address" />
          </dd>
          <dt><ProvidedText name="liability.steps.overview.labels.street" /></dt>
          <dd>
            {{ address.street }}
          </dd>
          <dt><ProvidedText name="liability.steps.overview.labels.city" /></dt>
          <dd>
            {{ address.city }}
          </dd>
        </template>
        <dt><ProvidedText name="liability.steps.overview.labels.emailAddress" /></dt>
        <dd>
          {{ contactDetails.emailAddress }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.mailingList" /></dt>
        <dd>
          <ProvidedText
            name="liability.steps.overview.content.mailingList.yes"
            v-if="approvedMailingList"
          />
          <ProvidedText name="liability.steps.overview.content.mailingList.no" v-else />
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.phoneNumber" /></dt>
        <dd>
          {{ contactDetails.phoneNumber }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="liability.steps.overview.headings.paymenttypeHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="liability.steps.overview.labels.startDate" /></dt>
        <dd v-if="startDate">
          {{ startDate | CycleDate({ locale }) }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.iban" /></dt>
        <dd>
          {{ paymentDetails.iban }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.paymentTerm" /></dt>
        <dd>
          <ProvidedText
            name="liability.steps.overview.content.paymentTerm.month"
            v-if="monthlyPayment"
          />
          <ProvidedText
            name="liability.steps.overview.content.paymentTerm.year"
            v-if="yearlyPayment"
          />
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.paymentType" /></dt>
        <dd>
          <ProvidedText name="liability.steps.overview.content.paymentType" />
        </dd>
      </dl>
    </CycleFormSet>
    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="liability.steps.overview.labels.next" />
      </CycleButton>
    </template>
    <slot />
  </CycleFormPage>
</template>

<script>
import { FullHouseNumber } from '@/components/atoms'
import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { Gender } from '@/constants'
import { CycleCurrency, CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { Person, PaymentTerm, PaymentDetails, ContactDetails, Address } from '@/models'
import { FamilyType } from '../../constants'
import { ProductPremium } from '../../models'

export default {
  name: 'LiabilityStepOverview',
  inject: ['textContent'],
  components: {
    FullHouseNumber,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    address: {
      required: false,
      type: Address
    },
    approvedMailingList: {
      required: true,
      type: Boolean
    },
    contactDetails: {
      required: true,
      type: ContactDetails
    },
    familyType: {
      required: true,
      type: String
    },
    insuredAmount: {
      required: true,
      type: String
    },
    person: {
      required: true,
      type: Person
    },
    paymentDetails: {
      required: true,
      type: PaymentDetails
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    startDate: {
      required: false,
      type: [String, Date]
    }
  },
  computed: {
    isMale() {
      return this.person.gender === Gender.Male
    },
    isFemale() {
      return this.person.gender === Gender.Vemale
    },
    monthlyPayment() {
      return this.paymentDetails.paymentTerm === PaymentTerm.Monthly
    },
    yearlyPayment() {
      return this.paymentDetails.paymentTerm === PaymentTerm.Yearly
    },
    nameForFamilyType() {
      return `liability.steps.overview.content.familyType.${this.familyType}`
    },
    isValidFamilyType() {
      return this.familyType === FamilyType.Single || this.familyType === FamilyType.Multiple
    }
  }
}
</script>
