//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleAdviceBlock } from '@/components/cycle-advice-block'
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedHtml, ProvidedText, ProvidedTooltip } from '@/components/organisms'

import { ProductPremium } from '../../models'

export default {
  name: 'LiabilityStepOffers',
  components: {
    CycleAdviceBlock,
    CycleTooltip,
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    CycleHtml,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  props: {
    premium: {
      required: false,
      type: ProductPremium
    }
  },
  methods: {
    handlePremiumChosen() {
      this.$emit('submit')
    }
  }
}
