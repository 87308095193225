import { OverviewEntryModel } from './OverviewEntryModel'

export class OverviewGroupModel {
  constructor({ title = null, entries = [] }) {
    this.title = title
    this.entries = []
    entries.forEach((entry) => this.addEntry(entry))
  }

  get isValid() {
    return this.title && Array.isArray(this.entries) && this.entries.some((entry) => entry.isValid)
  }

  get filteredEntries() {
    return this.entries.filter((entry) => entry.isValid)
  }

  addEntry(data) {
    const entry = data instanceof OverviewEntryModel ? entry : new OverviewEntryModel(data)
    this.entries = [...this.entries, entry]
    return this
  }
}
