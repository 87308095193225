//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleAutoComplete } from '@/components/elements/forms'
import { CycleButtonLink } from '@/components/cycle-link'
import { intlMixin } from '@/mixins'

export default {
  name: 'CycleCollectivityPicker',
  components: {
    CycleAutoComplete,
    CycleButtonLink,
    CycleHtml
  },
  mixins: [intlMixin],
  inject: ['textContent'],
  props: {
    getCollectivities: {
      type: Function,
      required: true
    },
    collectivities: {
      type: Array,
      default: () => []
    },
    stayOnPage: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      pickedItem: null
    }
  },
  computed: {
    mappedAutoCompleteItems() {
      return this.collectivities.map(this.mapCollectivityToAutocompleteItem)
    }
  },
  watch: {
    pickedItem(pickedItem) {
      const linkType = pickedItem?.collectivityType?.link?.linkType
      if (linkType === 'internal') {
        let pickedUrl = pickedItem.collectivityType.link.href
        if (this.stayOnPage) {
          const pathname = window.location.pathname.replace(`/${this.locale}/`, '/')
          const localePath = `/${this.locale}`
          const deepLinkPath = pickedItem.deepLink ?? ''
          pickedUrl = `${localePath}${deepLinkPath}${pathname}`
        }
        window.location.href = pickedUrl
      }
    }
  },
  methods: {
    showExternal(pickedItem) {
      if (pickedItem?.collectivityType?.link?.linkType === 'external') {
        return true
      }
      return false
    },
    mapCollectivityToAutocompleteItem(item) {
      return {
        name: item.collectivityName,
        value: item
      }
    }
  }
}
