<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :heading="contentProvider.getContentByPath('steps.setup.title')"
    hide-back-button
    :next-button-text="contentProvider.getContentByPath('steps.setup.nextButton')"
    :hide-next-button="isUninsurable"
  >
    <CycleFormSet>
      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.setup.contentsBuildingType.label" />

          <ProvidedTooltip
            heading-name="steps.setup.contentsBuildingType.label"
            body-name="steps.setup.contentsBuildingType.tooltip"
          />
        </template>

        <CycleSelect v-model="formData.contentsBuildingType" required>
          <template #option>
            <CycleOption
              v-for="buildingType in contentsBuildingTypes"
              :key="buildingType.key"
              :value="buildingType"
              v-text="buildingType.value"
            />
          </template>
        </CycleSelect>
        <template #subtext v-if="isUninsurable">
          <p class="form__errormessage">
            {{ phrase(uninsurableMessage) }}
          </p>
        </template>
      </CycleInputContainer>

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="address.postalCode.label" />
          <ProvidedTooltip
            heading-name="address.postalCode.label"
            body-name="address.postalCode.tooltip"
          />
        </template>
        <CycleTextInput
          v-model="formData.insuredObjectAddress.postalCode"
          :placeholder="contentProvider.getContentByPath('address.postalCode.placeholder', false)"
          postcode
          required
          :maxlength="7"
        />
      </CycleInputContainer>
    </CycleFormSet>

    <CycleFormSet
      :heading="contentProvider.getContentByPath('steps.setup.householdComposition.heading')"
    >
      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.setup.birthDate.label" />
          <ProvidedTooltip
            heading-name="steps.setup.birthDate.label"
            body-name="steps.setups.birthDate.tooltip"
          />
        </template>
        <CycleDateInput v-model="formData.birthDate" :min="maxAge" :max="minAge" required />
        <template #error-max>
          {{ phrase('error-messages-funnels-005') }}
        </template>
        <template #error-min>
          {{ phrase('error-messages-funnels-006') }}
        </template>
      </CycleInputContainer>

      <CycleInputContainer render-list>
        <template #label>
          <ProvidedText name="steps.setup.householdComposition.label" />

          <ProvidedTooltip
            heading-name="steps.setup.householdComposition.label"
            body-name="steps.setup.householdComposition.tooltip"
          />
        </template>

        <CycleRadioInput
          v-for="householdComposition in householdCompositions"
          v-model="formData.householdComposition"
          :key="householdComposition.key"
          :value="householdComposition"
          :label="householdComposition.value"
          required
        />
      </CycleInputContainer>
    </CycleFormSet>
  </CycleFormPage>
</template>

<script>
import store from '@/stores/profile'
import {
  CycleHeading,
  CycleButton,
  CycleSelect,
  CycleOption,
  CycleRadioInput,
  CycleTextInput,
  CycleDateInput
} from '@/components/elements'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { uninsurableMessagesMap } from '../../constants/constants'

import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { intlMixin, dictionaryMixin, storeModuleMixinFactory } from '@/mixins'
import format from 'date-fns/format'
import subYears from 'date-fns/subYears'
import addDays from 'date-fns/addDays'

export default {
  name: 'HomeContentsStepSetup',
  inject: ['formData', 'textContent', 'contentProvider'],
  components: {
    CycleTextInput,
    CycleDateInput,
    CycleFormSet,
    CycleInputContainer,
    CycleButton,
    CycleSelect,
    CycleOption,
    CycleRadioInput,
    CycleFormPage,
    CycleHeading,
    ProvidedText,
    ProvidedTooltip
  },
  mixins: [
    intlMixin,
    dictionaryMixin,
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      gettersMapping: ['prefilledBuildingTypeKey']
    })
  ],
  props: {
    contentsBuildingTypes: {
      type: Array,
      required: true
    },
    householdCompositions: {
      type: Array,
      required: true
    }
  },
  computed: {
    isUninsurable() {
      return this.formData.contentsBuildingType?.isInsurable === false
    },
    uninsurableMessage() {
      const selectedKey = this.formData.contentsBuildingType?.key || 'none'
      return uninsurableMessagesMap[selectedKey]()
    },
    minAge() {
      return format(subYears(new Date(), 18), 'yyyy-MM-dd')
    },
    maxAge() {
      return format(addDays(subYears(new Date(), 110), 1), 'yyyy-MM-dd')
    }
  },
  mounted() {
    const prefilledBuildingType = this.prefilledBuildingTypeKey
      ? this.contentsBuildingTypes.find((cbt) => cbt.key === this.prefilledBuildingTypeKey)
      : null
    if (prefilledBuildingType) {
      this.formData.contentsBuildingType = prefilledBuildingType
    }
  },
  methods: {
    handleSubmit() {
      const {
        contentsBuildingType,
        insuredObjectAddress,
        birthDate,
        householdComposition
      } = this.formData

      this.$emit('submit', {
        contentsBuildingType,
        insuredObjectAddress,
        birthDate,
        householdComposition
      })
    }
  }
}
</script>
