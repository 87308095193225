//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import BusyIndicator from '@/components/molecules/BusyIndicator'
import { CycleButton, CycleHeading } from '@/components/elements'
import { CycleFormNotification } from '../../../cycle-form-notification'
import { CycleUidMixin, dictionaryMixin } from '@/mixins'
import { logError } from '@/services/Logging'
import CycleFormConfirmation from '../CycleFormConfirmation/CycleFormConfirmation'

export default {
  name: 'CycleFormPage',
  components: {
    CycleButton,
    CycleHeading,
    CycleFormConfirmation,
    CycleFormNotification,
    BusyIndicator
  },
  mixins: [CycleUidMixin, dictionaryMixin],
  inject: {
    formState: 'formState',
    formData: 'formData',
    formErrors: 'formErrors',
    registerFormPage: 'registerFormPage',
    unRegisterFormPage: 'unRegisterFormPage',
    textContent: { default: {} }
  },
  provide() {
    return {
      validateFormPage: this.validateFormPage,
      pageName: this.name
    }
  },
  props: {
    heading: {
      type: String,
      required: false,
      default: ''
    },
    nextButtonText: {
      type: String,
      required: false,
      default: 'Next'
    },
    hasNextButton: {
      // DEPRECATED, use hide-next-button
      type: Boolean,
      required: false,
      default: true
    },
    hideNextButton: {
      type: Boolean,
      required: false,
      default: false
    },
    prevButtonText: {
      type: String,
      required: false,
      default: 'Previous'
    },
    hasPrevButton: {
      // DEPRECATED, use hide-back-button
      type: Boolean,
      required: false,
      default: true
    },
    hideBackButton: {
      type: Boolean,
      required: false,
      default: false
    },
    isPreFunnel: {
      type: Boolean,
      required: false,
      default: false
    },
    preFunnelButtonText: {
      type: String,
      required: false,
      default: 'Calculate your premium'
    },
    modifier: {
      type: String,
      required: false,
      validator: (value) => value === 'checkout'
    },
    onInit: {
      type: Function,
      required: false
    },
    onSubmit: {
      type: Function,
      required: false
    },
    inlineSpinner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isInitializing: true,
      isSubmitting: false,
      technicalError: false,
      graphqlError: false
    }
  },
  computed: {
    name() {
      return `page${this._cycleUid}`
    },
    defaultErrorText() {
      return this.phrase('error-messages-funnels-001')
    },
    loadingText() {
      if (this.textContent == null || this.textContent.loadingText == null) {
        return null
      }
      return this.textContent.loadingText
    },
    shouldShowPage() {
      if (this.formState.active == null) {
        return true
      }
      return this.formState.active === this._cycleUid
    },
    isLoadingInlineSpinner() {
      if (this.inlineSpinner && (this.isSubmitting || this.isInitializing)) {
        return this.loadingText
      }

      return false
    }
  },
  created() {
    this.registerFormPage(this)
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.unRegisterFormPage(this)
  },
  methods: {
    async init() {
      try {
        if (typeof this.onInit === 'function') {
          await this.onInit()
        }
      } catch (err) {
        this.technicalError = true
        logError(err)
      }
      this.isInitializing = false
    },
    prevButtonClick() {
      this.formState.prev()
    },
    async submit() {
      if (!this.validateFormPage()) {
        window.scroll({
          top: this.$el.getBoundingClientRect().top,
          behavior: 'smooth'
        })
        return
      }
      this.resetValidations()

      if (typeof this.onSubmit !== 'function') {
        this.formState.next()
        return
      }

      if (this.isSubmitting) {
        return
      }

      this.isSubmitting = true
      this.graphqlError = false
      this.technicalError = false

      let res = null
      try {
        res = await this.onSubmit()
      } catch (err) {
        this.technicalError = true
        logError(err)
      }

      this.isSubmitting = false
      const errorsLength = get(res, ['errors', 'length'], false)
      if (errorsLength > 0) {
        this.graphqlError = true
        logError(res.errors)
      }
      if (!this.technicalError && !this.graphqlError) {
        this.formState.next()
      }
    },
    validateFormPage() {
      const pageErrors = this.formErrors(`form.${this.name}`)
      if (!pageErrors) {
        return true
      }

      pageErrors.$touch()
      return !pageErrors.$invalid
    },
    resetValidations() {
      const pageErrors = this.formErrors(`form.${this.name}`)
      if (!pageErrors) {
        return
      }
      pageErrors.$reset()
    }
  }
}
