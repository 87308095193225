//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading, CycleIcon } from '@/components/elements'
import { CycleHtml } from '@/components/base'
import { CycleButtonLink } from '@/components/cycle-link'
import { CycleNav } from '@/components/cycle-nav'

export default {
  name: 'ProductCard',
  components: {
    CycleButtonLink,
    CycleHeading,
    CycleHtml,
    CycleIcon,
    CycleNav
  },
  props: {
     heading: {
      type: String,
      required: false
    },
    icon:{
      type: String,
      required: false
    },
    bodytext: {
      type: String,
      required: false
    },
    buttonList: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
