<template>
  <div class="navigation navigation--main">
    <a v-if="logoSrc" :href="logoLink" class="coreui__custom-logo--link">
      <img
        :src="logoSrc"
        :alt="logoAlt"
        loading="eager"
        fetchpriority="high"
        class="coreui__custom-logo--image"
      />
    </a>
    <a v-else :href="logoLink" class="coreui__logo"></a>
    <ul class="navigation__items" v-if="hasNavigationItems">
      <template v-for="navigationItem in items">
        <SubNavigationItem
          v-if="hasSubItems(navigationItem)"
          :key="navigationItem.id"
          :name="title(navigationItem)"
          :items="getSubItems(navigationItem)"
          v-menuScript
        />
        <NavigationItem
          v-else
          :key="navigationItem.id"
          :href="href(navigationItem)"
          :title="title(navigationItem)"
          :show-as-button="showAsButton(navigationItem)"
          :is-language-switch="isLanguageSwitch(navigationItem)"
          :is-active="isActive(navigationItem)"
        />
      </template>
    </ul>
  </div>
</template>

<script>
import { CycleUidMixin } from '../../mixins/uid'
import { NavigationItem } from '@/components/elements/NavigationItem'
import { SubNavigationItem } from '@/components/molecules'
import menuScript from './scripts/menuScript'

export default {
  name: 'Navigation',
  components: {
    NavigationItem,
    SubNavigationItem
  },
  directives: {
    menuScript: {
      inserted: menuScript
    }
  },
  mixins: [CycleUidMixin],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    logoLink: {
      type: String,
      required: false,
      default: '/'
    },
    logo: {
      type: Object,
      required: false
    }
  },
  computed: {
    navigationToggleId() {
      return `main-navigation-toggle-${this._cycleUid}`
    },
    hasNavigationItems() {
      return !!this.items?.length
    },
    logoSrc() {
      return this.logo?.src
    },
    logoAlt() {
      return this.logo?.alt
    }
  },
  methods: {
    href(item) {
      return item?.fields?.Link?.value?.href
    },
    title(item) {
      return item?.fields?.Title?.value
    },
    showAsButton(item) {
      return item?.name === 'Inloggen'
    },
    getSubItems(item) {
      return item?.fields?.items ?? []
    },
    hasSubItems(item) {
      const subItems = this.getSubItems(item)
      return subItems.length > 0
    },
    isLanguageSwitch(item) {
      return !!item?.fields?.isLanguageSwitch
    },
    isActive(item) {
      return !!item?.fields?.CurrentItem
    }
  }
}
</script>
