<template>
  <div class="panel">
    <header class="panel__header" v-if="textContent.title">
      <CycleHeading>{{ textContent.title }}</CycleHeading>
    </header>
    <div class="panel__body">
      <CycleHtml :html="textContent.introduction" v-if="textContent.introduction" />
      <slot />
    </div>
  </div>
</template>

<script>
import CycleHtml from '@/components/base/CycleHtml'
import CycleHeading from '@/components/elements/CycleHeading/CycleHeading'

export default {
  name: 'GridComponentAdapter',
  components: { CycleHeading, CycleHtml },
  props: {
    textContent: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
