<template>
  <CycleSimpleForm
    :heading="textContent.heading"
    :next-button-text="textContent.nextButtonText"
    :form-submit-action="handleSubmit"
  >
    <p>{{ textContent.intro }}</p>
    <CycleFormSet>
      <CycleInputContainer>
        <template #label>
          {{ textContent.reasonLabel }}
        </template>
        <CycleSelect v-model="formData.reason" required>
          <template #option>
            <CycleOption v-for="reason in reasons" :value="reasonValue(reason)" :key="reason.id">
              {{ reasonValue(reason) }}
            </CycleOption>
          </template>
        </CycleSelect>
      </CycleInputContainer>
      <CycleInputContainer>
        <template #label>
          {{ textContent.descriptionLabel }}
        </template>
        <CycleTextarea
          v-model="formData.additionalInformation"
          graphql-safe
          :required-if="() => isAdditionalInformationRequired"/>
      </CycleInputContainer>
      <CycleInputContainer>
        <template #label>
          {{ textContent.changeDateLabel }}
          <CycleTooltip v-if="textContent.changeDateTooltip">
            <template #tooltip-heading>
              {{ textContent.changeDateLabel }}
            </template>
            <template #tooltip-content>
              <CycleHtml :html="textContent.changeDateTooltip" />
            </template>
          </CycleTooltip>
        </template>
        <CycleDateInput v-model="formData.startDate" required :min="today" :max="todayNextYear" />
      </CycleInputContainer>
    </CycleFormSet>
    <template #confirmation-confirmed>
      <CycleHtml :html="textContent.postSucceededMessageText" />
    </template>
    <template #confirmation-rejected>
      <CycleHtml :html="textContent.postFailedMessageText" />
    </template>
  </CycleSimpleForm>
</template>

<script>
import { pathOr } from 'ramda'
import format from 'date-fns/format'
import addYears from 'date-fns/addYears'

import CycleForm from '../CycleForm/CycleForm'
import CycleFormPage from '../CycleFormPage/CycleFormPage'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import CycleInputContainer from '../CycleInputContainer/CycleInputContainer'
import CycleOption from '../../../elements/forms/CycleSelect/CycleOption/CycleOption'
import { CycleSelect } from '../../../elements/forms/CycleSelect'
import { CycleTextarea, CycleDateInput } from '../../../elements/forms'
import CycleHtml from '../../../base/CycleHtml'
import CycleTooltip from '../../../cycle-tooltip/CycleTooltip'
import { CycleFormSet } from '../CycleFormSet'
import { ReasonOtherIds } from './constants'

export default {
  name: 'CycleChangePolicy',
  components: {
    CycleInputContainer,
    CycleSimpleForm,
    CycleFormPage,
    CycleForm,
    CycleOption,
    CycleSelect,
    CycleTextarea,
    CycleDateInput,
    CycleHtml,
    CycleTooltip,
    CycleFormSet
  },
  props: {
    reasons: {
      type: Array,
      required: true
    },
    textContent: {
      type: Object,
      required: true
    },
    /** @type {{ policyNumber: string, sourceSystem: string, business: string }} */
    policy: {
      type: Object,
      required: true
    },
    submitState: {
      type: [Promise, Boolean],
      required: false
    },
    isCancelForm: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        policyNumber: this.policy.policyNumber,
        sourceSystem: this.policy.sourceSystem,
        business: this.policy.business,
        policyUniqueIdentifier: this.policy.policyUniqueIdentifier,
        startDate: null,
        reason: null
      }
    }
  },
  computed: {
    today() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    todayNextYear() {
      return format(addYears(new Date(), 1), 'yyyy-MM-dd')
    },
    isAdditionalInformationRequired() {
      return !(
        this.isCancelForm &&
        !ReasonOtherIds.includes(
          this.reasons.find((reason) => reason.value === this.formData.reason)?.id
        )
      )
    }
  },
  methods: {
    async handleSubmit() {
      this.$emit('submit', this.formData)
      await this.$nextTick()

      return this.submitState
    },
    reasonValue(reason) {
      return pathOr('unknown', ['fields', 'Wording', 'value'], reason)
    },
    format
  }
}
</script>
