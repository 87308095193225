<template>
  <AddressLookup
    @input="handleLookupAddress"
    @input-manual="handleManualInputAddress"
    :postal-code-found="postalCodeFound"
    :found-address="foundAddress"
    :has-manual-entry="hasManualEntry"
    :name="name"
    :is-postal-code-disabled="isPostalCodeDisabled"
  />
</template>
<script>
import { textProps, textMap } from '../helpers/textContent'
import { createPostalCodeKey } from '../helpers/createPostalCodeKey'
import AddressLookup from '../components/AddressLookup'
import { AddressDataInputModel } from '../models'
import { Address } from '@/models'

import { intlMixin, storeModuleMixinFactory } from '@/mixins'
import { store } from '../store'
import { getService, registerService, serviceNames } from '@/services/data'
import { AddressLookupService } from '../services'

const mixinConfig = {
  moduleNamespace: 'AddressLookup',
  storeModule: store,
  actionsMapping: ['lookupAddress', 'setAddress'],
  gettersMapping: ['selectedAddress', 'fullState']
}

const serviceName = serviceNames.AddressLookupService

export default {
  name: 'AddressLookupContainer',
  components: {
    AddressLookup
  },
  mixins: [storeModuleMixinFactory(mixinConfig), intlMixin],
  props: {
    ...textProps,
    value: { type: Address, required: true },
    hasManualEntry: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      required: false
    },
    isPostalCodeDisabled: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      textContent: this.textContent
    }
  },
  data() {
    return {
      postalCodeFound: undefined,
      foundAddress: undefined,
      textContent: textMap(this)
    }
  },
  watch: {
    value(newAddress) {
      if (newAddress) {
        this.foundAddress = newAddress
        this.postalCodeFound = !newAddress.isManuallyOverridden
      }
    }
  },
  created() {
    try {
      getService(serviceName)
    } catch (e) {
      registerService(serviceName, AddressLookupService, this.$jss)
    }
  },
  methods: {
    handleManualInputAddress(formData) {
      const manuallyOverriddenAddress = Address.clone({ ...formData, isManuallyOverridden: true })

      const key = `${formData.postalCode}${formData.houseNumber}`
      const postalCodeKey = createPostalCodeKey(key)
      if (manuallyOverriddenAddress.city && manuallyOverriddenAddress.street) {
        this.setAddress({ manuallyOverriddenAddress, postalCodeKey })
        this.$emit('input', this.selectedAddress(postalCodeKey))
      }
    },
    async handleLookupAddress(formData) {
      const key = `${formData.postalCode}${formData.houseNumber}`
      const postalCodeKey = createPostalCodeKey(key)
      this.$emit('communication-start')
      await this.lookupAddress({
        addressLookupInput: new AddressDataInputModel(formData),
        houseNumberAddition: formData.houseNumberAddition,
        postalCodeKey
      })
      this.$emit('input', this.selectedAddress(postalCodeKey))
      this.$emit('communication-end')
    }
  }
}
</script>
