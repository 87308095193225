<template>
  <CycleTag
    v-if="isInProgress || isPending"
    :in-progress="isInProgress"
    :pending="isPending"
    :rejected="isRejected"
  >
    {{ statusValue }}
  </CycleTag>
</template>

<script>
import { isPolicyInProgress, isPolicySuspended, isPolicyRejected } from '@/services/Insurance'
import CycleTag from '../CycleTag/CycleTag'

export default {
  name: 'CycleInsuranceStatusTag',
  components: {
    CycleTag
  },
  inject: ['statusLabels'],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  computed: {
    isInProgress() {
      return isPolicyInProgress(this.status)
    },
    isPending() {
      return isPolicySuspended(this.status)
    },
    isRejected() {
      return isPolicyRejected(this.status)
    },
    statusValue() {
      if (this.isInProgress) {
        return this.statusLabels.statusInProgress
      } else {
        return this.statusLabels.statusSuspended
      }
    }
  }
}
</script>
