<template>
  <CycleForm :on-submit="handleSubmit">
    <CycleFormPage
      :heading="heading"
      :has-next-button="hasNextButton"
      :has-prev-button="false"
      :next-button-text="nextButtonText"
    >
      <slot></slot>
    </CycleFormPage>
    <template #confirmation>
      <CycleFormConfirmation status="confirmed" v-show="confirmed">
        <template #heading>
          <slot name="confirmation-rejected-heading" />
        </template>
        <slot name="confirmation-confirmed" />
        <slot name="thank-you" />
      </CycleFormConfirmation>
      <CycleFormConfirmation status="rejected" v-show="rejected">
        <template #heading>
          <slot name="confirmation-rejected-heading" />
        </template>
        <slot name="confirmation-rejected">
          Het verzenden van het formulier is mislukt.
        </slot>
      </CycleFormConfirmation>
    </template>
  </CycleForm>
</template>
<script>
import { logError } from '@/services/Logging'
import { CycleFormConfirmation } from '../CycleFormConfirmation'
import { CycleFormPage } from '../CycleFormPage'
import { CycleForm } from '../CycleForm'
import { CycleButton } from '@/components/elements'
export default {
  name: 'CycleSimpleForm',
  components: { CycleButton, CycleForm, CycleFormPage, CycleFormConfirmation },
  inject: ['formData'],
  props: {
    heading: {
      type: String
    },
    nextButtonText: {
      type: String,
      required: true
    },
    hasNextButton: {
      type: Boolean,
      required: false,
      default: true
    },
    formSubmitAction: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      confirmed: false,
      rejected: false
    }
  },
  methods: {
    handleSubmit() {
      try {
        const promise = this.formSubmitAction(this.formData)
        this.confirmed = false
        this.rejected = false

        promise
          .then(() => {
            this.confirmed = true
          })
          .catch(() => {
            this.rejected = true
          })

        return promise
      } catch (e) {
        this.rejected = true
        logError(e)
      }
    }
  }
}
</script>
