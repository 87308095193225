//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleBusstop } from '@/components/cycle-busstop'
import { CycleFunnelConfirmation } from '@/components/functional/forms'
import {
  FormContainer,
  StepFinalQuestions,
  ServiceInformation,
  ECommerceTracker
} from '@/components/organisms'

import { funnelMixinFactory } from '@/mixins/funnel'
import { serviceNames, FunnelService } from '@/services/data'
import { GetContentProvider } from '@/services/FunnelContentProvider'
import { uuid4 } from '@/services/UUID'
import { AddressLookupContainer } from '@/modules/AddressLookup'
import { AddressLookupService } from '@/modules/AddressLookup/services'
import { PersonalDataTextSetMap } from '@/constants/textSetLabelMaps'
import { PolicyAction } from '@/constants'

import {
  FunnelQuestionsContainer,
  QuestionRenderType,
  FunnelQuestions
} from '@/modules/FunnelQuestions'

import { FunnelHomeOwnerService, homeOwnerToInputModel } from '../services'
import { default as storeModule } from '../store'
import HomeOwnerStepSetup from './steps/HomeOwnerStepSetup'
import HomeOwnerCoverageSelection from './steps/HomeOwnerCoverageSelection'
import HomeOwnerStepPayment from './steps/HomeOwnerStepPayment'
import HomeOwnerStepPersonalData from './steps/HomeOwnerStepPersonalData'
import HomeOwnerStepOverview from './steps/HomeOwnerStepOverview'

import { createPrerequisiteQuestions } from './helpers/createPrerequisiteQuestions'
import {
  SET_RISK_ADDRESS,
  SET_HOUSE_INFO_FOUND,
  SET_HOUSE_INFO_MANUALLY,
  SET_SELECTED_HOME_BUILDING_TYPE,
  SET_FUNNEL_QUESTIONS,
  SET_PERSONAL_DATA,
  SET_CLIENT_ADDRESS,
  SET_FUNNEL_PREREQUISITE_QUESTIONS,
  GET_ADDRESS
} from '../store/mutation-types'

import {
  SET_COMMUNICATION_START,
  SET_COMMUNICATION_SUCCESS
} from '@/stores/communication/mutation-types'

import { PrerequisiteQuestion, FunnelHomeOwner } from '../constants'

export default {
  name: 'FunnelHomeOwnerSitecore',
  components: {
    CycleBusstop,
    CycleFunnelConfirmation,
    FormContainer,
    FunnelQuestionsContainer,
    HomeOwnerStepPayment,
    HomeOwnerStepSetup,
    HomeOwnerStepOverview,
    HomeOwnerCoverageSelection,
    AddressLookupContainer,
    StepFinalQuestions,
    ServiceInformation,
    HomeOwnerStepPersonalData,
    FunnelQuestions,
    ECommerceTracker
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelHome',
      storeModule,
      stateMapping: ['isVerifiedEmailAddress'],
      actionsMapping: [
        'fetchPremiumRequestFields',
        'submitStepSetup',
        'calculatePremium',
        'updateCoverage',
        'getHouseInfo',
        'handlePayment',
        'submitFunnel'
      ],
      gettersMapping: [
        'homeBuildingTypeValues',
        'checkAcceptanceMessage',
        'riskAddress',
        'clientAddress',
        'selectedPremium',
        'allPremiums',
        'isHouseVolumeRetrieved',
        'isPostalCodeRetrieved',
        'stepPaymentData',
        'person',
        'contactDetails',
        'overviewData',
        'submitResult'
      ],
      mutationsMapping: {
        SET_RISK_ADDRESS,
        SET_HOUSE_INFO_FOUND,
        SET_HOUSE_INFO_MANUALLY,
        SET_SELECTED_HOME_BUILDING_TYPE,
        SET_FUNNEL_QUESTIONS,
        SET_PERSONAL_DATA,
        SET_CLIENT_ADDRESS,
        SET_FUNNEL_PREREQUISITE_QUESTIONS,
        SET_COMMUNICATION_START,
        SET_COMMUNICATION_SUCCESS,
        GET_ADDRESS
      },
      services: [
        {
          serviceName: serviceNames.FunnelHomeOwnerService,
          serviceDefinition: FunnelHomeOwnerService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        },
        {
          serviceName: serviceNames.AddressLookupService,
          serviceDefinition: AddressLookupService
        }
      ]
    })
  ],
  props: {
    textContent: {
      type: Object,
      default: () => ({})
    },
    assign: {
      type: Object,
      default: () => ({})
    },
    transaction: {
      type: String,
      default: () => uuid4()
    },
    funnelName: {
      type: String,
      required: false,
      default: FunnelHomeOwner
    }
  },
  data() {
    return {
      formData: {
        houseVolume: null
      },
      questions: {},
      prerequisiteAnswers: {},
      validateOnInputPrerequisiteValue: 'J'
    }
  },
  computed: {
    steps() {
      return [
        this.textContent?.steps?.setup?.title || '',
        this.textContent?.steps?.coverages?.title || '',
        this.textContent?.steps?.houseDetails?.heading || '',
        this.textContent?.steps?.personalData?.title || '',
        this.textContent?.steps?.payment?.title || '',
        this.textContent?.steps?.finalQuestions?.title || '',
        this.textContent?.steps?.overview?.title || ''
      ]
    },
    showLocationCheck() {
      return this.riskAddress && this.riskAddress.isManuallyOverridden
    },
    showHouseVolumeEntry() {
      return this.isHouseVolumeRetrieved === false && this.riskAddress
    },
    questionRenderType() {
      return QuestionRenderType
    },
    productCode() {
      return this.assign?.premiumRequestProductCode || 'M001-BR826'
    },
    prerequisiteQuestions() {
      return createPrerequisiteQuestions(this.textContent.steps.houseDetails, PrerequisiteQuestion)
    },
    hasPrerequisitesAnsweredYes() {
      /**
       * inversed the value as we agreed the defaults for booleans should be false, used in <StepFinalQuestions>
       *
       */
      return !this.prerequisiteQuestions.every((item) => {
        return this.prerequisiteAnswers[item.code]?.answer === 'N'
      })
    }
  },
  provide() {
    return {
      formData: this.formData, // validation support
      textContent: this.textContent,
      textContentMapper: this.textContentMapper,
      contentProvider: GetContentProvider(this.textContent)
    }
  },
  beforeMount() {
    this.fetchPremiumRequestFields()
  },
  methods: {
    async handleSubmit() {
      await this.submitFunnel(homeOwnerToInputModel(PolicyAction.Create))
    },
    textContentMapper(textKey) {
      if (Object.keys(PersonalDataTextSetMap).includes(textKey)) {
        return PersonalDataTextSetMap[textKey]
      }

      return textKey
    }
  }
}
