<template>
  <div class="infographic">
    <div class="infographic__art">
      <CyclePieChart v-if="segments" :segments="segments" />
    </div>
    <div class="infographic__legend">
      <div class="infographic__title" v-if="title">
        <strong class="infographic__label" style="--infographic-label-color: '';">
          {{ title.label }}
        </strong>
        <span class="infographic__value infographic__value--highlighted">
          {{ title.value | CycleCurrency({ locale }) }}
        </span>
      </div>
      <ul class="infographic__details" v-if="segments">
        <li class="infographic__detail" v-for="(detail, index) in segments" :key="index">
          <span class="infographic__label" :style="`--infographic-label-color:${detail.color};`">{{
            detail.label
          }}</span>
          <span class="infographic__value">{{ detail.value | CycleCurrency({ locale }) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CyclePieChart from '../cycle-pie-chart/CyclePieChart'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'
export default {
  name: 'CycleInfoGraph',
  components: {
    CyclePieChart
  },
  filters: { CycleCurrency },
  mixins: [intlMixin],
  props: {
    segments: {
      type: Array
    },
    title: {
      type: Object
    }
  }
}
</script>
