<template>
  <MyDocuments
    :documents="documents"
    :text-content="textContent"
    :total-number-of-lines="totalNumberOfLines"
    :number-of-lines-on-page="numberOfLinesOnPage"
    :is-loading="isLoading > 0"
    :response-status="responseStatus"
    :page-number="pageNumber"
    :show-reply-message="showReplyMessage"
    @page-change="onPageChange"
    @response="onResponse"
    ref="mydocs"
  />
</template>

<script>
import myDocumentsQuery from './gql/myDocuments.gql'
import replyMessage from './gql/replyMessage.gql'
import { CycleMyDocuments } from '@aon/cycle'
import { mapValues } from 'lodash'
import { logWarning } from '@/services/logging'
import { currentYYYYMMDD } from '@/services/DateTime'

const sortOrder = 'DESC'
const sortBy = 'DATUM'

export default {
  name: 'ReplyMessage',
  components: {
    MyDocuments: CycleMyDocuments
  },
  inject: ['filterOptions', 'setDataFetched'],
  props: {
    fields: Object,
    rendering: Object
  },
  data: () => ({
    documents: undefined,
    isLoading: 1,
    numberOfLinesOnPage: 0,
    pageNumber: 1,
    totalNumberOfLines: 0,
    responseStatus: {},
    documentCodes: [],
    dateFrom: null,
    dateTo: null
  }),
  computed: {
    textContent() {
      return mapValues(this.fields, (item) => item.value ?? item)
    },
    showReplyMessage() {
      return this.fields?.showReplyMessage?.value
    }
  },
  apollo: {
    documents: {
      prefetch: false, // Not prefetching because we don't have authentication
      query: myDocumentsQuery,
      variables() {
        const currentYear = new Date().getFullYear()
        const yearFrom = currentYear - 3
        return {
          inputModel: {
            dateFrom: this.dateFrom ?? `${yearFrom}-01-01`,
            dateTo: this.dateTo ?? currentYYYYMMDD(),
            sortBy,
            sortOrder,
            numberOfLinesOnPage: '10',
            pageNumber: `${this.pageNumber}`,
            filters: {
              documentCodes: this.documentCodes ?? [],
              showPolicies: !this.fields?.ignorePolicies?.value,
              showInvoices: !this.fields?.ignoreInvoices?.value
            }
          }
        }
      },
      update({ documents }) {
        this.totalNumberOfLines = documents?.result?.totalNumberOfLines
        this.numberOfLinesOnPage = documents?.result?.numberOfLinesOnPage
        return documents?.documentsList
      },
      result() {
        this.setDataFetched({ isDataFetched: true })
      },
      // fetchPolicy: 'cache-and-network' is needed for loadingKey and watchLoading to work: https://github.com/vuejs/vue-apollo/issues/263#issuecomment-553039985
      fetchPolicy: 'cache-and-network',
      loadingKey: 'isLoading',
      watchLoading(countModifier) {
        this.isLoading = countModifier
      }
    }
  },
  watch: {
    filterOptions: {
      deep: true,
      handler(newVal) {
        this.pageNumber = 1
        this.documentCodes = newVal.filter?.documentFilter
        this.dateFrom = newVal.filter?.dateFrom
        this.dateTo = newVal.filter?.dateTo
      }
    }
  },
  methods: {
    onPageChange(pageNumber) {
      this.pageNumber = pageNumber
    },
    async onResponse({ document, message }) {
      this.responseStatus[document.id] = 0
      this.$refs.mydocs.$forceUpdate()
      try {
        const resp = await this.$apollo.mutate({
          mutation: replyMessage,
          variables: {
            formDefinition: {
              formDefinitionId: this.rendering.dataSource,
              antiForgeryToken: this.rendering.antiForgeryToken.value
            },
            formValues: {
              message,
              sortOrder,
              sortBy,
              date: document.date,
              documentId: document.id,
              information: document.information
            }
          }
        })
        this.responseStatus[document.id] = resp.data?.replyMessage?.success ? 1 : -1
      } catch (ex) {
        logWarning('Failed to mutate message: ', ex.message)
        this.responseStatus[document.id] = -1
      }
      this.$refs.mydocs.$forceUpdate()
    }
  }
}
</script>
