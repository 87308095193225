//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleForm from '../CycleForm/CycleForm'
import CycleFormPage from '../CycleFormPage/CycleFormPage'
import CycleCard from '../../../card/CycleCard'
import CycleHeading from '../../../elements/CycleHeading/CycleHeading'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import CycleInputContainer from '../CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../../../elements/forms/CycleNumberInput/CycleNumberInput'
import { CycleFormSet } from '../CycleFormSet'

export default {
  name: 'CycleChangeContactDetails',
  components: {
    CycleNumberInput,
    CycleTextInput,
    CycleInputContainer,
    CycleSimpleForm,
    CycleHeading,
    CycleCard,
    CycleFormPage,
    CycleForm,
    CycleFormSet
  },
  props: {
    heading: {
      type: String,
      required: true
    },
    intro: {
      type: String,
      required: true
    },
    nextButtonText: {
      type: String,
      required: true
    },
    handleContactDetailSubmission: {
      type: Function,
      required: true
    }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data: () => ({
    formData: {}
  })
}
