<template>
  <CycleFormSet>
    <template #heading>
      <ProvidedText name="contactDetails.header" />
    </template>

    <slot name="postalcode" />

    <!-- phone number -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="contactDetails.labels.phoneNumber" />

        <ProvidedTooltip
          heading-name="contactDetails.headings.phoneNumber"
          body-name="contactDetails.tooltips.phoneNumber"
        />
      </template>

      <CycleTextInput v-model="formData.contactDetails.phoneNumber" required phone-dutch>
        <ProvidedText name="contactDetails.labels.phoneNumber" />
      </CycleTextInput>
    </CycleInputContainer>

    <!-- email address -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="contactDetails.labels.emailAddress" />

        <ProvidedTooltip
          heading-name="contactDetails.headings.emailAddress"
          body-name="contactDetails.tooltips.emailAddress"
        />
      </template>

      <CycleTextInput v-model="formData.contactDetails.emailAddress" required email>
        <ProvidedText name="contactDetails.labels.emailAddressVerify" />
      </CycleTextInput>
    </CycleInputContainer>

    <!-- email address verification -->
    <CycleInputContainer>
      <template #label>
        <ProvidedText name="contactDetails.labels.emailAddressVerify" />

        <ProvidedTooltip
          heading-name="contactDetails.headings.emailAddressVerify"
          body-name="contactDetails.tooltips.emailAddressVerify"
        />
      </template>

      <CycleTextInput
        v-model="formData.emailAddressVerify"
        @paste.prevent
        required
        email
        :same-as="() => formData.contactDetails.emailAddress"
        autocomplete="new-password"
      >
        <ProvidedText name="contactDetails.labels.emailAddressVerify" />
      </CycleTextInput>
      <template #subtext>
        <ProvidedText name="contactDetails.emailAddress.subtext" />
      </template>
    </CycleInputContainer>
  </CycleFormSet>
</template>

<script>
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput, CycleRadioInput, CycleTextInput } from '@/components/elements/forms'
import { CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { ContactDetails } from '@/models'

import ProvidedHtml from '../Content/ProvidedHtml'
import ProvidedText from '../Content/ProvidedText'
import ProvidedTooltip from '../Content/ProvidedTooltip'

export default {
  name: 'ContactDetailsForm',
  components: {
    CycleButton,
    CycleDateInput,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTextInput,
    CycleTooltip,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  inject: ['formData'],
  props: {
    value: {
      type: ContactDetails
    },
    isVerifiedEmailAddress: {
      type: Boolean
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.$set(this.formData, 'contactDetails', value)

        if (this.isVerifiedEmailAddress) {
          this.formData.emailAddressVerify = value.emailAddress
        }
      }
    }
  }
}
</script>
