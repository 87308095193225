//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleCheckboxInput } from '../../../../../elements'
import { CycleHtml } from '../../../../../base'
import { CycleInputContainer } from '../../../CycleInputContainer'
import { mixin } from './mixin'

export default {
  name: 'CycleFinalQuestionCheckBox',
  components: {
    CycleCheckboxInput,
    CycleHtml,
    CycleInputContainer
  },
  mixins: [mixin],
  props: {
    label: {
      type: String,
      required: false,
      default: 'Ik ga akkoord'
    }
  }
}
