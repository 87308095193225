<template>
  <div :id="playerId">
    <span>{{ placeholder }}</span>
  </div>
</template>

<script>
export default {
  name: 'JwPlayer',
  props: {
    placeholder: { type: String, required: true },
    playerId: { type: String, required: true }
  }
}
</script>
