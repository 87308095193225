import { mergeDeepWith, concat } from 'ramda'

export const writePolicyParams = (params) => {
  const {
    insurer,
    salutation,
    initials,
    preposition,
    lastname,
    birthdate,
    street,
    housenumber,
    housenumberAddition,
    zipcode,
    city,
    emailaddress,
    phonenumber,
    startdate,
    iban,
    paymenttype,
    mailinglist
  } = params

  const finalQuestionAnswers = Object.keys(params.finalQuestions).reduce((accumulator, item) => {
    let response = params.finalQuestions[item]
    if (typeof response === 'string') {
      accumulator.push({ number: item, answer: response })
    }
    return accumulator
  }, [])

  const writePolicyParamValues = {
    values: {
      premiumRequestConfigMapperCode: `PremiumRequestConfig-${insurer}`,
      startingDate: startdate,
      newsLetterSubscription: mailinglist.includes('y'),
      parameters: [
        {
          key: 'AccountNumber',
          value: iban
        },
        {
          key: 'CollectionMethod',
          value: paymenttype
        },
        {
          key: 'PaymentTerm',
          value: '1'
        }
      ],
      /**
       * # DEPRECATION WARNING
       *
       * writing the person input manually is deprecated. When refactoring please
       * use a logical state (based on Cycle models), and have the schema person
       * mapper make the correct input model.
       */
      person: {
        initials: initials,
        prefix: preposition,
        lastName: lastname,
        dateOfBirth: birthdate,
        gender: salutation,
        maritalStatus: '9',
        emailAddress: emailaddress,
        telephoneNumber: phonenumber,
        mobileNumber: phonenumber,
        residentialAddress: {
          street: street ? street.substr(0, 35) : '',
          houseNumber: housenumber,
          houseNumberAddition: housenumberAddition,
          postalCode: zipcode,
          town: city,
          countryCode: 'NL'
        },
        correspondence: 'E-mail'
      },
      acceptanceInputModel: {
        points: '0',
        result: 'Ja',
        explanation: 'Dit is de uitleg'
      },
      acceptanceQuestionsInputModel: {
        questions: finalQuestionAnswers
      }
    }
  }
  return mergeDeepWith(concat, premiumCalculationParams(params), writePolicyParamValues)
}

export const premiumCalculationParams = (params) => {
  const mediation = params.coverages.includes('mediation').toString()
  const finance = params.coverages.includes('finance').toString()
  const income = params.coverages.includes('income').toString()
  const traffic = params.coverages.includes('traffic').toString()
  return {
    values: {
      premiumRequestCode: 'SingleLegalAid',
      parameters: [
        {
          key: 'Action',
          value: 'Nieuw'
        },
        {
          key: 'LegalAidDivorceMediation',
          value: mediation
        },
        {
          key: 'LegalAidFiscalAndAssets',
          value: finance
        },
        {
          key: 'LegalAidIncome',
          value: income
        },
        {
          key: 'LegalAidTraffic',
          value: traffic
        }
      ]
    }
  }
}
