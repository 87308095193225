//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'
import { validationMixin } from 'vuelidate'

import { window } from '@/globals'
// Cannot import from molecules as the `CycleForm` itself is technically a molecule
import BusyIndicator from '@/components/molecules/BusyIndicator.vue'
import CycleFormConfirmation from '../CycleFormConfirmation/CycleFormConfirmation'
import CycleHtml from '../../../base/CycleHtml'
import { dictionaryMixin, CycleUidMixin } from '@/mixins'

export default {
  name: 'CycleForm',
  components: { CycleHtml, CycleFormConfirmation, BusyIndicator },
  mixins: [CycleUidMixin, validationMixin, dictionaryMixin],
  inject: {
    formData: 'formData',
    textContent: { default: {} },
    shouldUseDefaultBrowserScrollBehaviour: {
      default: false
    }
  },
  provide() {
    return {
      formState: this.formState,
      formValidations: this.formValidations,
      formTree: this.formTree,
      formErrors: this.getFormErrors,
      registerFormPage: this.registerFormPage,
      unRegisterFormPage: this.unRegisterFormPage,
      pageName: 'Top level form'
    }
  },
  props: {
    onSubmit: {
      type: Function
    },
    isCommunicating: {
      type: Boolean,
      required: false,
      default: false
    },
    inlineSpinner: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: Number,
      default: 0
    },
    isOverrideSubmit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSubmitting: false,
      technicalError: false,
      formState: {
        index: this.page,
        active: 0,
        next: this.next,
        prev: this.prev,
        show: this.show,
        submitted: false,
        errors: null
      },
      // Collects all validation objects
      formValidations: {},
      // Collects all inputs and labels
      formTree: { form: {} },
      pages: [],
      communicationPromise: Promise.resolve(),
      resolveCommunication: null
    }
  },
  validations() {
    return this.formValidations
  },
  computed: {
    loadingText() {
      if (this.textContent == null || this.textContent.loadingText == null) {
        return null
      }
      return this.textContent.loadingText
    },
    failureText() {
      return this.phrase('error-messages-funnels-001')
    },
    form() {
      return Object.keys(this.formTree.form).reduce((acc, elem) => {
        acc[elem] = this.mapTreeToModels(this.formTree.form[elem])
        return acc
      }, {})
    },
    isLoadingInlineSpinner() {
      if (this.inlineSpinner && (this.isSubmitting || this.isCommunicating)) {
        return this.loadingText
      }

      return false
    }
  },
  watch: {
    ['formState.index']() {
      if (this.isOverrideSubmit && this.formState.index > 0) {
        return
      }
      this.setActivePage()
    },
    isCommunicating: {
      handler(val) {
        if (val && this.resolveCommunication != null) {
          return
        }

        if (!val && this.resolveCommunication != null) {
          this.resolveCommunication()
          this.resolveCommunication = null
          return
        }

        this.communicationPromise = new Promise((resolve) => {
          this.resolveCommunication = resolve
        })
      },
      immediate: false
    }
  },
  mounted() {
    this.setActivePage()
  },
  methods: {
    async setActivePage() {
      this.scrollToView()
      if (this.pages.length > 0) {
        const child = this.pages[this.formState.index]
        if (child) {
          await this.communicationPromise
          this.$emit('page-view', this.formState.index + 1)
          this.formState.active = child
        }
      }
    },
    async submitForm() {
      this.scrollToView()
      this.isSubmitting = true
      this.technicalError = false
      let result = null
      try {
        if (typeof this.onSubmit === 'function') {
          result = await this.onSubmit()
        }
      } catch {
        this.technicalError = true
      }
      if (this.isOverrideSubmit) {
        this.isSubmitting = false
        return
      }
      this.formState.submitted = true
      this.isSubmitting = false

      return result
    },
    scrollToView() {
      if (this.isOverrideSubmit) {
        return
      }
      if (typeof window !== 'undefined' && !this.shouldUseDefaultBrowserScrollBehaviour) {
        const scrollElement = this.$el
        window.requestAnimationFrame(() => {
          scrollElement.scrollIntoView(true)
        })
      }
    },
    next() {
      if (this.formState.index < this.pages.length - 1) {
        this.formState.index++
      } else {
        this.submitForm()
      }
    },
    prev() {
      if (this.formState.index > 0) {
        this.formState.index--
      }
    },
    show(i) {
      if (i >= 0 && i < this.pages.length - 1) {
        this.formState.index = i
      }
    },
    getFormErrors(path) {
      if (!path) return this.$v
      return get(this.$v, path, undefined)
    },
    mapTreeToModels(obj) {
      return Object.keys(obj).reduce((acc, elem) => {
        if (this[elem]) {
          acc[elem] = this[elem]
        } else {
          acc[elem] = null
        }
        return acc
      }, {})
    },
    registerFormPage(formPage) {
      if (formPage == null || formPage._cycleUid == null) {
        throw new Error('It is not possible to use a component without uid as form page.')
      }

      if (this.pages.length === this.index) {
        this.active = formPage._cycleUid
      }
      this.pages.push(formPage._cycleUid)
    },
    unRegisterFormPage(formPage) {
      const componentIndex = this.pages.indexOf(formPage._cycleUid)
      if (componentIndex < 0) {
        return
      }
      this.pages.splice(componentIndex, 1)
    }
  }
}
