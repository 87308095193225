<template>
  <label class="form__radio">
    <input
      type="radio"
      v-bind="Object.assign({}, attributes, $attrs)"
      v-on="Object.assign({}, listeners, $listeners, { change: updateValue })"
      :value="value"
      :checked="checked"
    />
    <span>
      <slot v-if="$slots.default" />
      <template v-else>{{ label }}</template>
    </span>
  </label>
</template>

<script>
import BaseInput from '../BaseInput'
export default {
  name: 'CycleRadioInput',
  extends: BaseInput,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    // Deprecated
    label: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      required: false,
      default: () => ''
    }
  },
  computed: {
    checked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value)
    }
  }
}
</script>
