<template>
  <div :class="classList">
    <header v-if="title" :class="headerClassName">
      <CycleHeading :weight="2">
        {{ title }}
        <span v-if="badgeText" class="badge badge--right">{{ badgeText }}</span>
      </CycleHeading>
    </header>
    <div class="panel__body">
      <CycleHtml :html="bodyText" v-if="bodyText" />
      <slot />
    </div>
    <footer class="panel__footer" v-if="isFooterVisible">
      <nav class="panel__buttons" v-if="hasLinks">
        <CycleButtonLink
          v-for="(item, index) in links"
          :key="index"
          :href="href(item)"
          :target="target(item)"
          modifiers="pre-funnel"
          v-testable="'heroButton'"
        >
          {{ item.title }}
        </CycleButtonLink>
      </nav>
      <div class="panel__footer-content" v-if="panelFooterContent">
        <CycleHtml :html="panelFooterContent" />
      </div>
    </footer>
  </div>
</template>

<script>
import { CycleHeading } from '@/components/elements/CycleHeading'
import { CycleButtonLink } from '@/components/cycle-link'
import { CycleHtml } from '@/components/base'

export default {
  name: 'CycleHeaderPanel',
  components: {
    CycleHeading,
    CycleHtml,
    CycleButtonLink
  },
  props: {
    title: { type: String },
    badgeText: { type: String },
    bodyText: { type: String },
    links: { type: Array },
    alternative: {
      type: Boolean,
      default: false
    },
    panelFooterContent: { type: String }
  },
  computed: {
    hasLinks() {
      return this.links?.length > 0
    },
    classList() {
      return {
        ['panel']: true,
        ['panel--hero']: !this.alternative,
        ['panel--hero-heading']: this.alternative
      }
    },
    isFooterVisible() {
      return !this.alternative && (!!this.panelFooterContent || this.hasLinks)
    },
    headerClassName() {
      return {
        ['panel__header']: true,
        ['panel__header--badge']: !!this.badgeText
      }
    }
  },
  methods: {
    href(item) {
      return item?.href
    },
    target(item) {
      return item?.target
    }
  }
}
</script>
