<template>
  <div>
    <slot name="top"></slot>
    <CycleFormSet
      v-for="(ovGroup, ovGroupIndex) in overviewFiltered"
      :key="`ov-group-${ovGroupIndex}`"
      :heading="ovGroup.title"
    >
      <dl>
        <template v-for="(entry, entryIndex) in ovGroup.filteredEntries">
          <dt :key="`ov-group-${ovGroupIndex}-entry-${entryIndex}-dt`">
            {{ entry.label }}
          </dt>
          <dd :key="`ov-group-${ovGroupIndex}-entry-${entryIndex}-dd`">
            {{ renderItemValue(entry) }}
          </dd>
        </template>
      </dl>
    </CycleFormSet>
    <slot name="bottom"></slot>
  </div>
</template>

<script>
import { intlMixin } from '../../../../../mixins'
import { CycleDate, CycleCurrency } from '../../../../../filters'
import { CycleFormSet } from '../../CycleFormSet'
import { OverviewEntryTypes } from './models'

export default {
  name: 'CycleFunnelOverview',
  components: {
    CycleFormSet
  },
  mixins: [intlMixin],
  props: {
    overview: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    overviewFiltered() {
      return this.overview.filter((ovItem) => ovItem.isValid)
    }
  },
  methods: {
    renderItemValue(item) {
      switch (item.type) {
        case OverviewEntryTypes.DATE:
          return CycleDate(item.value, { locale: this.locale })
        case OverviewEntryTypes.CURRENCY:
          return CycleCurrency(item.value, { locale: this.locale })
        default:
          return item.value
      }
    }
  }
}
</script>
