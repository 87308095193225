//
//
//
//

import window from '@/globals/window'
import { CycleUidMixin } from '@/mixins'
import { JwPlayer } from '@/components/atoms'

const storyteqSrc =
  'https://storage.googleapis.com/storyteq-video-player/dist/storyteq-video-player.min.js'
const pollForSeconds = 1

export default {
  name: 'StoryteqContainer',
  components: {
    JwPlayer
  },
  mixins: [CycleUidMixin],
  props: {
    paramName: { type: String, required: false },
    placeholder: { type: String, required: true },
    videoKey: { type: String, required: false }
  },
  data: () => ({
    defaultInterval: 100
  }),
  computed: {
    /**
     * Calculates the amount of attempts needed to reach or exceed pollForSeconds with the defaultInterval.
     * 5 times is used as a fallback.
     */
    maxPollAttempts() {
      if (this.defaultInterval === 0) {
        return 5
      }
      return Math.ceil((pollForSeconds * 1000) / this.defaultInterval)
    },
    playerId() {
      return `jwplayer-${this._cycleUid}`
    }
  },
  /**
   * Check whether the Storyteq connector has already been loaded and loads it if needed.
   */
  beforeCreate() {
    if (window.StoryteqConnectorJwPlayer != null || window.document == null) {
      return
    }

    const storyteqElement = window.document.createElement('script')
    storyteqElement.type = 'text/javascript'
    storyteqElement.src = storyteqSrc
    storyteqElement.async = true

    window.document.getElementsByTagName('head').item(0).appendChild(storyteqElement)
  },
  mounted() {
    this.pollInit()
  },
  methods: {
    async pollInit(max = this.maxPollAttempts, current = 1, interval = this.defaultInterval) {
      if (window.StoryteqConnectorJwPlayer != null) {
        window.connector = new window.StoryteqConnectorJwPlayer({
          videoPlayerId: this.playerId,
          videoHash: this.videoKey,
          videoParameterName: this.paramName
        })
        return
      }

      if (current === max) {
        throw new Error('Storyteq connector for JwPlayer did not load, cannot start the video.')
      }

      await new Promise((r) => setTimeout(r, interval))
      this.pollInit(max, current + 1, interval)
    }
  }
}
