//
//
//
//
//
//

export default {
  name: 'WarningMessage'
}
