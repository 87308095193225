<template>
  <span v-if="title" @click.prevent="handleSubCategorySelected" :class="className">
    {{ title }}
  </span>
</template>
<script>
import { computed } from 'vue'

export default {
  name: 'FaqSubCategory',
  props: {
    title: {
      type: String
    },
    isOpen: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const className = computed(() => {
      const baseClassForSubCategory = 'faq__subcategory'
      return {
        [`${baseClassForSubCategory}`]: true,
        [`${baseClassForSubCategory}--open`]: props.isOpen
      }
    })

    const handleSubCategorySelected = () => {
      emit('subCategorySelected')
    }

    return {
      className,
      handleSubCategorySelected
    }
  }
}
</script>
