<template>
  <div v-if="isCategoryVisible" :class="className">
    <div class="faq__category-trigger" @click.prevent="handleFaqCategoryClick">
      <h6 class="faq__category-title">
        <CycleIcon :icon="faqIcon" />
        {{ category.title }}
      </h6>
    </div>
    <slot />
  </div>
</template>
<script>
import { computed, ref } from 'vue'
import { CycleIcon } from '@/components/elements'

export default {
  name: 'FaqCategory',
  components: {
    CycleIcon
  },
  props: {
    category: {
      type: Object,
      default: () => ({})
    },
    isActive: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const hasFaqItems = computed(() => props.category?.faqItems?.length > 0)
    const hasSubCategories = computed(() => props.category?.subCategories?.length > 0)

    const isCategoryVisible = computed(() => hasSubCategories.value || hasFaqItems.value)
    const className = computed(() => {
      const baseClassForCategory = 'faq__category'
      return {
        [`${baseClassForCategory}`]: true,
        [`${baseClassForCategory}--no-subcategories`]: !hasSubCategories.value,
        [`${baseClassForCategory}--active`]: props.isActive,
        [`${baseClassForCategory}--open`]: !hasFaqItems.value && isOpen.value
      }
    })

    const faqIcon = computed(() => props.category?.icon || 'shield')
    const toggleCategory = () => {
      isOpen.value = !isOpen.value
    }
    const handleFaqCategoryClick = () => {
      toggleCategory()
      emit('categorySelected')
    }
    return {
      isCategoryVisible,
      className,
      faqIcon,
      handleFaqCategoryClick
    }
  }
}
</script>
