<template>
  <CycleFormPage :on-submit="handleSubmit">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="liability.steps.payment.header" />
      </CycleHeading>
    </template>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="liability.steps.payment.headings.startDate" />
      </template>
      <ProvidedHtml name="liability.steps.payment.content.startDate" />

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="liability.steps.payment.labels.startDate" />
        </template>

        <CycleDateInput
          v-model="formData.startDate"
          required
          :min="minStartDate"
          :max="maxStartDate"
        />

        <template #error-min>
          {{ phrase('error-messages-funnels-002') }}
        </template>

        <template #error-max>
          {{ phrase('error-messages-funnels-003') }}
        </template>
      </CycleInputContainer>
    </CycleFormSet>
    <PaymentDetailsForm :payment-details="paymentDetails" existing-customer />

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="liability.steps.payment.labels.next" />
      </CycleButton>
    </template>
    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="liability.steps.payment.labels.prev" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { addDays, format } from 'date-fns'

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { PaymentDetailsForm, ProvidedText, ProvidedHtml } from '@/components/organisms'

import { dictionaryMixin } from '@/mixins'

export default {
  name: 'LiabilityStepPayment',
  components: {
    CycleButton,
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    PaymentDetailsForm,
    ProvidedHtml,
    ProvidedText
  },
  inject: ['formData'],
  mixins: [dictionaryMixin],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    paymentDetails: undefined,
    startDate: undefined
  }),
  computed: {
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }

        this.paymentDetails = newValue.paymentDetails
        this.startDate = newValue.startDate
      },
      immediate: true
    },
    ['formData.startDate'](newValue) {
      this.startDate = newValue
    }
  },
  created() {
    this.$set(this.formData, 'startDate', this.startDate) // Needed for validations
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', { paymentDetails: this.paymentDetails, startDate: this.startDate })
    }
  }
}
</script>
