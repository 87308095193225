<template>
  <div class="checkout-item checkout-item--payment">
    <p class="checkout-item__label">
      {{ amountDescription }}
      <span class="checkout-item__total">{{ amount | CycleCurrency({ locale }) }}</span>
    </p>
    <p class="checkout-item__info">
      {{ amountSubtext }}
    </p>
  </div>
</template>

<script>
import { CycleCurrency } from '../../../../filters/cycle-currency/cycle-currency'
import { intlMixin } from '../../../../mixins/intl'

export default {
  name: 'CycleCheckoutLabel',
  filters: {
    CycleCurrency
  },
  mixins: [intlMixin],
  props: {
    amount: {
      type: [String, Number]
    },
    amountDescription: {
      type: String
    },
    amountSubtext: {
      type: String
    }
  }
}
</script>
