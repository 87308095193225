<template>
  <CycleFormPage :on-submit="handleSubmit" :has-prev-button="false">
    <template #header>
      <CycleHeading :weight="3"><ProvidedText name="liability.steps.setup.header" /></CycleHeading>
    </template>

    <CycleFormSet>
      <CycleInputContainer>
        <!-- <template #heading>
          <ProvidedText name="liability.steps.setup.headings.changeDate" />
        </template>
        <ProvidedHtml name="liability.steps.setup.content.changeDate" /> -->

        <template #label>
          <ProvidedText name="liability.steps.setup.labels.householdComposition" />

          <ProvidedTooltip
            heading-name="liability.steps.setup.labels.householdComposition"
            body-name="liability.steps.setup.tooltips.householdComposition"
          />
        </template>

        <CycleRadioInput
          v-for="type in familyTypes"
          v-model="familyType"
          :value="type"
          :key="type"
          required
        >
          <ProvidedText :name="`liability.steps.setup.labels.familyTypes.${type}`" />
        </CycleRadioInput>
      </CycleInputContainer>

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="liability.steps.setup.labels.insuredAmount" />

          <ProvidedTooltip
            heading-name="liability.steps.setup.labels.insuredAmount"
            body-name="liability.steps.setup.tooltips.insuredAmount"
          />
        </template>

        <CycleRadioInput
          v-for="amount in insurableAmounts"
          v-model="insuredAmount"
          :key="amount"
          :value="amount"
          required
        >
          {{ amount | CycleCurrency({ locale }) }}
        </CycleRadioInput>
      </CycleInputContainer>
    </CycleFormSet>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="liability.steps.setup.labels.next" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleRadioInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'

import { FamilyType, InsurableAmounts } from '../../constants'

export default {
  name: 'LiabilityStepSetup',
  inject: ['formData'],
  components: {
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTooltip,
    ProvidedText,
    ProvidedTooltip
  },
  filters: { CycleCurrency },
  mixins: [intlMixin],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      familyType: null,
      insuredAmount: null
    }
  },
  computed: {
    familyTypes() {
      return FamilyType
    },
    insurableAmounts() {
      return InsurableAmounts
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.familyType = newValue.familyType
        this.insuredAmount = newValue.insuredAmount
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        familyType: this.familyType,
        insuredAmount: this.insuredAmount
      })
    }
  }
}
</script>
