// FIXME: rewrite to types
const setFormData = (state, data) => (state.formData = data)

const setGraphqlResult = (state, { key, data }) =>
  (state.graphqlResults = { ...state.graphqlResults, [key]: data })

const setFunnelMeta = (state, data) => {
  const {
    antiForgeryToken: { value },
    dataSource
  } = data
  state.funnelMeta = {
    antiForgeryToken: value,
    dataSource
  }
}
const mutations = {
  setFormData,
  setGraphqlResult,
  setFunnelMeta
}

export default mutations
