import { OverviewEntryTypes } from './OverviewEntryTypes'

export class OverviewEntryModel {
  constructor({ label = null, value = null, type = OverviewEntryTypes.STRING }) {
    this.label = label
    this.value = value
    this.type = OverviewEntryTypes[type] ? OverviewEntryTypes[type] : OverviewEntryTypes.STRING
  }

  get isValid() {
    return this.label && this.value
  }
}
