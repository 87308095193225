<script>
/**
 * This component expects 2 children.
 *   - The first child passed will be used as the label for the information
 *   - The second child will become the content of the information
 *
 * ```jsx
 * <NamedInformation>
 *   <Text>I'm the title</Text>
 *   <RetrieveTextFromObscureSource name="text-for-cool-title" />
 * </NamedInformation>
 * ```
 *
 * will turn into
 * ```jsx
 * <li>
 *   <label>I'm the title</label>
 *   <p>[Whatever the obscure source returns]</p>
 * </li>
 * ```
 */
import { removeBlanksFromChildren } from '../../../services/Vue'

export default {
  name: 'NamedInformation',
  functional: true,
  props: {
    showOnMobile: {
      type: Boolean,
      default: false
    }
  },
  render(createElement, context) {
    const children = removeBlanksFromChildren(context.children)

    return createElement(
      'li',
      {
        attrs: { class: context.props.showOnMobile ? 'showonmobile' : '' }
      },
      [createElement('label', [children[0]]), createElement('p', [children[1]])]
    )
  }
}
</script>
