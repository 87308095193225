//
//
//
//
//
//
//
//
//

import { pick } from 'lodash'
import CycleContactForm from '../../functional/forms/CycleContactForm/CycleContactForm'
import { jssMixin } from '../../../mixins'

export default {
  name: 'CycleContactFormRequestSitecore',
  components: {
    CycleContactForm
  },
  mixins: [jssMixin],
  props: {
    pageTitle: {
      type: String
    },
    sendFormLabel: {
      type: String
    },
    contactTelephoneNumber: {
      type: String
    },
    setHelpHeading: {
      type: String
    },
    choiceLabel: {
      type: String
    },
    changeOption: {
      type: String
    },
    questionOption: {
      type: String
    },
    damageOption: {
      type: String
    },
    setChangeHeading: {
      type: String
    },
    changeLabel: {
      type: String
    },
    setQuestionHeading: {
      type: String
    },
    questionLabel: {
      type: String
    },
    setContactInfoHeading: {
      type: String
    },
    nameLabel: {
      type: String
    },
    namePlaceholderText: {
      type: String
    },
    emailLabel: {
      type: String
    },
    phonenumberLabel: {
      type: String
    },
    yesOption: {
      type: String
    },
    noOption: {
      type: String
    },
    setPrivacyHeading: {
      type: String
    },
    privacyStatementText: {
      type: String
    },
    setDamageHeading: {
      type: String
    },
    damageText: {
      type: String
    },
    damageClaimsNumber: {
      type: String
    },
    postSucceededMessageText: {
      type: String
    },
    postFailedMessageText: {
      type: String
    },
    postalCodeLabel: {
      type: String
    },
    houseNumberLabel: {
      type: String
    },
    houseNumberAdditionLabel: {
      type: String
    },
    changeRequestChoiceLabel: {
      type: String
    },
    questionRequestChoiceLabel: {
      type: String
    },
    changeRequestHeading: {
      type: String
    },
    questionRequestHeading: {
      type: String
    },
    changeRequestOptions: {
      type: Array,
      default: () => []
    },
    questionRequestOptions: {
      type: Array,
      default: () => []
    },
    shouldUseDefaultBrowserScrollBehaviour: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      shouldUseDefaultBrowserScrollBehaviour: this.shouldUseDefaultBrowserScrollBehaviour
    }
  },
  computed: {
    textContent() {
      return pick(this, [
        'pageTitle',
        'sendFormLabel',
        'contactTelephoneNumber',
        'setHelpHeading',
        'choiceLabel',
        'changeOption',
        'questionOption',
        'damageOption',
        'setChangeHeading',
        'changeLabel',
        'setQuestionHeading',
        'questionLabel',
        'setContactInfoHeading',
        'nameLabel',
        'namePlaceholderText',
        'emailLabel',
        'phonenumberLabel',
        'yesOption',
        'noOption',
        'setPrivacyHeading',
        'privacyStatementText',
        'setDamageHeading',
        'damageText',
        'damageClaimsNumber',
        'postSucceededMessageText',
        'postFailedMessageText',
        'postalCodeLabel',
        'houseNumberLabel',
        'houseNumberAdditionLabel',
        'questionRequestHeading',
        'changeRequestHeading',
        'questionRequestChoiceLabel',
        'changeRequestChoiceLabel'
      ])
    },
    changeRequestOptionList() {
      return this.changeRequestOptions.map((opt) => {
        const mailId = opt.id
        const title = opt.fields?.title?.value
        return { title, mailId }
      })
    },

    questionRequestOptionList() {
      return this.questionRequestOptions.map((opt) => {
        const mailId = opt.id
        const title = opt.fields?.title?.value
        return { title, mailId }
      })
    }
  },
  methods: {
    handleFormSubmit(formData) {
      const requestContactForm = 'requestContactForm'
      return this.gql.mutationByName(requestContactForm, {
        formValues: formData
      })
    }
  }
}
