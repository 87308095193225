
import { get } from 'lodash'

import { CycleHtml } from '../../base'

export default {
  name: 'ProvidedHtml',
  inject: ['textContent'],
  props: {
    name: {
      type: String,
      required: true
    }
  },
  render(createElement) {
    const text = get(this.textContent, this.name, this.name)

    return createElement(CycleHtml, { props: { html: text } })
  }
}
