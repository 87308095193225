import { get } from 'lodash'

import { CycleFaqList } from '../../../components/cycle-faq-list'
import { storeModuleMixinFactory } from '../../../mixins'
import { FaqInputModel } from '../models'
import store from '../store'

const displayComponents = {
  list: 'CycleFaqList'
}
const displayComponentValidator = (value) => typeof displayComponents[value] !== 'undefined'

const displayLimitDefault = 4

export default {
  name: 'CycleFaqSitecore',
  components: {
    CycleFaqList
  },
  mixins: [
    storeModuleMixinFactory({
      moduleNamespace: 'faq',
      storeModule: store,
      actionsMapping: true,
      gettersMapping: true
    })
  ],
  props: {
    category: {
      type: String,
      required: false
    },
    includeTags: {
      type: String,
      required: false
    },
    excludeTags: {
      type: String,
      required: false
    },
    question: {
      type: String,
      required: false
    },
    answer: {
      type: String,
      required: false
    },
    questionOrAnswer: {
      type: String,
      required: false
    },
    displayComponent: {
      type: String,
      default: 'list',
      required: false,
      validator: displayComponentValidator
    },
    displayLimit: {
      type: [String, Number],
      default: displayLimitDefault,
      required: false
    }
  },
  computed: {
    inputModel() {
      return new FaqInputModel({
        category: this.category || undefined,
        includeTags: this.includeTagsArray,
        excludeTags: this.excludeTagsArray,
        question: this.question || undefined,
        answer: this.answer || undefined,
        questionOrAnswer: this.questionOrAnswer || undefined
      })
    },
    includeTagsArray() {
      return this.includeTags ? this.includeTags.split(',') : undefined
    },
    excludeTagsArray() {
      return this.excludeTags ? this.excludeTags.split(',') : undefined
    },
    selectedQuestionLimit() {
      return Number.parseInt(this.displayLimit, 10) || displayLimitDefault
    },
    selectedQuestionsKey() {
      return get(this, ['inputModel', 'dehydrate'], '')
    },
    selectedQuestions() {
      const selectedQuestions = get(this, ['questionsByKey', this.selectedQuestionsKey], [])
      return selectedQuestions.slice(0, this.selectedQuestionLimit)
    },
    displayComponentDefinitionName() {
      return displayComponents[this.displayComponent]
    }
  },
  watch: {
    inputModel: {
      immediate: true,
      handler() {
        this.updateQuestions()
      }
    }
  },
  methods: {
    updateQuestions() {
      this.fetchQuestions(this.inputModel)
    }
  },
  mounted() {
    this.updateQuestions()
  }
}
