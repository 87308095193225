<template>
  <CycleCard>
    <template #header>
      <CycleHeading>
        <ProvidedText name="bankAccount.header" />
      </CycleHeading>
    </template>

    <template #body>
      <InformationLines>
        <NamedInformation>
          <CycleHtml v-if="!loading && !hasBankAccount" :html="textContent.bankAccount.text.fallbackText"/>
          <template v-else-if="!loading && hasBankAccount">
            <ProvidedText name="bankAccount.labels.accountNumberLabel" />
            <IbanPrivacy :account-number="bankAccount.accountNumber" :censored="censored" @toggleCensor="toggleCensor" />
          </template>
        </NamedInformation>
        <NamedInformation v-if="!loading && hasBankAccount && hasPaymentMethod">
          <ProvidedText name="bankAccount.labels.paymentMethodLabel" />
          <p>{{ bankAccount.paymentMethod.value }} </p>
        </NamedInformation>
      </InformationLines>
    </template>

    <template #footer>
      <div>
        <CycleNav buttons v-if="!loading && hasBankAccount" data-testid="footer-nav">
          <CycleButtonLink :href="button.buttonLink.href" modifiers="cta, secondary">
            {{ button.buttonText }}
          </CycleButtonLink>
        </CycleNav>
      </div>
    </template>
  </CycleCard>
</template>

<script>
import { CycleHtml } from '@/components/base'
import { CycleCard } from '@/components/card'
import { CycleNav } from '@/components/cycle-nav'
import { CycleHeading } from '@/components/elements'
import { IbanPrivacy } from '@/components/molecules'
import { CycleButtonLink } from '@/components/cycle-link'
import ProvidedText from '../../Content/ProvidedText'
import { InformationLines, NamedInformation } from '@/components/atoms'

export default {
  inject: ['textContent', 'button'],
  name: 'BankAccount',
  components: {
    CycleHtml,
    CycleCard,
    CycleNav,
    CycleHeading,
    IbanPrivacy,
    InformationLines,
    NamedInformation,
    ProvidedText,
    CycleButtonLink
  },
  data: () => ({
    censored: true
  }),
  computed: {
    loading() {
      return this.loadingBankAccount > 0
    },
    hasBankAccount() {
      return this.bankAccount?.found
    },
    hasPaymentMethod() {
      return this.bankAccount?.paymentMethod?.value
    }
  },
  methods: {
    toggleCensor() {
      this.censored = !this.censored
    }
  },
  props: {
    loadingBankAccount: {
      type: Number,
      required: true,
    },
    bankAccount: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>
