<template>
  <FormContainer :on-submit="handleSubmit" store-namespace="funnelLiability">
    <template #header="{state}">
      <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
      <slot name="collectivity-picker" v-if="state.index === 0" />
    </template>

    <LiabilityStepSetup :value="stepSetupData" @submit="handleSetup" />

    <LiabilityStepOffers :premium="availablePremium" />

    <LiabilityStepPersonalData
      :text-content="textContent"
      :address="fullState.address"
      :value="stepPersonalDataData"
      :is-verified-email-address="isVerifiedEmailAddress"
      @submit="commit(SET_PERSONAL_DATA, $event)"
    >
      <template #postalcode>
        <AddressLookupContainer :value="fullState.address" @input="lookupAddress" />
      </template>
    </LiabilityStepPersonalData>

    <LiabilityStepPayment :value="stepPaymentData" @submit="handlePayment" />

    <LiabilityStepFinalQuestions :value="questions" @submit="commit(SET_FUNNEL_QUESTIONS, $event)">
      <FunnelQuestionsContainer
        :product-code="selectedProductCode"
        v-model="questions"
        :render-type="questionRenderType.Final"
      />
      <ServiceInformation :service-info="serviceInfo" />
    </LiabilityStepFinalQuestions>

    <LiabilityStepOverview v-bind="fullState">
      <FunnelQuestionsContainer
        :product-code="selectedProductCode"
        v-model="questions"
        :render-type="questionRenderType.Agreement"
      />
    </LiabilityStepOverview>

    <template #confirmation>
      <CycleFunnelConfirmation :result="submitResult" />
    </template>
  </FormContainer>
</template>

<script>
import { CycleAddress } from '@/components/cycle-address'
import { CycleBusstop } from '@/components/cycle-busstop'
import { CycleFunnelConfirmation } from '@/components/functional/forms'
import { FormContainer, ServiceInformation } from '@/components/organisms'

import { funnelMixinFactory } from '@/mixins/funnel'
import { Address } from '@/models'
import { PolicyAction } from '@/constants'
import { serviceNames, FunnelService } from '@/services/data'
import { FunnelQuestionsContainer } from '@/modules/FunnelQuestions'
import { QuestionRenderType } from '@/modules/FunnelQuestions/models'

import { ProductPremiumService, writePolicyMapper } from '../services'
import { default as storeModule } from '../store'
import { SET_FUNNEL_QUESTIONS, SET_PERSONAL_DATA } from '../store/mutation-types'

import LiabilityStepSetup from './Steps/LiabilityStepSetup'
import LiabilityStepOffers from './Steps/LiabilityStepOffers'
import LiabilityStepPersonalData from './Steps/LiabilityStepPersonalData'
import LiabilityStepPayment from './Steps/LiabilityStepPayment'
import LiabilityStepFinalQuestions from './Steps/LiabilityStepFinalQuestions'
import LiabilityStepOverview from './Steps/LiabilityStepOverview'
import { textProps, textMap } from './textContent'

import { AddressLookupContainer } from '@/modules/AddressLookup'
import { merge } from 'lodash'

export default {
  name: 'FunnelLiabilitySitecore',
  components: {
    CycleAddress,
    CycleBusstop,
    CycleFunnelConfirmation,
    FormContainer,
    FunnelQuestionsContainer,
    LiabilityStepOffers,
    LiabilityStepSetup,
    LiabilityStepPersonalData,
    LiabilityStepPayment,
    LiabilityStepFinalQuestions,
    LiabilityStepOverview,
    AddressLookupContainer,
    ServiceInformation
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelLiability',
      storeModule,
      stateMapping: ['isVerifiedEmailAddress'],
      actionsMapping: ['handleSetup', 'handlePayment', 'handleAddressLookup', 'submitFunnel'],
      gettersMapping: [
        'availablePremium',
        'fullState',
        'selectedProductCode',
        'stepPaymentData',
        'stepPersonalDataData',
        'stepSetupData',
        'submitResult',
        'hasCommunicationErrors'
      ],
      mutationsMapping: { SET_PERSONAL_DATA, SET_FUNNEL_QUESTIONS },
      services: [
        {
          serviceName: serviceNames.ProductPremiumService,
          serviceDefinition: ProductPremiumService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        }
      ]
    })
  ],
  props: {
    ...textProps,
    serviceInfo: {
      type: Array,
      required: false
    },
    textContent: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      address: new Address(),
      formData: {},
      questions: {}
    }
  },
  computed: {
    steps() {
      return [
        this.page1title,
        this.page2title,
        this.page3title,
        this.page4title,
        this.page5title,
        this.page6title
      ]
    },
    questionRenderType() {
      return QuestionRenderType
    }
  },
  provide() {
    return {
      formData: this.formData, // legacy support?
      textContent: merge({}, textMap(this), this.textContent)
    }
  },
  methods: {
    async handleSubmit() {
      await this.submitFunnel(writePolicyMapper(PolicyAction.Create))
    },
    async lookupAddress(data) {
      await this.handleAddressLookup(data)
    }
  }
}
</script>
