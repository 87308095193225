import { getService, serviceNames } from '../../../services/data'
import { FaqInputModel } from '../models'
import { SET_QUESTIONS } from './mutation-types'

const serviceName = serviceNames.FaqService

export const actions = {
  async fetchQuestions({ commit }, input) {
    const inputModel = new FaqInputModel(input)
    const key = inputModel.dehydrate
    const questions = await getService(serviceName).getQuestions(inputModel)
    commit(SET_QUESTIONS, { key, questions })
  }
}

export default actions
