<template>
  <CycleFormPage>
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.overview.title" />
      </CycleHeading>
    </template>

    <ProvidedHtml name="steps.overview.content.intro" />
    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.generalHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="steps.setup.insuredAmount.label" /></dt>
        <dd>
          {{ overviewData.insuredAmount | CycleCurrency({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.setup.weddingDate.label" /></dt>
        <dd>
          {{ overviewData.weddingDate | CycleDate({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.setup.weddingCountry.label" /></dt>
        <dd>
          {{ overviewData.weddingCountry }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.setup.weddingPartyCountry.label" /></dt>
        <dd>
          {{ overviewData.weddingPartyCountry }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.coverages.baseCoverage.label" /></dt>
        <dd>
          {{ premiumCoverage(coverageTypes.basicInsurance) | CycleCurrency({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.coverages.weddingClothesAndGifts.label" /></dt>
        <dd>
          {{ premiumCoverage(coverageTypes.weddingClothesAndGifts) | CycleCurrency({ locale }) }}
        </dd>
      </dl>
      <!-- list of selected coverages -->
      <dl v-for="coverage in overviewData.optionalCoverages" :key="coverage">
        <dt><ProvidedText :name="`steps.coverages.${coverage}.label`" /></dt>
        <dd>
          {{ premiumCoverage(coverage) | CycleCurrency({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="paymentDetails.totalAmountSingle.label" /></dt>
        <dd>
          {{ totalPremium | CycleCurrency({ locale }) }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.personalDetailHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="personalData.salutation.label" /></dt>
        <dd>
          <ProvidedText
            :name="`personalData.salutation${
              overviewData.gender === 'M' ? 'Male' : 'Female'
            }.label`"
          />
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.initials.label" /></dt>
        <dd>
          {{ overviewData.initials }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.infix.label" /></dt>
        <dd>
          {{ overviewData.tussenvoegsel }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.lastName.label" /></dt>
        <dd>
          {{ overviewData.lastName }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.dateOfBirth.label" /></dt>
        <dd>
          {{ overviewData.dateOfBirth | CycleDate({ locale }) }}
        </dd>
      </dl>
      <template v-if="overviewData.address">
        <dl>
          <dt><ProvidedText name="address.postalCode.label" /></dt>
          <dd>
            {{ overviewData.address.postalCode }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.houseNumber.label" /></dt>
          <dd>
            {{ overviewData.address.houseNumber }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.street.label" /></dt>
          <dd>
            {{ overviewData.address.street }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.city.label" /></dt>
          <dd>
            {{ overviewData.address.city }}
          </dd>
        </dl>
      </template>
      <dl>
        <dt><ProvidedText name="contactDetails.emailAddress.label" /></dt>
        <dd>
          {{ overviewData.emailAddress }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="newsletter.signUp.label" /></dt>
        <dd>
          <ProvidedText :name="phrase('general-yes')" v-if="overviewData.mailingList" />
          <ProvidedText :name="phrase('general-no')" v-else />
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="contactDetails.phoneNumber.label" /></dt>
        <dd>
          {{ overviewData.phoneNumber }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.paymenttypeHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="paymentDetails.iban.label" /></dt>
        <dd>
          {{ overviewData.paymentDetails.iban }}
        </dd>
        <dt><ProvidedText name="Betaaltermijn" /></dt>
        <dd>Eenmalig</dd>
        <dt><ProvidedText name="Betaalwijze" /></dt>
        <dd>Automatische incasso</dd>
      </dl>
    </CycleFormSet>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.overview.nextButton" />
      </CycleButton>
    </template>
    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="steps.overview.backButton" />
      </CycleButton>
    </template>
    <slot />
  </CycleFormPage>
</template>

<script>
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { dictionaryMixin, intlMixin } from '@/mixins'
import { WeddingCoverageTypes } from '../../models'

export default {
  name: 'WeddingStepOverview',
  components: {
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    overviewData: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      coverageTypes: WeddingCoverageTypes
    }
  },
  computed: {
    totalPremium() {
      return this.overviewData?.premium?.totalPremium
    }
  },
  methods: {
    premiumCoverage(coverage) {
      return this.overviewData?.premium?.coverages[coverage]
    }
  }
}
</script>
