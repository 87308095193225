export default {
  formDefinition({ funnelMeta }) {
    return {
      antiForgeryToken: funnelMeta.antiForgeryToken,
      dataSource: funnelMeta.dataSource
    }
  },
  submitResult({ graphqlResults }) {
    if (graphqlResults.submitFunnel == null) {
      return {}
    }
    return graphqlResults.submitFunnel.write.policyResults[0].result
  }
}
