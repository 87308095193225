//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
export default {
  name: 'CycleRadioInput',
  extends: BaseInput,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    // Deprecated
    label: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      required: false,
      default: () => ''
    }
  },
  computed: {
    checked() {
      return this.modelValue === this.value
    }
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value)
    }
  }
}
