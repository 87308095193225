//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleCard from '../card/CycleCard'
import { CycleList } from '../cycle-list/'
import Heading from '../elements/CycleHeading/CycleHeading'

export default {
  name: 'CycleCardList',
  components: {
    CycleCard,
    CycleList,
    Heading
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false
    },
    modifiers: {
      type: String,
      default: '',
      required: false
    }
  },
  methods: {
    hasSlot(slotName = 'default') {
      return !!this.$scopedSlots[slotName] || !!this.$slots[slotName]
    }
  }
}
