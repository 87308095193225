export const QuestionMixin = {
  props: {
    question: {
      type: Object,
      required: false,
      default: () => ({})
    },
    name: {
      type: String,
      required: true
    },
    validationPath: {
      type: String,
      required: true
    }
  },
  computed: {
    description() {
      return this.question.description || ''
    },
    code() {
      return this.question.code || ''
    }
  }
}
