export const labels = [
  'page1title',
  'page2title',
  'page3title',
  'page4title',
  'page5title',
  'page6title',

  'page1NextButton',
  'page2NextButton',
  'page3NextButton',
  'page4NextButton',
  'page5NextButton',
  'page6NextButton',

  'page2PrevButton',
  'page3PrevButton',
  'page4PrevButton',
  'page5PrevButton',
  'page6PrevButton',

  'introText',

  'chosenCoverages',
  'optionalCoverages',
  'defaultCoverage',
  'defaultCoverageTooltip',
  'traffic',
  'trafficTooltip',
  'mediation',
  'mediationTooltip',
  'income',
  'incomeTooltip',
  'finance',
  'financeTooltip',

  'salutationLabel',
  'salutationTooltip',
  'initialsLabel',
  'initialsTooltip',
  'prepositionLabel',
  'prepositionTooltip',
  'lastnameLabel',
  'lastnameTooltip',
  'birthdateLabel',
  'birthdateTooltip',
  'zipcodeLabel',
  'zipcodePlaceholder',
  'zipcodeTooltip',
  'housenumberLabel',
  'housenumberTooltip',
  'streetLabel',
  'streetTooltip',
  'cityLabel',
  'cityTooltip',
  'phonenumberLabel',
  'phonenumberTooltip',
  'emailaddressLabel',
  'emailaddressTooltip',
  'emailaddressRepeatLabel',
  'emailaddressRepeatTooltip',
  'emailaddressSubtext',
  'mailinglistLabel',
  'mailinglistText',
  'paymenttypeLabel',
  'paymenttermLabel',
  'paymenttypeTooltip',
  'ibanLabel',
  'ibanPlaceholder',
  'ibanTooltip',
  'paymenttermMonth',

  'premiumTaxesText',
  'premiumTaxesExText',
  'premiumTaxesHeader',
  'premiumTaxesTooltip',
  'premiumInfoText',
  'existingCustomerText',
  'startdateText',
  'paymenttypeText',
  'overviewText',

  'contactTitle',
  'startdateTitle',
  'paymenttypeTitle',
  'generalTitle'
]

export const labelProps = labels.reduce(
  (accumulator, label) =>
    Object.assign(accumulator, {
      [label]: { type: String, required: false }
    }),
  {}
)
