<template>
  <NotificationBar :notification-type="modifiers">
    <ProvidedHtml :name="bodytext" />
  </NotificationBar>
</template>

<script>
import { NotificationBar } from '@/components/atoms'
import { ProvidedHtml } from '@/components/organisms'

export default {
  name: 'CycleNotificationSitecore',
  components: {
    NotificationBar,
    ProvidedHtml
  },
  props: {
    modifiers: {
      type: String,
      required: true
    },
    bodytext: {
      type: String,
      required: true
    }
  }
}
</script>
