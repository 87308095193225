//
//
//
//
//
//
//
//
//
//
//
//

import { CycleIcon } from '@/components/elements'

export default {
  name: 'InboxItem',
  components: { CycleIcon },
  props: {
    isOpen: Boolean,
    isHiddenOnLarge: Boolean
  },
  methods: {
    handleClick(event) {
      this.$emit('open', event)
    }
  }
}
