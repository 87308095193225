<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'DocumentWrapper'
}
</script>
