<template>
  <component :is="listTag" :class="bemClass" v-bind="$attrs">
    <slot />
  </component>
</template>
<script>
import { CycleBaseComponent } from '../base'

export default {
  name: 'CycleList',
  extends: CycleBaseComponent,
  props: {
    ordered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      bem: {
        allowedModifiers: [
          'usp',
          'nousp',
          'usp-no-usp',
          'nomarker',
          'nostyle',
          'bar',
          'horizontal',
          'checked',
          'pros',
          'cons',
          'flat'
        ],
        block: 'list'
      }
    }
  },
  computed: {
    listTag() {
      return this.ordered ? 'ol' : 'ul'
    }
  }
}
</script>
