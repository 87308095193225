<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :heading="contentProvider.getContentByPath('steps.overview.title')"
    :next-button-text="contentProvider.getContentByPath('steps.overview.nextButton')"
    :prev-button-text="contentProvider.getContentByPath('steps.overview.backButton')"
  >
    <ProvidedHtml name="overview.content.intro" />
    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.generalHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="steps.overview.labels.basePremiumLabel" /></dt>
        <dd>
          {{ overviewData.basePremium | CycleCurrency({ locale }) }}
        </dd>
      </dl>
      <dl v-if="overviewData.hasHomeAdditionalCoverageGarage">
        <dt><ProvidedText name="steps.overview.labels.additionalGaragePremiumLabel" /></dt>
        <dd>
          {{ overviewData.additionalGaragePremium | CycleCurrency({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.overview.labels.totalPremiumLabel" /></dt>
        <dd>
          {{ overviewData.totalPremium | CycleCurrency({ locale }) }}
        </dd>
      </dl>
    </CycleFormSet>
    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.homeDetailsHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="steps.setup.labels.homebuildingtype" /></dt>
        <dd>
          {{ overviewData.buildingType }}
        </dd>
      </dl>
      <template v-if="overviewData.riskAddress">
        <dl>
          <dt><ProvidedText name="address.labels.postalCode" /></dt>
          <dd>
            {{ overviewData.riskAddress.postalCode }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.labels.houseNumberAndSuffix" /></dt>
          <dd>
            {{ overviewData.riskAddress.houseNumber }}
            <span v-if="riskAddressHasHouseNumberAddition">
              {{ overviewData.riskAddress.houseNumberAddition }}
            </span>
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.labels.street" /></dt>
          <dd>
            {{ overviewData.riskAddress.street }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.labels.city" /></dt>
          <dd>
            {{ overviewData.riskAddress.city }}
          </dd>
        </dl>
        <dl v-if="overviewData.riskAddress.isManuallyOverridden">
          <dt><ProvidedText name="steps.setup.labels.homelocation" /></dt>
          <dd>
            {{ isAbroad ? phrase('general-no', 'Nee') : phrase('general-yes', 'Ja') }}
          </dd>
        </dl>
      </template>
      <dl v-if="isHouseVolumeManuallyFilled">
        <dt><ProvidedText name="steps.setup.labels.houseVolume" /></dt>
        <dd>{{ overviewData.houseVolume }} m3</dd>
      </dl>
      <dl>
        <dt>
          <ProvidedText name="steps.houseDetails.prerequisiteQuestionThatchedroof.questionLabel" />
        </dt>
        <dd>
          {{
            overviewData.prerequisiteQuestionThatchedroofAnswer === 'N'
              ? phrase('general-no', 'Nee')
              : phrase('general-yes', 'Ja')
          }}
        </dd>
      </dl>
      <dl>
        <dt>
          <ProvidedText name="steps.houseDetails.prerequisiteQuestionMonument.questionLabel" />
        </dt>
        <dd>
          {{
            overviewData.prerequisiteQuestionMonumentAnswer === 'N'
              ? phrase('general-no', 'Nee')
              : phrase('general-yes', 'Ja')
          }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="steps.houseDetails.prerequisiteQuestionRental.questionLabel" /></dt>
        <dd>
          {{
            overviewData.prerequisiteQuestionRentalAnswer === 'N'
              ? phrase('general-no', 'Nee')
              : phrase('general-yes', 'Ja')
          }}
        </dd>
      </dl>
      <dl>
        <dt>
          <ProvidedText name="steps.houseDetails.prerequisiteQuestionRecreational.questionLabel" />
        </dt>
        <dd>
          {{
            overviewData.prerequisiteQuestionRecreationalAnswer === 'N'
              ? phrase('general-no', 'Nee')
              : phrase('general-yes', 'Ja')
          }}
        </dd>
      </dl>
    </CycleFormSet>
    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.personalDetailsHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="personalData.labels.salutation" /></dt>
        <dd>
          <ProvidedText
            :name="`personalData.labels.salutation${
              overviewData.person.gender === 'M' ? 'Male' : 'Female'
            }`"
          />
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.labels.initials" /></dt>
        <dd>
          {{ overviewData.person.initials }}
        </dd>
      </dl>
      <dl v-if="overviewData.person.tussenvoegsel">
        <dt><ProvidedText name="personalData.labels.infix" /></dt>
        <dd>
          {{ overviewData.person.tussenvoegsel }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.labels.lastName" /></dt>
        <dd>
          {{ overviewData.person.lastName }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="personalData.labels.dateOfBirth" /></dt>
        <dd>
          {{ overviewData.person.dateOfBirth | CycleDate({ locale }) }}
        </dd>
      </dl>
      <template v-if="overviewData.clientAddress">
        <dl>
          <dt><ProvidedText name="address.labels.postalCode" /></dt>
          <dd>
            {{ overviewData.clientAddress.postalCode }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.labels.houseNumberAndSuffix" /></dt>
          <dd>
            {{ overviewData.clientAddress.houseNumber }}
            <span v-if="clientAddressHasHouseNumberAddition">
              {{ overviewData.clientAddress.houseNumberAddition }}
            </span>
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.labels.street" /></dt>
          <dd>
            {{ overviewData.clientAddress.street }}
          </dd>
        </dl>
        <dl>
          <dt><ProvidedText name="address.labels.city" /></dt>
          <dd>
            {{ overviewData.clientAddress.city }}
          </dd>
        </dl>
      </template>
      <dl>
        <dt><ProvidedText name="contactDetails.labels.emailAddress" /></dt>
        <dd>
          {{ overviewData.contactDetails.emailAddress }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="newsletter.labels.signUp" /></dt>
        <dd>
          {{
            overviewData.approvedMailingList
              ? phrase('general-yes', 'Ja')
              : phrase('general-no', 'Nee')
          }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="contactDetails.labels.phoneNumber" /></dt>
        <dd>
          {{ overviewData.contactDetails.phoneNumber }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet>
      <template #heading>
        <ProvidedText name="steps.overview.headings.paymentDetailsHeading" />
      </template>
      <dl>
        <dt><ProvidedText name="steps.overview.labels.startDateLabel" /></dt>
        <dd>
          {{ overviewData.startDate | CycleDate({ locale }) }}
        </dd>
      </dl>
      <dl>
        <dt><ProvidedText name="paymentDetails.iban.label" /></dt>
        <dd>
          {{ overviewData.paymentDetails.iban }}
        </dd>
        <dt><ProvidedText name="paymentDetails.labels.paymentTerm" /></dt>
        <dd>
          <ProvidedText name="overview.premiumDurationValues.monthly" />
        </dd>
        <dt><ProvidedText name="paymentDetails.labels.paymentType" /></dt>
        <dd>
          <ProvidedText name="overview.paymentAutomaticDebit.label" />
        </dd>
      </dl>
    </CycleFormSet>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.overview.nextButton" />
      </CycleButton>
    </template>
    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="steps.overview.backButton" />
      </CycleButton>
    </template>
    <slot />
  </CycleFormPage>
</template>

<script>
import { CycleFormPage } from '@/components/functional/forms'
import { CycleCurrency, CycleDate } from '@/filters'
import { dictionaryMixin, intlMixin } from '@/mixins'

export default {
  name: 'HomeOwnerStepOverview',
  components: { CycleFormPage },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [dictionaryMixin, intlMixin],
  inject: ['contentProvider', 'formData'],
  props: {
    overviewData: {
      required: true,
      type: Object
    },
    value: { type: Object, required: true }
  },
  computed: {
    isAbroad() {
      return this.formData.homelocation === false
    },
    riskAddressHasHouseNumberAddition() {
      return this.overviewData.riskAddress.houseNumberAddition
    },
    clientAddressHasHouseNumberAddition() {
      return this.overviewData.clientAddress.houseNumberAddition
    },
    isHouseVolumeManuallyFilled() {
      return !this.overviewData.isHouseVolumeRetrieved && this.overviewData.houseVolume
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
</script>
