
import { policySwitch } from '../../services/SourceMatrix'

export default {
  name: 'SourceMatrixCondition',
  props: {
    policy: Object,
    when: Array
  },
  render() {
    return policySwitch(
      this.policy,
      this.when.includes('aonDirect70') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonLegalAid') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonLiability') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonCar') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonTravel') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonShortTravel') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonEffects') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonProperty') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonWedding') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('meeus') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('IAK') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerk') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerkLiability') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerkTravel') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerkCar') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerkLegal') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerkHomeOwner') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('mijnWerkHomeContents') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonVerzekeringen') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('aonDirect10') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('OHIZorg') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('AnvaC90') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('AnvaC95') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('AnvaC20') ? this.$slots.default[0] : this.$slots.else,
      this.when.includes('default') ? this.$slots.default[0] : this.$slots.else
    )
  }
}
