//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleIcon } from '@/components/elements/CycleIcon'
import { CycleLink } from '@/components/cycle-link'
export default {
  name: 'CompareTable',
  components: {
    CycleIcon,
    CycleLink
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultClass: 'compare-coverages'
    }
  },
  methods: {
    getCellClass(field) {
      const cellClass = this.defaultClass + '__cell'
      const classList = [cellClass]
      if (field?.label) {
        classList.push(`${cellClass}--with-label`)
      }
      return classList
    },
    showIcon(field) {
      return !!field?.fields?.Icon?.value
    },
    showLink(field) {
      return !!field?.fields?.Link?.value.href
    },
    getLink(field) {
      return field?.fields?.Link?.value
    },
    getIcon(field) {
      return field?.fields?.Icon?.value === 'unchecked' ? 'cross' : 'check'
    },
    getText(field) {
      return field?.fields?.Text?.value || ''
    }
  }
}
