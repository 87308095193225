<template>
  <CycleFormPage
    :on-submit="handleSubmit"
    :heading="contentProvider.getContentByPath('steps.acceptance.title')"
    :prev-button-text="contentProvider.getContentByPath('steps.acceptance.backButton')"
    :next-button-text="contentProvider.getContentByPath('steps.acceptance.nextButton')"
    :hide-next-button="isNotInsurable"
  >
    <CycleFormSet>
      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.acceptance.ownHome.label" />

          <ProvidedTooltip
            heading-name="steps.acceptance.ownHome.label"
            body-name="steps.acceptance.ownHome.tooltip"
          />
        </template>

        <CycleRadioInput
          v-model="formData.ownHome"
          :value="true"
          :label="contentProvider.getContentByPath('steps.acceptance.ownHome.yesOption')"
          required
        />
        <CycleRadioInput
          v-model="formData.ownHome"
          :value="false"
          :label="contentProvider.getContentByPath('steps.acceptance.ownHome.noOption')"
          required
        />
      </CycleInputContainer>

      <CycleInputContainer v-if="formData.ownHome">
        <template #label>
          <ProvidedText name="steps.acceptance.rental.label" />

          <ProvidedTooltip
            heading-name="steps.acceptance.rental.label"
            body-name="steps.acceptance.rental.tooltip"
          />
        </template>

        <CycleRadioInput
          v-model="formData.rental"
          :value="true"
          :label="contentProvider.getContentByPath('steps.acceptance.rental.yesOption')"
          required
        />
        <CycleRadioInput
          v-model="formData.rental"
          :value="false"
          :label="contentProvider.getContentByPath('steps.acceptance.rental.noOption')"
          required
        />
      </CycleInputContainer>
      <template v-if="formData.rental">
        <p class="form__errormessage">{{ phrase('funnel-contents-002-rental-not-allowed') }}</p>
      </template>

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.acceptance.woodenOrThatchedRoof.label" />

          <ProvidedTooltip
            heading-name="steps.acceptance.woodenOrThatchedRoof.label"
            body-name="steps.acceptance.woodenOrThatchedRoof.tooltip"
          />
        </template>

        <CycleRadioInput
          v-model="formData.woodenOrThatchedRoof"
          :value="true"
          :label="
            contentProvider.getContentByPath('steps.acceptance.woodenOrThatchedRoof.yesOption')
          "
          required
        />
        <CycleRadioInput
          v-model="formData.woodenOrThatchedRoof"
          :value="false"
          :label="
            contentProvider.getContentByPath('steps.acceptance.woodenOrThatchedRoof.noOption')
          "
          required
        />
      </CycleInputContainer>
      <template v-if="formData.woodenOrThatchedRoof">
        <p class="form__errormessage">
          {{ phrase(`funnel-contents-003-wooden-home-or-thatched-roof`) }}
        </p>
      </template>

      <CycleInputContainer>
        <template #label>
          <ProvidedText name="steps.acceptance.recreationalHome.label" />

          <ProvidedTooltip
            heading-name="steps.acceptance.recreationalHome.label"
            body-name="steps.acceptance.recreationalHome.tooltip"
          />
        </template>

        <CycleRadioInput
          v-model="formData.recreationalHome"
          :value="true"
          :label="contentProvider.getContentByPath('steps.acceptance.recreationalHome.yesOption')"
          required
        />
        <CycleRadioInput
          v-model="formData.recreationalHome"
          :value="false"
          :label="contentProvider.getContentByPath('steps.acceptance.recreationalHome.noOption')"
          required
        />
      </CycleInputContainer>
      <template v-if="formData.recreationalHome">
        <p class="form__errormessage">
          {{ phrase(`funnel-contents-004-recreational-or-second-home-not-allowed`) }}
        </p>
      </template>
    </CycleFormSet>
  </CycleFormPage>
</template>

<script>
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { CycleRadioInput } from '@/components/elements'
import { dictionaryMixin } from '@/mixins'

export default {
  name: 'HomeContentsStepAcceptance',
  components: { CycleInputContainer, CycleFormPage, CycleFormSet, CycleRadioInput },
  inject: ['formData', 'contentProvider'],
  mixins: [dictionaryMixin],
  computed: {
    isNotInsurable() {
      return (
        !!this.formData.rental ||
        !!this.formData.woodenOrThatchedRoof ||
        !!this.formData.recreationalHome
      )
    }
  },
  watch: {
    'formData.ownHome'() {
      this.formData.rental = undefined
    }
  },
  methods: {
    handleSubmit() {
      const { ownHome, rental, woodenOrThatchedRoof, recreationalHome } = this.formData

      this.$emit('submit', {
        ownHome: { answer: ownHome },
        rental: { answer: rental },
        woodenOrThatchedRoof: { answer: woodenOrThatchedRoof },
        recreationalHome: { answer: recreationalHome }
      })
    }
  }
}
</script>

<style scoped></style>
