<template>
  <component :is="displayComponentDefinitionName" :faq-data="selectedQuestions" />
</template>

<script>
import { default as Faq } from './Faq'
import { registerService, serviceNames } from '../../../services/data'
import { FaqService } from '../services'

const serviceName = serviceNames.FaqService

export default {
  ...Faq,
  beforeCreate() {
    registerService(serviceName, FaqService, this.$jss)
  }
}
</script>
