import { OverviewGroupModel, OverviewEntryTypes } from '../../../shared'

export function driver({ textContent, formData, currentSecurityValue }) {
  return new OverviewGroupModel({
    title: textContent.driverTitle,
    entries: [
      ...(!formData.regulardriver
        ? [
            {
              label: 'Naam',
              value: formData.driver.fullName
            },
            {
              label: textContent.birthdateLabel,
              value: formData.driver.birthdate,
              type: OverviewEntryTypes.DATE
            },
            {
              label: textContent.zipcodeLabel,
              value: formData.driver.zipcode
            },
            {
              label: textContent.housenumberLabel,
              value: [formData.driver.housenumber, formData.driver.housenumberAddition]
                .join(' ')
                .trim()
            },
            {
              label: textContent.streetLabel,
              value: formData.driver.street
            },
            {
              label: textContent.cityLabel,
              value: formData.driver.city
            }
          ]
        : []),
      {
        label: textContent.claimfreeyearsLabel,
        value: formData.claimfreeyears
      },
      {
        label: textContent.distanceperyearLabel,
        value: formData.distanceperyear
      },
      {
        label: textContent.carsecurityLabel,
        value: currentSecurityValue
      },
      {
        label: textContent.licenseholderLabel,
        value: formData.licenseholder ? 'Ja' : 'Nee'
      },
      {
        label: textContent.regulardriverLabel,
        value: formData.regulardriver ? 'Ja' : 'Nee'
      }
    ]
  })
}
