//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleChangeContactDetails from '../../functional/forms/CycleChangeContactDetails/CycleChangeContactDetails'
import CycleHtml from '../../base/CycleHtml'
import { jssMixin } from '../../../mixins'

export default {
  name: 'CycleChangeContactDetailsSitecore',
  components: { CycleHtml, CycleChangeContactDetails },
  mixins: [jssMixin],
  props: {
    heading: {
      type: String,
      required: false,
      default: ''
    },
    intro: {
      type: String,
      required: false,
      default: ''
    },
    emailLabel: {
      type: String,
      required: false,
      default: 'email'
    },
    emailLabelvalidate: {
      type: String,
      required: false,
      default: 'email nogmaals'
    },
    emailSubtext: {
      type: String,
      required: false,
      default: ''
    },
    emailSubtextValidate: {
      type: String,
      required: false,
      default: ''
    },
    phoneLabel: {
      type: String,
      required: false,
      default: 'telefoonnummer 1'
    },
    phoneSubtext: {
      type: String,
      required: false,
      default: ''
    },
    phoneLabel2: {
      type: String,
      required: false,
      default: 'telefoonnummer 2'
    },
    nextButtonLabel: {
      type: String,
      required: false,
      default: 'Volgende'
    },
    thankYouText: {
      type: String,
      required: false,
      default: ''
    },
    failureText: {
      type: String,
      required: false,
      default: 'Probeer het later opnieuw.'
    }
  },
  methods: {
    handleContactDetailSubmission(formData) {
      const formValues = {
        emailAddress: formData.emailaddress,
        phoneNumber: formData.phone,
        phoneNumber2: formData.phone2
      }
      return this.gql.mutationByName('changeContactDetails', { formValues })
    }
  }
}
