const textWithDefaults = {
  zipcodeLabel: 'Postcode',
  zipcodeTooltip: '',
  housenumberLabel: 'Huisnummer',
  housenumberTooltip: '',
  housenumberAdditionLabel: 'Toevoeging',
  streetLabel: 'Straat',
  streetTooltip: '',
  cityLabel: 'Woonplaats',
  cityTooltip: '',
  zipcodeCheckFailureText: ''
}
export const text = Object.keys(textWithDefaults)
export const textProps = Object.keys(textWithDefaults).reduce(
  (accumulator, label) =>
    Object.assign(accumulator, {
      [label]: {
        type: String,
        required: false,
        default: textWithDefaults[label]
      }
    }),
  {}
)
export const textMap = (context) => ({
  address: {
    labels: {
      postalCode: context.zipcodeLabel,
      housenumber: context.housenumberLabel,
      housenumberAddition: context.housenumberAdditionLabel,
      street: context.streetLabel,
      city: context.cityLabel
    },
    tooltips: {
      postalCode: context.zipcodeTooltip,
      housenumber: context.housenumberTooltip,
      street: context.streetTooltip,
      city: context.cityTooltip
    }
  }
})
