import { merge } from 'lodash'
import { logInfo } from '@/services/Logging'
import defaults from '@/constants/defaults'
import { formatOptions } from './cycle-currency.constants'
import { nbsp, nnbsp } from '@/constants/CharacterMap'

export function CycleCurrency(value, options = {}) {
  const { defaultLocale } = defaults
  const combinedOptions = merge(
    {},
    {
      locale: defaultLocale,
      formatOptions
    },
    options
  )

  if (typeof Intl === undefined) {
    return value
  }

  if (value == null) {
    return '-,--'
  }

  const valueAsFloat = Number.parseFloat(value)
  if (isNaN(valueAsFloat)) {
    return value
  }

  try {
      const formattedValue = new Intl.NumberFormat(combinedOptions.locale, combinedOptions.formatOptions)
        .format(valueAsFloat)
        .replace(new RegExp(`${nbsp}`, 'g'), nnbsp)

      return combinedOptions.split
        ? `<span class="comparison__price-pre-decimal">${formattedValue.slice(0,-2)}</span><span class="dec comparison__price-decimal">${formattedValue.slice(-2)}</span>`
        : formattedValue


  } catch (e) {
    logInfo(`CycleCurrency: couldn't format ${value} using options `, options, e)

    return value
  }
}
