//
//
//
//
//
//
//
//
//
//
//

import CycleHeading from '../elements/CycleHeading/CycleHeading'
import { dictionaryMixin } from '../../mixins/dictionary'
import { get } from 'lodash'

export default {
  name: 'CycleFormNotification',
  components: { CycleHeading },
  mixins: [dictionaryMixin],
  inject: ['formErrors', 'pageName', 'formTree'],
  computed: {
    errors() {
      return this.formErrors(this.pagePath) || {}
    },
    errorElements() {
      return this.flattenErrors(this.formTree, this.errors, this.pagePath)
    },
    errorTitle() {
      return this.phrase('error-messages-general-001')
    },
    pagePath() {
      return `form.${this.pageName}`
    }
  },
  methods: {
    flattenErrors(formTree, errors, path) {
      return Object.entries(errors)
        .filter(([key, value]) => {
          return value && value.$invalid && typeof value === 'object' && key in errors.$params
        })
        .reduce((acc, [key, value]) => {
          const objectPath = path != null ? `${path}.${key}` : key
          const recursiveErrors = this.flattenErrors(formTree, value, objectPath)
          if (Object.entries(recursiveErrors).length) {
            return { ...acc, ...recursiveErrors }
          }

          const label = get(formTree, objectPath, key)
          return { ...acc, [objectPath]: label }
        }, {})
    }
  }
}
