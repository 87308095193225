<template>
  <CycleFunnelCar
    :text-content="textContent"
    :get-premiums-action="getPremiums"
    :check-acceptance="checkAcceptance"
    :get-premiums-for-configuration-action="getPremiumsForConfiguration"
    :get-premiums-for-configuration-selection-action="getPremiumsForConfigurationSelection"
    :form-submit-action="formSubmit"
    @coverageChange="handleCoverageChange"
    :lookup-action="licenseplateLookup"
    :field-information="premiumRequestFields"
    :premium-information="premiumInformation"
    :vehicle-information="vehicleInformation"
    :final-questions="finalQuestions"
    :optional-coverage-information="optionalCoverageInformation"
    :check-sign-code-and-license-plate="checkSignCodeAndLicensePlate"
    @page-view="handlePageView"
    :declined-license-plate-message="declinedLicenseMessage"
    :is-accepted-license-plate="isAcceptedLicensePlate"
    ref="funnel"
  >
    <template #collectivity-picker>
      <slot name="collectivity-picker" />
    </template>

    <template #confirmation>
      <CycleFunnelConfirmation :result="submissionResults" />
    </template>
  </CycleFunnelCar>
</template>

<script>
import { CycleFunnelCar } from './../../functional'
import { pathOr } from 'ramda'
import { jssMixin, dictionaryMixin } from '../../../mixins'
import { funnelMixin } from '../funnel'
import { storeModuleMixinFactory } from '@/mixins'
import store from '@/stores/profile'
import { pick } from 'lodash'
import { logError } from '../../../services/Logging'
import { isPolicyResultSuccess } from '../../../services/PolicyResults'
import { registerPurchase, registerCheckout } from '../../../services/Ecommerce'
import { uuid4 } from '../../../services/UUID'
import {
  writePolicyParams,
  premiumCalculationParams,
  premiumCalculationParamsForConfiguration,
  checkAcceptanceParams
} from './queryParams'

import { labels, labelProps } from './labels'
import { premiumResultConfigurationModel } from './helpers'

import * as gql from './gql'
import CycleFunnelConfirmation from '../../functional/forms/CycleFunnelConfirmation/CycleFunnelConfirmation'

export default {
  name: 'CycleFunnelCarSitecore',
  components: {
    CycleFunnelConfirmation,
    CycleFunnelCar
  },
  mixins: [
    jssMixin,
    funnelMixin,
    dictionaryMixin,
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      gettersMapping: ['licensePlate']
    })
  ],
  provide() {
    return {
      formData: this.formData
    }
  },
  props: {
    ...labelProps,
    transaction: {
      type: String,
      required: false,
      default: () => uuid4()
    }
  },
  data() {
    const finalQuestions = {}
    return {
      finalQuestions,
      formData: {
        car: true,
        accessories: 1250,
        checkSignCodeResponse: null,
        claimfreeyears: '',
        coverage: '',
        coverages: [],
        driver: {},
        finalQuestions,
        licenseholder: undefined,
        licenseowner: {},
        licenseplate: null,
        mailinglist: ['y'],
        onlyprivate: undefined,
        paymenttype: 'VM',
        regulardriver: undefined,
        signCode: null
      },
      submissionResults: {},
      premiumResults: {},
      premiumResultForConfiguration: {},
      premiumResultForConfigurationSelection: {},
      productInfoResults: {},
      vehicleInformation: undefined,
      requestFieldsResults: undefined,
      declinedLicenseMessage: undefined,
      isAcceptedLicensePlate: true
    }
  },
  computed: {
    textContent() {
      return {
        ...pick(this, labels)
      }
    },
    vehicleAgeInMonths() {
      if (this.vehicleInformation) {
        const { constructionMonth, constructionYear } = this.vehicleInformation
        const now = new Date()
        const constructionTotalMonths = constructionYear * 12 + constructionMonth
        const nowTotalMonths = now.getFullYear() * 12 + (now.getMonth() + 1)
        return nowTotalMonths - constructionTotalMonths
      }
      return null
    },
    premiumInformation() {
      if (Object.keys(this.premiumResults).length === 0) {
        return []
      }

      const obj = this.premiumResults.premium.configurations.reduce(
        (accumulator, configuration) => {
          const {
            insurer,
            configurationId,
            order,
            price,
            productCode,
            coverageCode,
            textKeys
          } = new premiumResultConfigurationModel(configuration)

          let isInsurable = false
          let isHighlighted = false
          switch (configurationId) {
            case 'PremiumRequestConfig-Aon-Car-WA':
              isInsurable = this.vehicleAgeInMonths <= 300
              isHighlighted = this.vehicleAgeInMonths >= 120
              break
            case 'PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco':
              isInsurable = this.vehicleAgeInMonths <= 180
              isHighlighted = this.vehicleAgeInMonths < 120 && this.vehicleAgeInMonths >= 60
              break
            case 'PremiumRequestConfig-Aon-Car-WA-Casco':
              isInsurable = this.vehicleAgeInMonths <= 120
              isHighlighted = this.vehicleAgeInMonths < 60
              break
          }

          const uSPDescription = pathOr(
            '',
            [productCode, 'premiumRequestProduct', 'uSPDescription'],
            this.productInfoResults
          )
          // uSPDescription is an unpredictable blob of HTML
          // let's try to fetch what we need
          const productUSPs = (
            uSPDescription.match(/<li[^>]*>([^<]+)<\/li>+/gi) || []
          ).map((item) => item.replace(/.*>([^<]+)<\/.*/, '$1'))

          accumulator[insurer] = {
            isInsurable,
            isHighlighted,
            insurer,
            configurationId,
            price,
            order,
            productCode,
            productUSPs,
            coverageCode,
            texts: Object.keys(textKeys).reduce(
              (acc, key) =>
                Object.assign(acc, {
                  [key]: pathOr('', [textKeys[key]], this)
                }),
              {}
            )
          }
          return accumulator
        },
        {}
      )

      return ['Aon-Car-WA', 'Aon-Car-WA-Beperkt-Casco', 'Aon-Car-WA-Casco'].map((key) => obj[key])
    },
    optionalCoverageInformation() {
      const configuration = pathOr(
        null,
        ['premium', 'configurations', 0],
        this.premiumResultForConfiguration
      )

      if (!configuration) {
        return {}
      }

      const configurationModel = new premiumResultConfigurationModel(configuration)
      const configurationSelection = pathOr(
        false,
        ['premium', 'configurations', 0],
        this.premiumResultForConfigurationSelection
      )
      const configurationSelectionModel = configurationSelection
        ? new premiumResultConfigurationModel(configurationSelection)
        : null

      const label = this.textContent[configurationModel.textKeys.title]
      const tooltip = this.textContent[configurationModel.textKeys.tooltip]

      const priceBase = configurationSelectionModel
        ? configurationSelectionModel.price
        : configurationModel.price
      const priceCurrent = configurationSelectionModel
        ? configurationSelectionModel.termPremium
        : priceBase
      const optionalCoverages = ['legalAid', 'passengerDamage'].reduce((acc, coverageKey) => {
        const coverage = configurationModel.additionalCoverages[coverageKey]
        if (coverage) {
          acc.push({
            value: coverageKey,
            price: coverage.termPremium,
            label: this.textContent[coverage.textKeys.label],
            tooltip: this.textContent[coverage.textKeys.tooltip],
            selected: false
          })
        }
        return acc
      }, [])

      let accessoryValue = 0

      // accessories only available for
      // PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco and
      // PremiumRequestConfig-Aon-Car-WA-Casco
      if (
        [
          'PremiumRequestConfig-Aon-Car-WA-Beperkt-Casco',
          'PremiumRequestConfig-Aon-Car-WA-Casco'
        ].includes(configurationModel.configurationId)
      ) {
        accessoryValue = Math.max(1250, this.formData.accessories)
        optionalCoverages.push({
          value: 'accessories',
          price: '',
          label: this.textContent.accessories,
          tooltip: this.textContent.accessoriesTooltip,
          options: this.amountAccessories,
          selected: true
        })
      }

      return {
        label,
        tooltip,
        priceBase,
        priceCurrent,
        optionalCoverages,
        accessoryValue
      }
    },
    premiumRequestFields() {
      return (
        this.requestFieldsResults &&
        this.requestFieldsResults.reduce((acc, elem) => {
          acc[elem.name] = elem.values
          return acc
        }, {})
      )
    },
    amountAccessories() {
      return pathOr([], ['AmountAccessories'], this.premiumRequestFields).reduce((acc, field) => {
        const value = parseInt(field.key, 10)
        const label = field.value
        return value > 0 ? [...acc, { value, label }] : acc
      }, [])
    }
  },
  watch: {
    premiumResults(newVal) {
      this.$set(this, 'premiumResultForConfiguration', {})
      if (newVal.premium) {
        newVal.premium.configurations.forEach((configuration) =>
          this.fetchProductInfo(`${configuration.item.premiumRequestProductCode}`)
        )
      }
    }
  },
  created() {
    this.getPremiumRequestFields()
  },
  mounted() {
    if (!this.licensePlate) {
      return
    }
    this.formData.licenseplate = this.licensePlate
    this.licenseplateLookup()
  },
  methods: {
    checkAcceptance() {
      return this.gql
        .query(gql.queries.checkAcceptance, checkAcceptanceParams(this.formData))
        .then(({ data }) => data)
        .catch(logError)
    },
    getPremiums() {
      return this.gql
        .query(gql.queries.premiumCalculation, premiumCalculationParams(this.formData, true))
        .then(({ data }) => {
          this.$set(this, 'premiumResults', data)
        })
        .catch(logError)
    },
    getPremiumsForConfiguration() {
      const basePremiumForCoverage = this.gql
        .query(
          gql.queries.premiumCalculationForConfiguration,
          premiumCalculationParamsForConfiguration(this.formData, true)
        )
        .then(({ data }) => {
          this.$set(this, 'premiumResultForConfiguration', data)
        })
        .catch(logError)

      return Promise.all([basePremiumForCoverage, this.getPremiumsForConfigurationSelection()])
    },
    getPremiumsForConfigurationSelection() {
      return this.gql
        .query(
          gql.queries.premiumCalculationForConfiguration,
          premiumCalculationParamsForConfiguration(this.formData),
          { fetchPolicy: 'network-only' }
        )
        .then(({ data }) => {
          this.$set(this, 'premiumResultForConfigurationSelection', data)
        })
        .catch(logError)
    },
    async getPremiumRequestFields() {
      try {
        const res = await this.gql.query(gql.queries.assignPremiumRequestFields, {
          inputModel: {
            code: 'Car-request-AonDirect'
          }
        })
        this.requestFieldsResults = res.data.assignPremiumRequestFields
      } catch (e) {
        logError(e)
      }
    },
    licenseplateLookup() {
      this.resetFunnelData()
      this.$refs.funnel.$refs.form.isSubmitting = true
      this.isAcceptedLicensePlate = true
      this.declinedLicenseMessage = undefined
      this.$nextTick(() => {
        this.gql
          .query(gql.queries.vehicleDataFromLicensePlate, {
            inputModel: {
              licensePlate: this.formData.licenseplate
            }
          })
          .then(({ data }) => {
            this.$refs.funnel.$refs.form.isSubmitting = false
            this.$set(this, 'vehicleInformation', data.vehicleDataFromLicensePlate)
          })
          .catch(() => {
            this.$refs.funnel.$refs.form.isSubmitting = false
            this.isAcceptedLicensePlate = false
            this.declinedLicenseMessage = this.phrase('funnel-error-licenseplate-not-supported')
          })
      })
    },
    checkSignCodeAndLicensePlate() {
      return this.gql
        .query(gql.queries.validateLicensePlateAndSignCode, {
          inputModel: {
            licensePlate: this.formData.licenseplate,
            signCode: this.formData.signCode
          }
        })
        .then((res) => res.data.validateLicensePlateAndSignCode.result)
    },
    formSubmit() {
      return this.gql
        .mutate(gql.mutations.writePolicy, writePolicyParams(this.formData))
        .then(({ data }) => {
          this.submissionResults = pathOr({}, ['write', 'policyResults', '0', 'result'], data)
          this.handlePostSubmit()
        })
        .catch(logError)
    },
    handlePostSubmit() {
      if (isPolicyResultSuccess(this.submissionResults)) {
        registerPurchase(
          'Car',
          this.optionalCoverageInformation.priceCurrent * 12,
          this.transaction
        )
      }
    },
    handlePageView(page) {
      registerCheckout(
        'Car',
        page,
        pathOr(0, ['priceCurrent', 'optionalCoverageInformation'], this) * 12,
        this.transaction
      )
    },
    fetchProductInfo(code) {
      if (this.productInfoResults[code] === undefined) {
        this.gql
          .query(gql.queries.premiumRequestProduct, { inputModel: { code } })
          .then(({ data }) => {
            this.$nextTick(() => {
              this.$set(this, 'productInfoResults', {
                ...this.productInfoResults,
                [code]: data
              })
            })
          })
          .catch(logError)
      }
    },
    handleCoverageChange() {
      const premiumCode = pathOr(
        '',
        ['productCode'],
        this.premiumInformation.find(
          (premium) => premium.configurationId === this.formData.coverage
        )
      )

      this.fetchFinalQuestions(premiumCode)
    },
    resetFunnelData() {
      this.premiumResults = {}
      this.vehicleInformation = undefined
    }
  }
}
</script>
