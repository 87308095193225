<template>
  <div :class="bemClass">
    <header class="advice__header">
      <div class="advice__premium">
        <div class="advice__img"></div>
        <p class="advice__price">
          <slot name="advice-price">{{ premium | CycleCurrency({ locale }) }}</slot>
          <span> p/m</span>
        </p>
        <p class="advice__description">
          <slot name="advice-description">
            <template v-if="subtext">
              {{ subtext.value }}
              <CycleLink :href="subtext.link.value.href" :taget="subtext.link.value.target">{{
                subtext.link.value.text
              }}</CycleLink>
            </template>
          </slot>
        </p>
      </div>
    </header>

    <div class="advice__body">
      <slot name="advice-usps">
        <CycleList v-if="usps" modifiers="usp">
          <CycleListItem v-for="item in usps" :key="item.id">
            {{ item.text }}
          </CycleListItem>
        </CycleList>
      </slot>
      <div class="btn__container" v-if="hasPremium">
        <CycleButton
          modifiers="cta"
          @click="(e) => $emit('chosen', e)"
          v-testable="'adviceBlockButton'"
        >
          <slot name="advice-button">{{ link.text }}</slot>
        </CycleButton>
      </div>
    </div>
  </div>
</template>

<script>
import { CycleList, CycleListItem } from '../cycle-list'
import { CycleCollapser } from '../cycle-collapser'
import { CycleBaseComponent } from '../base'
import CycleImage from '../cycle-image/CycleImage'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import { CycleLink } from '../cycle-link'
import CycleButton from '../elements/CycleButton/CycleButton'

import { CycleCurrency } from '../../filters/cycle-currency/cycle-currency'
import { intlMixin } from '../../mixins/intl'

export default {
  name: 'CycleAdviceBlock',
  filters: {
    CycleCurrency
  },
  components: {
    CycleCollapser,
    CycleList,
    CycleListItem,
    CycleImage,
    CycleHeading,
    CycleLink,
    CycleButton
  },
  extends: CycleBaseComponent,
  mixins: [intlMixin],
  props: {
    usps: {
      type: Array,
      required: false,
      default: () => []
    },
    image: {
      type: Object,
      required: false
    },
    details: {
      type: Array,
      required: false,
      default: () => []
    },
    warnings: {
      type: String,
      required: false,
      default: ''
    },
    premium: {
      type: Number,
      required: false
    },
    link: {
      type: Object,
      required: false,
      default: () => ({})
    },
    subtext: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      open: false,
      bem: {
        allowedModifiers: ['promoted'],
        block: 'advice'
      }
    }
  },
  computed: {
    openTitle() {
      return !this.open ? 'Toon details' : 'Sluit details'
    },
    hasPremium() {
      return !!this.premium
    }
  },
  methods: {
    onParentCollapserChange(data) {
      this.open = data
    }
  }
}
</script>
