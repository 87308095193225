<template>
  <ECommerceTracker
    :funnel-name="funnelName"
    :year-premium="premium ? premium.totalPremium : undefined"
    :transaction="transaction"
  >
    <template #default="{registerCheckout, registerPurchase}">
      <FormContainer
        store-namespace="funnelWedding"
        @page-view="registerCheckout"
        :on-submit="handleSubmit"
        @submit-success="registerPurchase"
        v-if="ready"
      >
        <template #header="{state}">
          <CycleBusstop :steps="steps" :current-step="state.index" @navigation="state.show" />
          <slot name="collectivity-picker" v-if="state.index === 0" />
        </template>

        <WeddingStepSetup @submit="handleSetupStep" />

        <WeddingStepCoverages
          :selected-coverages="selectedCoverages"
          :premium="premium"
          @coverage-update="updateCoverages"
        />

        <WeddingStepPersonalData
          :address-value="address"
          :contact-details-value="contactDetails"
          :person-value="person"
          :is-verified-email-address="isVerifiedEmailAddress"
          @submit="commit(SET_PERSONAL_DATA, $event)"
        >
          <template #postalcode>
            <AddressLookupContainer :value="address" @input="handleAddressLookup" />
          </template>
        </WeddingStepPersonalData>

        <WeddingStepPayment
          :premium="premium"
          :value="paymentDetails"
          @submit="commit(SET_PAYMENT_DETAILS, $event)"
        />
        <StepFinalQuestions :value="questions" @submit="commit(SET_FUNNEL_QUESTIONS, $event)">
          <FunnelQuestionsContainer
            :product-code="productCode"
            v-model="questions"
            :render-type="questionRenderType.Final"
          />
          <ServiceInformation :service-info="textContent.serviceInfo" />
        </StepFinalQuestions>

        <WeddingStepOverview :overview-data="overviewData">
          <FunnelQuestionsContainer
            :product-code="productCode"
            v-model="questions"
            :render-type="questionRenderType.Agreement"
          />
        </WeddingStepOverview>

        <template #confirmation>
          <CycleFunnelConfirmation :result="submitResult" />
        </template>
      </FormContainer>
    </template>
  </ECommerceTracker>
</template>

<script>
import { CycleBusstop } from '@/components/cycle-busstop'
import { CycleFunnelConfirmation } from '@/components/functional/forms'
import {
  ECommerceTracker,
  FormContainer,
  ServiceInformation,
  StepFinalQuestions
} from '@/components/organisms'
import { funnelMixinFactory } from '@/mixins/funnel'
import { serviceNames, FunnelService } from '@/services/data'
import { uuid4 } from '@/services/UUID'

import { FunnelQuestionsContainer, QuestionRenderType } from '@/modules/FunnelQuestions'
import { AddressLookupContainer } from '@/modules/AddressLookup'
import { GetContentProvider } from '@/services/FunnelContentProvider'
import { PersonalDataTextSetMap } from '@/constants/textSetLabelMaps'

import { FunnelWeddingService, mapWeddingToInputModel } from '../services'
import { default as storeModule } from '../store'
import {
  SET_PERSONAL_DATA,
  SET_PAYMENT_DETAILS,
  SET_FUNNEL_QUESTIONS
} from '../store/mutation-types'

import WeddingStepSetup from './steps/WeddingStepSetup'
import WeddingStepCoverages from './steps/WeddingStepCoverages'
import WeddingStepPersonalData from './steps/WeddingStepPersonalData'
import WeddingStepPayment from './steps/WeddingStepPayment'
import WeddingStepOverview from './steps/WeddingStepOverview'
import WeddingPremiumService from '../services/wedding-premium-service'
import { FunnelNameWedding } from '../constants'

export default {
  name: 'FunnelWeddingSitecore',
  components: {
    AddressLookupContainer,
    CycleBusstop,
    CycleFunnelConfirmation,
    ECommerceTracker,
    FormContainer,
    FunnelQuestionsContainer,
    ServiceInformation,
    WeddingStepSetup,
    WeddingStepCoverages,
    WeddingStepPayment,
    WeddingStepPersonalData,
    StepFinalQuestions,
    WeddingStepOverview
  },
  mixins: [
    funnelMixinFactory({
      moduleNamespace: 'funnelWedding',
      storeModule,
      stateMapping: ['premium', 'isVerifiedEmailAddress'],
      actionsMapping: ['handleSetupStep', 'submitFunnel', 'handleAddressLookup', 'updateCoverages'],
      gettersMapping: [
        'address',
        'person',
        'contactDetails',
        'overviewData',
        'selectedCoverages',
        'submitResult'
      ],
      mutationsMapping: {
        SET_PERSONAL_DATA,
        SET_PAYMENT_DETAILS,
        SET_FUNNEL_QUESTIONS
      },
      services: [
        {
          serviceName: serviceNames.FunnelWeddingService,
          serviceDefinition: FunnelWeddingService
        },
        {
          serviceName: serviceNames.WeddingPremiumService,
          serviceDefinition: WeddingPremiumService
        },
        {
          serviceName: serviceNames.FunnelService,
          serviceDefinition: FunnelService
        }
      ]
    })
  ],
  props: {
    textContent: {
      type: Object,
      default: () => ({})
    },
    defaults: {
      type: Object,
      default: () => ({})
    },
    coverages: {
      type: Object,
      default: () => ({
        basicInsurance: '1.35',
        cancelHoneymoon: '0.25',
        accidentsAndLiability: '0.25',
        badWeather: '0.25'
      })
    },
    countries: {
      type: Array,
      default: () => []
    },
    assign: {
      type: Object,
      default: () => ({})
    },
    agreementQuestions: {
      type: Array,
      default: () => []
    },
    finalQuestions: {
      type: Array,
      default: () => []
    },
    validationConstants: {
      type: Object,
      default: () => ({})
    },
    transaction: {
      type: String,
      required: false,
      default: () => uuid4()
    },
    funnelName: {
      type: String,
      required: false,
      default: FunnelNameWedding
    }
  },
  data() {
    return {
      ready: false,
      formData: {
        ...this.defaults
      },
      stepExampleData: {},
      questions: {}
    }
  },
  computed: {
    steps() {
      return [
        this.textContent?.steps?.setup?.title || '',
        this.textContent?.steps?.coverages?.title || '',
        this.textContent?.steps?.personalData?.title || '',
        this.textContent?.steps?.payment?.title || '',
        this.textContent?.steps?.finalQuestions?.title || '',
        this.textContent?.steps?.overview?.title || ''
      ]
    },
    questionRenderType() {
      return QuestionRenderType
    },
    productCode() {
      return this.assign?.premiumRequestProductCode || 'M001-191'
    }
  },
  provide() {
    return {
      formData: this.formData, // legacy support?
      textContent: this.textContent,
      textContentMapper: this.textContentMapper,
      contentProvider: GetContentProvider(this.textContent),
      countries: this.countries,
      validationConstants: this.validationConstants
    }
  },
  mounted() {
    this.ready = true
    this.commit('setFormData', Object.assign({}, this.formData))
  },
  methods: {
    async handleSubmit() {
      await this.submitFunnel(mapWeddingToInputModel)
    },

    textContentMapper(textKey) {
      if (Object.keys(PersonalDataTextSetMap).includes(textKey)) {
        return PersonalDataTextSetMap[textKey]
      }

      return textKey
    }
  }
}
</script>
