//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @todo make consistent with the horizontal card
 */
import CycleContentBlock from '../cycle-content-block/CycleContentBlock'

export default {
  name: 'CycleCard',
  components: { CycleContentBlock },
  props: {
    dashboard: {
      type: Boolean,
      required: false,
      default: false
    },
    imageModifiers: {
      type: Array,
      required: false,
      default: () => []
    },
    tooltip: {
      type: Boolean,
      required: false,
      default: false
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    slotKeys() {
      return Object.keys(this.$slots)
    }
  }
}
