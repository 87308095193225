export const textKeys = [
  'page1title',
  'page2title',
  'page3title',
  'page4title',
  'page5title',
  'page6title',
  'page1NextButton',
  'page2NextButton',
  'page3NextButton',
  'page4NextButton',
  'page5NextButton',
  'page6NextButton',
  'page2PrevButton',
  'page3PrevButton',
  'page4PrevButton',
  'page5PrevButton',
  'page6PrevButton',
  'householdCompositionLabel',
  'householdCompositionTooltip',
  'insuredAmountLabel',
  'insuredAmountTooltip',
  'salutationLabel',
  'salutationTooltip',
  'initialsLabel',
  'initialsTooltip',
  'prepositionLabel',
  'prepositionTooltip',
  'lastnameLabel',
  'lastnameTooltip',
  'birthdateLabel',
  'birthdateTooltip',
  'zipcodeLabel',
  'zipcodePlaceholder',
  'zipcodeTooltip',
  'housenumberLabel',
  'housenumberTooltip',
  'streetLabel',
  'streetTooltip',
  'cityLabel',
  'cityTooltip',
  'phonenumberLabel',
  'phonenumberTooltip',
  'emailaddressLabel',
  'emailaddressTooltip',
  'emailaddressRepeatLabel',
  'emailaddressRepeatTooltip',
  'emailaddressSubtext',
  'mailinglistLabel',
  'mailinglistText',
  'paymenttypeLabel',
  'paymenttypeTooltip',
  'paymenttermLabel',
  'paymenttermTooltip',
  'paymentCollection',
  'ibanLabel',
  'ibanPlaceholder',
  'ibanTooltip',
  'paymenttermMonth',
  'premiumTaxesText',
  'premiumTaxesHeader',
  'premiumTaxesTooltip',
  'premiumInfoText',
  'monthlyLabel',
  'existingCustomerText',
  'startdateText',
  'paymenttypeText',
  'overviewText',
  'contactTitle',
  'startdateTitle',
  'paymenttypeTitle',
  'generalTitle',
  'personalDataHeader',
  'familyTypeMultiple',
  'familyTypeSingle',
  'salutationSir',
  'salutationMadam',
  'loginText',
  'loginButtonText'
]
export const textProps = textKeys.reduce(
  (acc, label) => ({
    ...acc,
    [label]: {
      type: String,
      required: true
    }
  }),
  {}
)
export const textMap = (context) => ({
  liability: {
    steps: {
      setup: {
        headings: {
          startDate: context.startdateTitle
        },
        header: context.page1title,
        labels: {
          startDate: context.startdateTitle || ' ',
          householdComposition: context.householdCompositionLabel,
          familyTypes: {
            8: context.familyTypeSingle,
            9: context.familyTypeMultiple
          },
          insuredAmount: context.insuredAmountLabel,
          next: context.page1NextButton
        },
        tooltips: {
          householdComposition: context.householdCompositionTooltip,
          insuredAmount: context.insuredAmountTooltip
        }
      },
      offers: {
        header: context.page2title,
        labels: {
          premiumTaxes: context.premiumTaxesText,
          productUSP: context.premiumInfoText,
          next: context.page2NextButton,
          prev: context.page2PrevButton
        },
        headings: {
          premiumTaxes: context.premiumTaxesHeader
        },
        tooltips: {
          premiumTaxes: context.premiumTaxesTooltip
        }
      },
      personalData: {
        header: context.page3title,
        loginContent: context.loginText,
        labels: {
          mailingList: {
            before: context.mailinglistLabel,
            after: context.mailinglistText
          },
          next: context.page3NextButton,
          prev: context.page3PrevButton,
          loginButton: context.loginButtonText
        }
      },
      payment: {
        header: context.page4title,
        content: {
          startDate: context.startdateText
        },
        labels: {
          startDate: context.startdateTitle || ' ',
          next: context.page4NextButton,
          prev: context.page4PrevButton
        },
        headings: {
          startDate: context.startdateTitle
        }
      },
      finalQuestions: {
        header: context.page5title,
        labels: {
          next: context.page5NextButton,
          prev: context.page5PrevButton
        }
      },
      overview: {
        header: context.page6title,
        content: {
          intro: context.overviewText,
          familyType: {
            8: context.familyTypeSingle,
            9: context.familyTypeMultiple
          },
          salutation: {
            m: context.salutationSir,
            v: context.salutationMadam
          },
          paymentTerm: {
            month: context.monthlyLabel
          },
          mailingList: {
            no: 'Nee',
            yes: 'Ja'
          },
          paymentType: context.paymentCollection
        },
        labels: {
          familyType: context.householdCompositionLabel,
          insuredAmount: context.insuredAmountLabel,
          salutation: context.salutationLabel,
          initials: context.initialsLabel,
          lastName: context.lastnameLabel,
          tussenvoegsel: context.prepositionLabel,
          dateOfBirth: context.birthdateLabel,
          emailAddress: context.emailaddressLabel,
          mailingList: context.mailinglistLabel,
          phoneNumber: context.phonenumberLabel,
          startDate: context.startdateTitle,
          iban: context.ibanLabel,
          paymentTerm: context.paymenttermLabel,
          paymentType: context.paymenttypeLabel,
          paymentTermMonth: context.paymenttermMonth,
          next: context.page6NextButton,
          prev: context.page6PrevButton,
          zipcode: context.zipcodeLabel,
          houseNumber: context.housenumberLabel,
          street: context.streetLabel,
          city: context.cityLabel
        },
        headings: {
          generalHeading: context.generalTitle,
          personalDetailHeading: context.page3title,
          paymenttypeHeading: context.paymenttypeTitle
        }
      }
    }
  },
  contactDetails: {
    header: context.contactTitle,
    headings: {
      phoneNumber: context.phonenumberLabel,
      emailAddress: context.emailaddressLabel,
      emailAddressVerify: context.emailaddressRepeatLabel
    },
    labels: {
      phoneNumber: context.phonenumberLabel,
      emailAddress: context.emailaddressLabel,
      emailAddressVerify: context.emailaddressRepeatLabel
    },
    tooltips: {
      phoneNumber: context.phonenumberTooltip,
      emailAddress: context.emailaddressTooltip,
      emailAddressVerify: context.emailaddressRepeatTooltip
    }
  },
  personalData: {
    header: context.personalDataHeader,
    labels: {
      salutation: context.salutationLabel,
      salutationOption: {
        M: context.salutationSir,
        V: context.salutationMadam
      },
      initials: context.initialsLabel,
      preposition: context.prepositionLabel,
      lastName: context.lastnameLabel,
      dateOfBirth: context.birthdateLabel
    },
    tooltips: {
      salutation: context.salutationTooltip,
      initials: context.initialsTooltip,
      preposition: context.prepositionTooltip,
      lastName: context.lastnameTooltip,
      dateOfBirth: context.birthdateTooltip
    }
  },
  paymentDetails: {
    header: context.paymenttypeTitle,
    content: {
      paymentType: context.paymenttypeText,
      existingCustomer: context.existingCustomerText,
      premiumInfo: context.premiumInfoText
    },
    labels: {
      iban: context.ibanLabel,
      paymentTermMonth: context.paymenttermMonth
    },
    headings: {
      premiumTaxes: context.premiumTaxesHeader
    },
    tooltips: {
      iban: context.ibanTooltip,
      premiumTaxes: context.premiumTaxesTooltip
    }
  }
})
