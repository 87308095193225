/**
 * Match licenseplate and return the pattern index
 * @param {*} licenseplate
 * @returns
 */
const getSidecode = (licenseplate) => {
  const sidecodes = []
  sidecodes[0] = /^[a-zA-Z]{2}[\d]{2}[\d]{2}$/ // 1 XX-99-99
  sidecodes[1] = /^[\d]{2}[\d]{2}[a-zA-Z]{2}$/ // 2 99-99-XX
  sidecodes[2] = /^[\d]{2}[a-zA-Z]{2}[\d]{2}$/ // 3 99-XX-99
  sidecodes[3] = /^[a-zA-Z]{2}[\d]{2}[a-zA-Z]{2}$/ // 4 XX-99-XX
  sidecodes[4] = /^[a-zA-Z]{2}[a-zA-Z]{2}[\d]{2}$/ // 5 XX-XX-99
  sidecodes[5] = /^[\d]{2}[a-zA-Z]{2}[a-zA-Z]{2}$/ // 6 99-XX-XX
  sidecodes[6] = /^[\d]{2}[a-zA-Z]{3}[\d]{1}$/ // 7 99-XXX-9
  sidecodes[7] = /^[\d]{1}[a-zA-Z]{3}[\d]{2}$/ // 8 9-XXX-99
  sidecodes[8] = /^[a-zA-Z]{2}[\d]{3}[a-zA-Z]{1}$/ // 9 XX-999-X
  sidecodes[9] = /^[a-zA-Z]{1}[\d]{3}[a-zA-Z]{2}$/ // 10 X-999-XX
  sidecodes[10] = /^[a-zA-Z]{3}[\d]{2}[a-zA-Z]{1}$/ // 11 XXX-99-X
  sidecodes[11] = /^[a-zA-Z]{1}[\d]{2}[a-zA-Z]{3}$/ // 12 X-99-XXX
  sidecodes[12] = /^[\d]{1}[a-zA-Z]{2}[\d]{3}$/ // 13 9-XX-999
  sidecodes[13] = /^[\d]{3}[a-zA-Z]{2}[\d]{1}$/ // 14 999-XX-9

  const index = sidecodes.findIndex((item) => licenseplate.match(item))
  return index + 1
}

/**
 * Format licenseplate based on user input
 * @param {*} licenseplateValue
 * @returns
 */
export const licensePlateFormat = (licenseplateValue) => {
  let licenseplate = licenseplateValue.replace(/-/g, '').toUpperCase()

  if (licenseplate.length === 6) {
    const sidecode = getSidecode(licenseplate)
    if (sidecode) {
      if (sidecode <= 6) {
        licenseplate = `${licenseplate.substr(0, 2)}-${licenseplate.substr(
          2,
          2
        )}-${licenseplate.substr(4, 2)}`
      }
      if (sidecode === 7 || sidecode === 9) {
        licenseplate = `${licenseplate.substr(0, 2)}-${licenseplate.substr(
          2,
          3
        )}-${licenseplate.substr(5, 1)}`
      }
      if (sidecode === 8 || sidecode === 10) {
        licenseplate = `${licenseplate.substr(0, 1)}-${licenseplate.substr(
          1,
          3
        )}-${licenseplate.substr(4, 2)}`
      }
      if (sidecode === 11 || sidecode === 14) {
        licenseplate = `${licenseplate.substr(0, 3)}-${licenseplate.substr(
          3,
          2
        )}-${licenseplate.substr(5, 1)}`
      }
      if (sidecode === 12 || sidecode === 13) {
        licenseplate = `${licenseplate.substr(0, 1)}-${licenseplate.substr(
          1,
          2
        )}-${licenseplate.substr(3, 3)}`
      }
    }
  }
  return licenseplate
}

/**
 * Check if licenseplate is valid or not
 * @param {*} licenseplateValue
 * @returns
 */
 export const isLicensePlateValid = (licenseplate) => {
  licenseplate = licenseplate.replace(/-/g, '').toUpperCase()
  const sidecode = getSidecode(licenseplate)
  return sidecode ? true : false
 }

/**
 * Get new cursor position based on deleted position
 * - Licenseplate value is: GZ-108-F then both dash positions in an array like here [2,6]
 * - Get Two dashes position from licenseplate using regex eg.
 * - Compare it with given position where it belong:
 * - Either before dash, In mid of dash and Last dash
 * 
 * @param {*} position
 * @param {*} licenseplateValue
 * @returns
 */
export const newCursorPosition = (position = 0, licensePlateValue = null) => {
  const regex = /-/gi
  const dashes = []
  let result
  while (result = regex.exec(licensePlateValue)) {
    dashes.push(result.index)
  }
  // Return deleted position only if liceseplate only one of the dash(-) is removed because its still valid
  if(isLicensePlateValid(licensePlateValue)) {
    return position
  }
  if (dashes.length > 0) {
    if ( position <= dashes[0] ) {
      return position
    } else if ( position >= dashes[0] && (position <= dashes[1] || !dashes[1]) ) {
      return position - 1
    } else {
      return position - 2
    }
  }
  return position
}