
export default {
  name: 'CycleGtmMessageListenerSitecore',
  props: {
    uaCode: {
      type: String,
      required: true
    },
    trustedDomains: {
      type: Array,
      default() {
        return ['iak.nl']
      }
    }
  },
  mounted() {
    this.setupGaObject()
    window.addEventListener('message', this.gtmListener)
  },
  destroyed() {
    window.removeEventListener('message', this.gtmListener)
  },
  methods: {
    setupGaObject() {
      window.ga =
        window.ga ||
        function () {
          window.ga.q = window.ga.q || [].push(arguments)
        }
      window.ga.l = +new Date()
    },
    gtmListener(event) {
      // make sure messages are from trusted domain.
      if (
        event.data?.gtmData?.clientId &&
        this.isTrustedDomain(this.trustedDomains, event.origin)
      ) {
        window.ga('create', this.uaCode, 'auto', {
          clientId: event.data.gtmData.clientId
        })
      }
    },
    isTrustedDomain(trustedDomains, origin) {
      return trustedDomains.some((current) => {
        return origin.includes(current)
      })
    }
  },
  render() {
    return this.$slots.default
  }
}
