<template>
  <CycleButton :modifiers="buttonModifiers" v-on="$listeners" v-bind="$attrs">
    <template>
      <CycleIcon :icon="icon" /> <slot v-if="label" name="label"> {{ label }} </slot>
    </template>
  </CycleButton>
</template>

<script>
import CycleButton from '../elements/CycleButton/CycleButton'
import CycleIcon from '../elements/CycleIcon/CycleIcon'
import { CycleBaseComponent } from '@/components/base'

export default {
  name: 'IconButtonVue',
  components: { CycleIcon, CycleButton },
  extends: CycleBaseComponent,
  props: {
    buttonModifiers: {
      type: String
    },
    icon: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>
