<template>
  <CycleSimpleForm :has-next-button="false" next-button-text="" :form-submit-action="submit">
    <CycleHtml :html="textContent.description" />
    <CycleFormSet :modifiers="['flush']">
      <CycleInputContainer
        survey
        :label="textContent.optionsTitle || ''"
        :tooltip="textContent.optionsTooltip"
        :render-list="isRenderList"
      >
        <div :class="containerClass" :style="!containerClass ? { display: 'contents' } : {}">
          <component
            v-for="(option, index) in options"
            :is="itemComponent"
            :is-active="activeOption === index"
            :key="index"
            :option="option"
            @update-url="updateUrl"
          />
        </div>
      </CycleInputContainer>
    </CycleFormSet>
  </CycleSimpleForm>
</template>

<script>
import { CycleRadioNavigationItem, CycleTileNavigationItem } from '@/components/molecules'
import { CycleFormSet, CycleInputContainer } from '@/components/functional'
import { CycleHtml } from '@/components/base'
import CycleSimpleForm from '@/components/functional/forms/CycleSimpleForm/CycleSimpleForm'
import { logError } from '@/services/Logging'

export default {
  name: 'CycleSimpleNavigation',
  components: {
    CycleRadioNavigationItem,
    CycleTileNavigationItem,
    CycleSimpleForm,
    CycleHtml,
    CycleFormSet,
    CycleInputContainer
  },
  provide: { textContent: {} },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    isRenderList: {
      type: Boolean
    },
    isTileNavigation: {
      type: Boolean,
      required: false,
      default: false
    },
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return { activeOption: undefined, currentPath: null }
  },
  computed: {
    containerClass() {
      return this.isTileNavigation ? 'tiles' : ''
    },
    itemComponent() {
      return this.isTileNavigation ? `CycleTileNavigationItem` : 'CycleRadioNavigationItem'
    }
  },
  mounted() {
    if (typeof window === 'undefined') {
      return
    }
    this.currentPath = window.location.pathname.replace(/^\/[a-z]{2}-[A-Z]{2}/i, '')
    const currentPathExpression = new RegExp(this.currentPath, 'i')
    const matchedLinks = this.options.filter((opt) => currentPathExpression.test(opt.href))
    if (matchedLinks.length > 1) {
      this.activeOption = undefined
      return
    }
    const firstMatchedLink = matchedLinks[0]
    this.activeOption = this.options.indexOf(firstMatchedLink)
  },
  methods: {
    submit() {
      logError('Form should not submit')
    },
    updateUrl(newOption) {
      this.activeOption = this.options.indexOf(newOption)
      const currentOption = newOption.href
      const currentPathExpression = new RegExp(currentOption, 'i')
      const isActiveOption = currentPathExpression.test(this.currentPath)
      if (!isActiveOption) {
        window.location.assign(currentOption)
      }
    }
  }
}
</script>
