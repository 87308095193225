import { logError } from '@/services/logging'
import { currentYYYYMMDD } from '@/services/DateTime'
import { portfolioInfoQuery, policyDirectLinksQuery } from '@/gql/queries'
import { policyInfoQuery } from '@/store/MyZone/queries/policyInfoQuery'

import { myDocuments as documentsQuery } from '@/components/Modules/MyZone/gql'

export const actions = {
  async fetchPortfolioInfo({ commit, getters }, { dataSourceId, locale, overviewPage }) {
    try {
      const res = await this._vm.$jss.gql.query(portfolioInfoQuery, {
        inputModel: {
          dataSourceId,
          overviewPage
        }
      })

      if (res?.data?.portfolioInfo) {
        const clientIdentifier = getters.clientIdentifier
        commit('SET_PORTFOLIO_INFO', {
          dataSourceId,
          locale,
          clientIdentifier,
          portfolioInfo: res.data.portfolioInfo
        })
      }
    } catch (error) {
      logError(error)
    }
  },
  async fetchPolicyInfo({ commit, getters }, policyNumber) {
    try {
      const policy = getters.getPolicyInfo(policyNumber)
      if (policy) {
        const res = await this._vm.$jss.gql.query(policyInfoQuery(policy.productType), {
          inputModel: {
            sourceSystemCode: policy.sourceSystem.system.code,
            policyNumber: policy.policyUniqueIdentifier ?? policy.sourceSystem.key
          }
        })

        if (res?.data?.policyInfo) {
          commit('SET_POLICY_INFO', {
            policyNumber,
            policyInfo: res?.data?.policyInfo
          })
        }
      }
    } catch (error) {
      logError(error)
    }
  },
  async fetchDocuments({ commit }) {
    try {
      const dateToYear = new Date().getFullYear()
      const dateFromYear = dateToYear - 10

      const res = await this._vm.$jss.gql.query(documentsQuery, {
        inputModel: {
          dateFrom: `${dateFromYear}-01-01`,
          dateTo: currentYYYYMMDD(),
          sortBy: 'DATUM',
          sortOrder: 'DESC',
          numberOfLinesOnPage: '1000',
          pageNumber: '1',
          filters: {
            showPolicies: true,
            showInvoices: false
          }
        }
      })

      if (res?.data?.documents?.documentsList) {
        commit('SET_DOCUMENTS', res?.data?.documents?.documentsList)
      }
    } catch (error) {
      logError(error)
    }
  },
  async fetchDirectLinks({ commit }, inputModel) {
    try {
      const res = await this._vm.$jss.gql.query(
        policyDirectLinksQuery,
        {
          inputModel
        },
        {
          fetchPolicy: 'no-cache'
        }
      )

      if (res?.data?.portfolioInfo) {
        commit('SET_DIRECT_LINKS', res.data.portfolioInfo)
      }
    } catch (error) {
      logError(error)
    }
  }
}
