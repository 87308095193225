<template>
  <div class="hero-panel">
    <div>
      <h2>Zeker &amp; zorgeloos</h2>
      <p>Een zorgverzekering met een plusje van Aon</p>
      <ul class="checks">
        <li>
          Gratis health watch met slaapmeter bij een zorgverzekering via Aon
          <img src="http://cfpa.cmstest.aon.nl/images/actie.png" />
          <CycleButton>Naar deze actie</CycleButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { CycleButton } from '../elements'

export default {
  name: 'CycleHeroPanel',
  components: { CycleButton }
}
</script>
