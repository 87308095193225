<template>
  <GridComponentAdapter :text-content="content">
    <slot name="jss-content" />
  </GridComponentAdapter>
</template>

<script>
import { GridComponentAdapter } from '@/components/organisms'

export default {
  name: 'GridComponentAdapterSitecore',
  components: { GridComponentAdapter },
  props: {
    title: {
      type: String
    },
    introduction: {
      type: String
    }
  },
  computed: {
    content() {
      return {
        ...this.$props
      }
    }
  }
}
</script>
