<template>
  <CycleFormPage :on-submit="handleSubmit">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.coverages.title" />
      </CycleHeading>
    </template>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.coverages.nextButton" />
      </CycleButton>
    </template>

    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="steps.coverages.backButton" />
      </CycleButton>
    </template>

    <CycleFormSet>
      <CycleCheckout
        :amount="totalPremium"
        :amount-description="
          contentProvider.getContentByPath('paymentDetails.totalAmountSingle.label')
        "
        :amount-subtext="
          contentProvider.getContentByPath('paymentDetails.totalAmountSingle.subtext')
        "
      >
        <template #heading>
          <ProvidedText name="steps.coverages.headers.additions" />
        </template>

        <template #checkout-item>
          <CycleCheckoutItem
            :price="premiumCoverage(coverageTypes.basicInsurance)"
            :label="contentProvider.getContentByPath('steps.coverages.baseCoverage.label')"
            :tooltip="
              contentProvider.getContentByPath('steps.coverages.baseCoverage.tooltip', false)
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.baseCoverage.description')
            "
            selected
          />
          <CycleCheckoutItem
            :label="
              contentProvider.getContentByPath('steps.coverages.weddingClothesAndGifts.label')
            "
            :tooltip="
              contentProvider.getContentByPath(
                'steps.coverages.weddingClothesAndGifts.tooltip',
                false
              )
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.weddingClothesAndGifts.description')
            "
            :price="premiumCoverage(coverageTypes.weddingClothesAndGifts)"
            selected
          />
          <CycleCheckoutItem
            v-model="localSelectedCoverages"
            :value="coverageTypes.cancelHoneymoon"
            :label="contentProvider.getContentByPath('steps.coverages.cancellation.label')"
            :tooltip="
              contentProvider.getContentByPath('steps.coverages.cancellation.tooltip', false)
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.cancellation.description')
            "
            :price="premiumCoverage(coverageTypes.cancelHoneymoon)"
            @change="updateSelectedCoverages"
          />
          <CycleCheckoutItem
            v-model="localSelectedCoverages"
            :value="coverageTypes.accidentsAndLiability"
            :label="contentProvider.getContentByPath('steps.coverages.liabilityAndAccidents.label')"
            :tooltip="
              contentProvider.getContentByPath(
                'steps.coverages.liabilityAndAccidents.tooltip',
                false
              )
            "
            :description="
              contentProvider.getContentByPath('steps.coverages.liabilityAndAccidents.description')
            "
            :price="premiumCoverage(coverageTypes.accidentsAndLiability)"
            @change="updateSelectedCoverages"
          />
          <CycleCheckoutItem
            v-model="localSelectedCoverages"
            :value="coverageTypes.badWeather"
            :label="contentProvider.getContentByPath('steps.coverages.badWeather.label')"
            :tooltip="contentProvider.getContentByPath('steps.coverages.badWeather.tooltip', false)"
            :description="
              contentProvider.getContentByPath('steps.coverages.badWeather.description')
            "
            :price="premiumCoverage(coverageTypes.badWeather)"
            @change="updateSelectedCoverages"
          />
        </template>
      </CycleCheckout>
    </CycleFormSet>
  </CycleFormPage>
</template>

<script>
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { dictionaryMixin, intlMixin } from '@/mixins'
import { CycleFormSet } from '@/components/functional'
import { CycleCheckout, CycleCheckoutItem } from '@/components/functional/CycleCheckout'
import { WeddingCoverageTypes, ProductPremium } from '../../models'
import { debounce } from 'lodash'

export default {
  name: 'WeddingStepCoverages',
  inject: ['formData', 'textContent', 'contentProvider'],
  components: {
    CycleFormPage,
    CycleFormSet,
    ProvidedText,
    ProvidedTooltip,
    CycleInputContainer,
    CycleCheckout,
    CycleCheckoutItem
  },
  mixins: [intlMixin, dictionaryMixin],
  props: {
    selectedCoverages: {
      type: Array,
      default: () => []
    },
    premium: {
      type: ProductPremium,
      required: false
    }
  },
  data() {
    return {
      coverageTypes: WeddingCoverageTypes,
      localSelectedCoverages: []
    }
  },
  computed: {
    totalPremium() {
      return this.premium?.totalPremium
    }
  },
  watch: {
    selectedCoverages: {
      immediate: true,
      handler(selectedCoverages) {
        this.localSelectedCoverages = selectedCoverages.map((c) => c.coverageType)
        //this.updateSelectedCoverages()
      }
    }
  },
  methods: {
    updateSelectedCoverages: debounce(function () {
      this.$emit('coverage-update', this.localSelectedCoverages)
    }, 500),

    handleSubmit() {},
    premiumCoverage(coverage) {
      return this.premium?.coverages[coverage]
    }
  }
}
</script>
