<template>
  <ol class="navigation navigation--top" v-if="hasTopNavigationItems">
    <TopNavigationItem
      v-for="item in items"
      :key="item.id"
      :href="getHref(item)"
      :title="getTitle(item)"
      :active="isActive(item)"
    >
    </TopNavigationItem>
  </ol>
</template>

<script>
import TopNavigationItem from './TopNavigationItem'
export default {
  name: 'TopNavigation',
  components: { TopNavigationItem },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    hasTopNavigationItems() {
      return this.items?.length > 0
    }
  },
  methods: {
    getHref(item) {
      return item?.fields?.Link?.value?.href
    },
    getTitle(item) {
      return item?.fields?.Title?.value
    },
    isActive(item) {
      return item?.fields?.CurrentItem
    }
  }
}
</script>
