<template>
  <ul class="receipt">
    <span v-if="groupTitle" class="receipt__label receipt__label--heading">
      {{ groupTitle }}:
    </span>
    <li class="receipt__item">
      <span v-if="labelHeading" class="receipt__label receipt__label--heading">
        {{ labelHeading }}:
      </span>
      <span v-if="valueHeading" class="receipt__value receipt__value--heading">
        {{ valueHeading }}:
      </span>
    </li>
    <slot name="receipt-group" />
  </ul>
</template>

<script>
export default {
  name: 'CycleReceiptGroup',
  props: {
    groupTitle: {
      type: String,
      required: false,
      default: ''
    },
    labelHeading: {
      type: String,
      required: false,
      default: ''
    },
    valueHeading: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
