//
//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
export default {
  name: 'CycleSelect',
  extends: BaseInput,
  provide() {
    return {
      modelValue: this.value
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      localValue: this.value
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  },
  methods: {
    updateValue() {
      this.$emit('change', this.localValue)
    }
  }
}
