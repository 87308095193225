import { pathOr } from 'ramda'
import { PolicyStatus } from '@/constants'

/**
 * Reduces a portfolio object to a { number => policy } object
 *
 * @param {Object} portfolioInfo
 * @param {String} [key] - The key on which to identify policies by
 */
export const reducePortfolio = (portfolioInfo, key = 'numberEncrypted') => {
  if (portfolioInfo.portfolioInfo !== undefined) {
    return reducePortfolio(portfolioInfo.portfolioInfo)
  }
  const packages = pathOr([], ['packages'], portfolioInfo).reduce(
    (accumulator, currentValue) => [...accumulator, ...pathOr([], ['policies'], currentValue)],
    []
  )
  const policies = pathOr([], ['policies'], portfolioInfo).filter(
    ({ product }) => product !== undefined
  )

  return [...policies, ...packages].reduce(
    (acc, policy) => ({
      ...acc,
      [policy[key]]: policy
    }),
    {}
  )
}

/**
 * Determines whether a policy is ongoing
 * @param {PolicyStatus} status
 * @return {Boolean}
 */
export const isPolicyOngoing = ({ code }) => parseInt(code) === PolicyStatus.Ongoing

/**
 * Determines whether a policy is cancelled
 * @param {PolicyStatus} status
 * @return {Boolean}
 */
export const isPolicyCancelled = ({ code }) => parseInt(code) === PolicyStatus.Cancelled

/**
 * Determines whether a policy is in progress
 * @param {PolicyStatus} status
 * @return {Boolean}
 */
export const isPolicyInProgress = ({ code }) =>
  [
    PolicyStatus.NewInProgress,
    PolicyStatus.NewFuturePolicy,
    PolicyStatus.OngoingInProgress,
    PolicyStatus.OngoingFutureChange
  ].includes(parseInt(code))

/**
 * Determines whether a policy is pending
 * @param {PolicyStatus} status
 * @return {Boolean}
 */
export const isPolicySuspended = ({ code }) => parseInt(code) === PolicyStatus.Suspended

/**
 * Determines whether a policy is expired or rejected
 * @param {PolicyStatus} status
 * @return {Boolean}
 */
export const isPolicyExpired = ({ code }) =>
  [
    PolicyStatus.Cancelled,
    PolicyStatus.NonContributory,
    PolicyStatus.Proposal,
    PolicyStatus.NewRejected
  ].includes(parseInt(code))

export const isPolicyRejected = isPolicyExpired
