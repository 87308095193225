<template>
  <CycleFormPage
    :prev-button-text="textContent.liability.steps.overview.labels.prev"
    :next-button-text="textContent.liability.steps.overview.labels.next"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="liability.steps.overview.header" />
      </CycleHeading>
    </template>

    <ProvidedHtml name="liability.steps.overview.content.intro" />

    <CycleFormSet>
      <dl>
        <dt><ProvidedText name="liability.steps.overview.labels.startDate" /></dt>
        <dd v-if="startDate">
          {{ startDate | CycleDate({ locale }) }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.familyType" /></dt>
        <dd>
          <ProvidedText v-if="isValidFamilyType" :name="nameForFamilyType" />
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.insuredAmount" /></dt>
        <dd>
          {{ insuredAmount | CycleCurrency({ locale }) }}
        </dd>
        <dt><ProvidedText name="liability.steps.overview.labels.paymentTerm" /></dt>
        <dd>
          <span v-if="premium">
            {{ premium.premium | CycleCurrency({ locale }) }}
          </span>
        </dd>
      </dl>
    </CycleFormSet>

    <slot />
  </CycleFormPage>
</template>

<script>
import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { FamilyType } from '../../constants'

import { ProductPremium } from '../../models'

export default {
  name: 'LiabilityStepOverviewChange',
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  inject: ['formData', 'textContent'],
  mixins: [intlMixin],
  props: {
    familyType: {
      required: true,
      type: String
    },
    insuredAmount: {
      required: true,
      type: String
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    startDate: {
      required: false,
      type: [String, Date]
    }
  },
  computed: {
    nameForFamilyType() {
      return `liability.steps.overview.content.familyType.${this.familyType}`
    },
    isValidFamilyType() {
      return this.familyType === FamilyType.Single || this.familyType === FamilyType.Multiple
    }
  }
}
</script>
