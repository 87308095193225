<template>
  <CycleFormSet>
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="liability.steps.overview.header" />
      </CycleHeading>
    </template>

    <ProvidedHtml name="liability.steps.overview.content.intro" />

    <!-- personal information -->
    <dl>
      <template>
        <dd v-if="person.lastName && person.initials">
          {{ fullName }}
        </dd>
        <dd v-if="address.street && address.houseNumber">
          {{ fullAddress }}
        </dd>
        <dd v-if="address.postalCode && address.city">
          {{ fullCityName }}
        </dd>
      </template>

      <!-- date of birth -->
      <dt><ProvidedText name="liability.steps.overview.labels.dateOfBirth" /></dt>
      <dd>
        {{ person.dateOfBirth | CycleDate({ locale }) }}
      </dd>

      <!-- email address -->
      <dt><ProvidedText name="liability.steps.overview.labels.emailAddress" /></dt>
      <dd>
        {{ contactDetails.emailAddress }}
      </dd>

      <!-- phone number -->
      <dt><ProvidedText name="liability.steps.overview.labels.phoneNumber" /></dt>
      <dd>
        {{ contactDetails.phoneNumber }}
      </dd>
    </dl>
  </CycleFormSet>
</template>

<script>
import { FullHouseNumber } from '@/components/atoms'
import { CycleHeading } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import ProvidedHtml from '../Content/ProvidedHtml'
import ProvidedText from '../Content/ProvidedText'
import { Gender } from '@/constants'
import { CycleCurrency, CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { Person, ContactDetails, Address } from '@/models'

export default {
  name: 'DetailDataOverview',
  components: {
    FullHouseNumber,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    contactDetails: {
      required: true,
      type: ContactDetails
    },
    person: {
      required: true,
      type: Person
    },
    address: {
      required: true,
      type: Address
    },
    textContent: {
      type: Object,
      required: false
    }
  },
  computed: {
    setGender() {
      if (this.person.gender === Gender.Male) {
        return this.textContent.personalData.labels.salutationOption.M
      } else {
        return this.textContent.personalData.labels.salutationOption.V
      }
    },
    fullName() {
      return this.setGender + ' ' + this.person.initials + ' ' + this.person.lastName
    },
    fullAddress() {
      return (
        this.address.street +
        ' ' +
        this.address.houseNumber +
        ' ' +
        this.address.houseNumberAddition
      )
    },
    fullCityName() {
      return this.address.postalCode + ' ' + this.address.city
    }
  }
}
</script>
