<template>
  <ContentToggle
    is-plain
    :open-action-text="textContent.showFilterButtonText"
    :close-action-text="textContent.hideFilterButtonText"
  >
    <CycleForm v-on="$listeners" is-override-submit ref="form">
      <CycleFormPage
        inline-spinner
        :has-prev-button="false"
        has-next-button
        :next-button-text="textContent.showResultsLabel"
        :on-submit="filterDocuments"
      >
        <CycleFormSet :heading="textContent.filterTitle">
          <CContainer :label="textContent.filterSelectionLabel">
            <CSelect v-model="formData.documentFilter" required>
              <COption
                slot="option"
                :value="[]"
                selected
                hidden
                v-text="phrase('generic-select-your-choice')"
              />
              <COption
                slot="option"
                v-for="(item, index) in filterOptions"
                :key="`${index}-${item.codes[0].code}`"
                :value="item.codes"
                v-text="item.description"
              />
            </CSelect>
          </CContainer>
          <CContainer :label="textContent.dateFromLabel">
            <CDate required v-model="formData.dateFrom" :min="minDate" :max="today" />
            <template #error-max>
              {{ phrase('error-messages-documents-fetch-max-date-from') }}
            </template>
            <template #error-min>
              {{ phrase('error-messages-documents-fetch-min-date-from') }}
            </template>
          </CContainer>
          <CContainer :label="textContent.dateToLabel">
            <CDate required v-model="formData.dateTo" :min="() => minDateTo" :max="today" />
            <template #error-max>
              {{ phrase('error-messages-documents-fetch-max-date-to') }}
            </template>
            <template #error-min>
              {{ phrase('error-messages-documents-fetch-min-date-to') }}
            </template>
          </CContainer>
        </CycleFormSet>
      </CycleFormPage>
    </CycleForm>
  </ContentToggle>
</template>

<script>
import { CycleDateInput, CycleSelect, CycleOption, CycleButton } from '@/components/elements'
import {
  CycleFormPage,
  CycleFormSet,
  CycleInputContainer,
  CycleForm
} from '@/components/functional/forms'
import { dictionaryMixin } from '@/mixins'
import { currentYYYYMMDD } from '@/services/DateTime'
import ContentToggle from './ContentToggle'
import { parseISO } from 'date-fns'
const isValidDateValue = (dateString) => !isNaN(parseISO(dateString).getTime())

const currentYear = new Date().getFullYear()
const defaultFilterFromYear = currentYear - 3

export default {
  name: 'DocumentsFilter',
  components: {
    CycleOption,
    ContentToggle,
    CContainer: CycleInputContainer,
    CDate: CycleDateInput,
    CSelect: CycleSelect,
    COption: CycleOption,
    CycleButton,
    CycleFormSet,
    CycleFormPage,
    CycleForm
  },
  mixins: [dictionaryMixin],
  provide() {
    return {
      formData: this.formData
    }
  },
  props: {
    /**
     * options to filter documents on
     */
    documentCodesFilter: {
      type: Array,
      required: true,
      default: () => []
    },
    textContent: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        dateFrom: `${defaultFilterFromYear}-01-01`,
        dateTo: currentYYYYMMDD(),
        documentFilter: []
      }
    }
  },
  computed: {
    today() {
      return currentYYYYMMDD()
    },
    minDate() {
      const minFilterYear = currentYear - 7
      return `${minFilterYear}-01-01`
    },
    minDateTo() {
      if (!isValidDateValue(this.formData.dateFrom) && !isValidDateValue(this.formData.dateTo)) {
        return this.minDate
      }

      return this.formData.dateFrom
    },
    filterOptions() {
      if (this.documentCodesFilter?.length === 0) {
        return []
      }

      return this.documentCodesFilter.filter((option) => option.codes?.length !== 0)
    }
  },
  methods: {
    getFormValues() {
      let filterCodes = []
      if (this.formData?.documentFilter?.length !== 0) {
        filterCodes = this.formData.documentFilter.map(({ code }) => code)
      }
      return {
        documentFilter: filterCodes,
        dateFrom: this.formData?.dateFrom ?? `${defaultFilterFromYear}-01-01`,
        dateTo: this.formData?.dateTo ?? this.today
      }
    },
    filterDocuments() {
      this.$emit('filter-change', this.getFormValues())
    }
  }
}
</script>
