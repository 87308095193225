<template>
  <span class="radio-bar__field">
    <input
      type="radio"
      class="radio-bar__input"
      v-bind="Object.assign({}, attributes, $attrs)"
      v-on="Object.assign({}, listeners, $listeners, { change: updateValue })"
      :value="value"
      :checked="checked"
    />
    <label
      v-bind="{ for: $attrs.id }"
      class="radio-bar__label"
      tabindex="0"
      @keydown.space="updateValue"
      @keydown.prevent.enter="updateValue"
    >
      <slot v-if="$slots.default" />
    </label>
  </span>
</template>

<script>
import CycleRadioInput from '../CycleRadioInput/CycleRadioInput'

export default {
  name: 'CycleRadioInput',
  extends: CycleRadioInput,
  methods: {
    updateValue() {
      this.$emit('change', this.value)
    }
  }
}
</script>
