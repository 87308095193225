//
//
//
//
//
//
//

import { CycleCompareCoverage } from '../../functional/CycleCompareCoverage'

/**
 * Sitecore interface for CycleCompareCoverage
 */
export default {
  name: 'CycleCompareCoverageSitecore',
  components: {
    CycleCompareCoverage
  }
}
