import { SET_QUESTIONS } from './mutation-types'

export const mutations = {
  [SET_QUESTIONS]: (state, { key, questions }) => {
    state.questions = {
      ...state.questions,
      ...questions.reduce(
        (acc, question) => ({
          ...acc,
          [question.id]: question
        }),
        {}
      )
    }
    state.lists[key] = questions.map((question) => question.id)
  }
}

export default mutations
