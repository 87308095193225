import Defaults from '@/constants/defaults'

export const intlMixin = {
  computed: {
    locale() {
      return (
        this.$store?.getters['siteSettings/currentLanguage'] ||
        this.$jss?.getters?.currentLanguage ||
        Defaults.defaultLocale
      )
    }
  }
}
