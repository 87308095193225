<template>
  <div :class="comparisonItemClassList">
    <CycleHeading v-if="title || tooltip" :weight="3">
      <CycleTag v-if="isPopular">{{ mostPopularText }} <span v-if="isCheckMarkEnabled" class="icon--thumbs-up icon" /></CycleTag>
      <span v-if="isRecommendedTextVisible" class="comparison__recommended">
        {{ recommendedText }}
      </span>
      {{ title }}
      <ModalTooltip v-if="hasTooltip">
        <template #tooltip-heading>
          {{ title }}
        </template>
        <template #tooltip-content>
          <CycleHtml :html="tooltip" />
        </template>
      </ModalTooltip>
    </CycleHeading>
    <div class="comparison__list">
      <h5 v-if="price != null && pricePosition === 'top'" class="comparison__price">
        <template v-if="hasTransitionParams">
          <ValueTransition :value="price" :transition-params="transitionParams" >
            <template #default="{transitionValue}">
              <CycleHtml tag="span" :html="transitionValue | CycleCurrency({ locale, split: true })" />
              <span class="comparison__payment-term">{{ paymentTerm }}</span>
            </template>
          </ValueTransition>
        </template>
        <template v-else>
          <CycleHtml tag="span" :html="price | CycleCurrency({ locale, split: true })" />
          <span class="comparison__payment-term">{{ paymentTerm }}</span>
        </template>
      </h5>
      <div v-if="alternativeOffers" class="comparison__alternatives">
        <ul>
          <li v-for="alternative in alternativeOffers" :key="alternative.id">
            <CycleLink
              :target="extractOffer(alternative).target"
              :href="extractOffer(alternative).href"
              :title="extractOffer(alternative).title"
            >
              {{ alternative.offername }}: {{ alternative.price | CycleCurrency({ locale }) }}
            </CycleLink>
          </li>
        </ul>
      </div>
      <slot />
      <div
        v-if="showBodyText"
        class="comparison__body"
      >
        {{ bodytext }}
      </div>
      <CycleHtml v-if="hasDescription" :html="description" />
      <div :class="singleCoverblockSectionClassName">
        <ContentToggle
          v-if="isListInToggle"
          :open-action-text="highlightedCoverageToggleOpenText"
          :close-action-text="highlightedCoverageToggleCloseText"
          is-plain
          :is-default-open="isHighlighted"
          :button-background="isBestOfferTool ? 'none' : undefined"
        >
          <CycleList modifiers="usp-no-usp">
            <CycleListItem v-for="(item, index) in list" :key="index">
              <CycleHtml :class="getUspIcon(item)" :html="item.text" />
            </CycleListItem>
          </CycleList>
        </ContentToggle>

        <CycleList v-else-if="hasList" modifiers="usp-no-usp">
          <CycleListItem v-for="(item, index) in list" :key="index">
            <template v-if="hasUspIcon(item)">
              <CycleHtml :class="getUspIcon(item)" :html="item.text" />
            </template>
          </CycleListItem>
        </CycleList>
        <slot name="input" />
        <h5 v-if="price != null && pricePosition === 'bottom'" class="comparison__price">
          {{ price | CycleCurrency({ locale }) }}
        </h5>
        <div v-if="(modelValue || value) && !isBestOfferTool" class="comparison__button">
          <CycleRadioInput
            v-if="!disabled && !selectionDisabled"
            v-model="modelValue"
            :value="value"
            @change="updateValue"
          >
            {{ linkText }}
          </CycleRadioInput>
        </div>
        <div v-else-if="link" :data-test="title" class="comparison__button">
          <CycleButtonLink v-if="hasLink" :disabled="disabled" modifiers="cta" :href="linkUrl">{{
            linkText
          }}</CycleButtonLink>
          <div v-else-if="isSelected">{{ selectedText }} <span class="icon--thumbs-up icon" /></div>
          <div v-else-if="disabled && isBestOfferTool" class="comparison__button--disabled-text">
            <ModalTooltip v-if="showBodyTextTooltip" :linkText="disabledText">
              <template #tooltip-heading>
                {{ title }}
              </template>
              <template #tooltip-content>
                <CycleHtml :html="bodytext" />
              </template>
            </ModalTooltip>
          </div>
          <CycleButton
            v-else
            :disabled="disabled"
            @click="emitter"
            :modifiers="!isBestOfferTool ? 'cta' : 'primary'"
            v-testable="testableName"
            type="button"
          >
            {{ linkText }}
          </CycleButton>
        </div>
        <h5 v-if="(price != null || hasTransitionParams) && hasSingleCoverBlock" class="comparison__price-bottom">
          <template v-if="hasTransitionParams">
            <ValueTransition :value="price" :transition-params="transitionParams">
              <template #default="{ transitionValue }">
                <CycleHtml
                  tag="span"
                  :html="transitionValue | CycleCurrency({ locale, split: true })"
                />
                <span class="comparison__payment-term">{{ paymentTerm }}</span>
              </template>
            </ValueTransition>
          </template>
          <template v-else>
            <CycleHtml tag="span" :html="price | CycleCurrency({ locale, split: true })" />
            <span class="comparison__payment-term">{{ paymentTerm }}</span>
          </template>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { pathOr } from 'ramda'
import { camelCase } from 'lodash'

import { CycleRadioInput, CycleButton, CycleHeading, CycleTag } from '@/components/elements'
import ContentToggle from '@/components/molecules/ContentToggle'
import { CycleHtml } from '@/components/base'
import { CycleButtonLink, CycleLink } from '@/components/cycle-link'
import { CycleList, CycleListItem } from '@/components/cycle-list'
import ModalTooltip from '@/components/molecules/ModalTooltip'
import ValueTransition from '@/components/molecules/ValueTransition'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'
export default {
  name: 'CycleCoverage',
  filters: {
    CycleCurrency
  },
  components: {
    CycleHeading,
    CycleList,
    CycleLink,
    CycleListItem,
    CycleButtonLink,
    CycleButton,
    ModalTooltip,
    CycleHtml,
    CycleRadioInput,
    CycleTag,
    ContentToggle,
    ValueTransition
  },
  mixins: [intlMixin],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    recommendedText: {
      type: String,
      required: false
    },
    isHighlighted: {
      type: Boolean,
      required: false
    },
    showToggle: {
      type: Boolean,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    bodytext: {
      type: String,
      required: false
    },
    price: {
      type: [Number, String],
      required: false
    },
    paymentTerm: {
      type: String,
      required: false,
      default: 'p/m'
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    alternativeOffers: {
      type: Array,
      required: false
    },
    link: {
      type: [String, Object],
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    modifiers: {
      type: [String, Array],
      required: false
    },
    value: {
      type: [String, Object],
      required: false
    },
    modelValue: {
      type: [String, Object],
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    highlightedCoverageToggleOpenText: {
      type: String,
      required: false,
      default: 'Toon dekkingen'
    },
    highlightedCoverageToggleCloseText: {
      type: String,
      required: false,
      default: 'Verberg dekkingen'
    },
    mostPopularText: {
      type: String,
      required: false
    },
    selectedText: {
      type: String,
      required: false
    },
    disabledText: {
      type: String,
      required: false
    },
    transitionParams: {
      type: Object,
      default: () => ({})
    },
    preventNotInsurableTextTooltip: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    testableName() {
      if (!this.name) {
        return 'coverageButton'
      }

      return `coverageButton-${camelCase(this.title.replace(/[^\w]+/g, ''))}`
    },
    comparisonItemClassList() {
      const mainClassName = 'comparison__item'
      return {
        [mainClassName]: true,
        [`${mainClassName}--disabled`]: this.disabled,
        [`${mainClassName}--highlighted`]: this.isHighlighted,
        [`${mainClassName}--selected`]: this.isSelected,
        [`${mainClassName}--bot-cards`]: this.isBotEnabled,
        [`${mainClassName}--card-checked`]: this.isCheckMarkEnabled
      }
    },
    showBodyText() {
      return (this.bodytext && !this.disabled) || (this.bodytext && this.preventNotInsurableTextTooltip)
    },
    showBodyTextTooltip() {
      return this.bodytext && !this.preventNotInsurableTextTooltip
    },
    isSelected() {
      return this.modelValue === this.value && this.modelValue != null
    },
    linkUrl() {
      return pathOr(false, ['link', 'href'], this)
    },
    linkText() {
      return pathOr(this.link, ['link', 'text'], this)
    },
    hasLink() {
      return this.linkText && this.linkUrl
    },
    hasList() {
      return this.list?.length > 0
    },
    isListInToggle() {
      return this.hasList && this.showToggle
    },
    isRecommendedTextVisible() {
      return !!this.recommendedText && this.isHighlighted
    },
    isPopular() {
      return !!this.mostPopularText
    },
    isBestOfferTool() {
      return this.isBotEnabled || this.isCheckMarkEnabled
    },
    isBotEnabled() {
      return this.modifiers?.includes('BestOfferTool')
    },
    isCheckMarkEnabled() {
      return this.modifiers?.[0]?.includes('BestOfferToolCheckMark')
    },
    hasSingleCoverBlock() {
      return this.modifiers?.[0]?.includes('BestOfferToolSingleCoverblock')
    },
    pricePosition() {
      return this.isBestOfferTool ? 'top' : 'bottom'
    },
    hasTransitionParams() {
      return !!this.transitionParams?.intervalTime
    },
    singleCoverblockSectionClassName() {
      return this.hasSingleCoverBlock ? 'comparison__list--single-coverblock-section' : ''
    },
    description() {
      return (this.list?.filter((item) => !item?.icon?.name))[0]?.text
    },
    hasDescription() {
      return this.description?.length > 0
    },
    hasTooltip() {
      return this.tooltip?.length > 0
    }
  },
  methods: {
    emitter(event) {
      this.$emit('click', this.value ?? event)
    },
    updateValue() {
      this.$emit('change', this.value)
    },
    extractOffer(offer) {
      return offer?.linkToSalesFunnel ?? {}
    },
    getUspIcon(usp) {
      return usp?.icon?.name ?? ''
    },
    hasUspIcon(usp) {
      return usp?.icon?.name
    }
  }
}
</script>
