import { OverviewGroupModel, OverviewEntryTypes } from '../../../shared'

export function requester({ textContent, formData }) {
  return new OverviewGroupModel({
    title: textContent.personaldataTitle,
    entries: [
      {
        label: textContent.salutationLabel,
        value: formData.salutation == 'V' ? 'Mevrouw' : 'Meneer'
      },
      {
        label: textContent.initialsLabel,
        value: formData.initials
      },
      {
        label: textContent.prepositionLabel,
        value: formData.preposition
      },
      {
        label: textContent.lastnameLabel,
        value: formData.lastname
      },
      {
        label: textContent.birthdateLabel,
        value: formData.birthdate,
        type: OverviewEntryTypes.DATE
      },
      {
        label: textContent.zipcodeLabel,
        value: formData.zipcode
      },
      {
        label: textContent.housenumberLabel,
        value: [formData.housenumber, formData.housenumberAddition].join(' ').trim()
      },
      {
        label: textContent.streetLabel,
        value: formData.street
      },
      {
        label: textContent.cityLabel,
        value: formData.city
      },
      {
        label: textContent.emailaddressLabel,
        value: formData.emailaddress
      },
      {
        label: textContent.mailinglistLabel,
        value: formData.mailinglist.includes('y') ? 'Ja' : 'Nee'
      },
      {
        label: textContent.phonenumberLabel,
        value: formData.phonenumber
      }
    ]
  })
}
