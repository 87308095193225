<template>
  <CycleCard>
    <template slot="header">
      <CycleHeading>
        <CycleLink :href="href" :target="target">
          <span class="link-text">{{ text }}</span>
          <span :class="`color-tag color-tag--${tag.type}`" v-if="tag.count">{{ tag.count }}</span>
        </CycleLink>
      </CycleHeading>
    </template>
  </CycleCard>
</template>
<script>
import { CycleLink } from '@/components//cycle-link'
import { CycleHeading } from '@/components/elements/CycleHeading'
import { CycleCard } from '@/components/card'

export default {
  name: 'CycleLinkCard',
  components: {
    CycleCard,
    CycleLink,
    CycleHeading
  },
  props: {
    href: {
      type: String,
      required: false,
      default: ''
    },
    target: {
      type: String,
      required: false,
      default: '_self'
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    tag: {
      type: Object,
      required: false,
      default: () => ({})
    }
  }
}
</script>
