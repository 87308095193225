//
//
//
//
//
//

import { GridComponentAdapter } from '@/components/organisms'

export default {
  name: 'GridComponentAdapterSitecore',
  components: { GridComponentAdapter },
  props: {
    title: {
      type: String
    },
    introduction: {
      type: String
    }
  },
  computed: {
    content() {
      return {
        ...this.$props
      }
    }
  }
}
