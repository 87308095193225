
import { pathOr } from 'ramda'

function parseModifiers(modifiers) {
  return Array.isArray(modifiers)
    ? modifiers
    : typeof modifiers === 'string'
    ? modifiers.split(/[^\w-]+/gm)
    : []
}

export default {
  name: 'CycleBaseComponent',
  props: {
    noBem: {
      type: Boolean,
      required: false,
      default: () => false
    },
    modifiers: {
      type: [String, Array],
      required: false,
      default: () => []
    }
  },
  computed: {
    bemClass() {
      const blockClass = pathOr(false, ['bem', 'block'], this)

      if (!blockClass || this.noBem) {
        // no BEM blok or BEM disabled, nothing else to do
        return {}
      }

      const bemClass = { [blockClass]: true }
      let modifiers = parseModifiers(this.modifiers)
      const defaultModifiers = parseModifiers(pathOr([], ['bem', 'defaultModifiers'], this))

      if (!modifiers.length && defaultModifiers.length) {
        modifiers = defaultModifiers
      }

      let allowedModifiers = pathOr(false, ['bem', 'allowedModifiers'], this)
      if (!Array.isArray(allowedModifiers) || !allowedModifiers.length) {
        allowedModifiers = false
      }

      modifiers
        .filter((_modifier) => !!_modifier) // discard empty strings
        .filter((_modifier) => (allowedModifiers ? allowedModifiers.includes(_modifier) : true)) // filter on allowed modifiers
        .forEach((_modifier) => (bemClass[`${blockClass}--${_modifier}`] = true))
      return bemClass
    }
  }
}
