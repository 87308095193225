//
//
//
//
//
//
//
//
//

import { CycleHtml } from '../../../../../base'
import { CycleInputContainer } from '../../../CycleInputContainer'
import { CycleTextarea } from '../../../../../elements'
import { mixin } from './mixin.js'

export default {
  name: 'CycleFinalQuestionText',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleTextarea
  },
  mixins: [mixin]
}
