//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleCheckboxInput } from '@/components/elements/forms'
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import {
  ProvidedText,
  PersonalDataForm,
  ContactDetailsForm,
  DetailDataOverview
} from '@/components/organisms'
import { Person, ContactDetails } from '@/models'
import { NotificationBar } from '@/components/atoms'

export default {
  name: 'HomeOwnerStepPersonalData',
  components: {
    ContactDetailsForm,
    CycleButton,
    CycleCheckboxInput,
    CycleFormPage,
    CycleHeading,
    CycleInputContainer,
    PersonalDataForm,
    DetailDataOverview,
    ProvidedText,
    NotificationBar
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    personValue: {
      type: Person
    },
    contactDetailsValue: {
      type: ContactDetails
    },
    isVerifiedEmailAddress: {
      type: Boolean
    }
  },
  data() {
    return {
      approvals: [],
      person: new Person(),
      contactDetails: new ContactDetails()
    }
  },
  computed: {
    mailingListKey() {
      return 'mailingList'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        if (newValue.approvedMailingList) {
          this.approvals = [this.mailingListKey]
        }
      }
    },
    personValue: {
      immediate: true,
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.person = newValue
      }
    },
    contactDetailsValue: {
      immediate: true,
      handler(newContactDetails) {
        if (typeof newContactDetails !== 'object') {
          return
        }

        this.contactDetails = newContactDetails
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        person: this.person,
        contactDetails: this.contactDetails,
        approvedMailingList: this.approvals.includes(this.mailingListKey)
      })
    }
  }
}
