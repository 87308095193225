<template>
  <CycleFormPage :on-submit="handleSubmit">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="liability.steps.personalData.header" />
      </CycleHeading>
    </template>
    <PersonalDataForm v-model="person" />
    <ContactDetailsForm
      v-model="contactDetails"
      :is-verified-email-address="isVerifiedEmailAddress"
    >
      <template #postalcode>
        <slot name="postalcode" />
      </template>
    </ContactDetailsForm>

    <CycleInputContainer>
      <template #label>
        <ProvidedText name="liability.steps.personalData.labels.mailingList.before" />
      </template>
      <CycleCheckboxInput v-model="approvals" :value="mailingListKey" required>
        <ProvidedText name="liability.steps.personalData.labels.mailingList.after" />
      </CycleCheckboxInput>
    </CycleInputContainer>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="liability.steps.personalData.labels.next" />
      </CycleButton>
    </template>

    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="liability.steps.personalData.labels.prev" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleCheckboxInput } from '@/components/elements/forms'
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import {
  ProvidedText,
  PersonalDataForm,
  ContactDetailsForm,
  DetailDataOverview
} from '@/components/organisms'
import { Person, ContactDetails, Address } from '@/models'
import { NotificationBar } from '@/components/atoms'

export default {
  name: 'LiabilityStepPersonalData',
  inject: ['textContent'],
  components: {
    ContactDetailsForm,
    CycleButton,
    CycleCheckboxInput,
    CycleFormPage,
    CycleHeading,
    CycleInputContainer,
    PersonalDataForm,
    DetailDataOverview,
    ProvidedText,
    NotificationBar
  },
  props: {
    address: {
      type: Address
    },
    value: {
      type: Object,
      required: false
    },
    textContent: {
      type: Object,
      required: false
    },
    isVerifiedEmailAddress: {
      type: Boolean
    }
  },
  data() {
    return {
      approvals: [],
      person: new Person(),
      contactDetails: new ContactDetails()
    }
  },
  computed: {
    mailingListKey() {
      return 'mailingList'
    }
    // hasPerson() {
    //   return this.person.initials !== undefined && this.person.lastName !== undefined
    // },
    // hasContactDetails() {
    //   return (
    //     this.contactDetails.emailAddress !== undefined &&
    //     this.contactDetails.phoneNumber !== undefined
    //   )
    // }
  },
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.person = newValue.person
        this.contactDetails = newValue.contactDetails
        if (newValue.approvedMailingList) {
          this.approvals = [this.mailingListKey]
        }
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        address: this.address,
        person: this.person,
        contactDetails: this.contactDetails,
        approvedMailingList: this.approvals.includes(this.mailingListKey)
      })
    }
  }
}
</script>
