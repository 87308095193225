<template>
  <span class="form__currency">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'CycleCurrencyInput'
}
</script>
