<template>
  <CycleHeading :weight="2"> {{ textContent.policyNumber }}: {{ policyNumber }} </CycleHeading>
</template>

<script>
import { CycleHeading } from '../elements/CycleHeading'

export default {
  name: 'CyclePolicyDetailsBlock',
  components: { CycleHeading },
  props: {
    textContent: {
      type: Object,
      required: true
    },
    policyNumber: {
      type: String,
      required: true
    }
  }
}
</script>
