<template>
  <CycleFormSet :heading="headerTitle">
    <CContainer label="Naam" :tooltip="textContent.fullNameTooltip">
      <CText v-model="formData.licenseowner.fullName" required :max-length="25" alpha-ext />
    </CContainer>
    <CContainer :label="textContent.birthdateLabel" :tooltip="textContent.birthdateTooltip">
      <CDate date required v-model="formData.licenseowner.birthdate" :min="min" :max="max" />
      <template #error-max>
        {{ phrase('error-messages-funnels-005') }}
      </template>
      <template #error-min>
        {{ phrase('error-messages-funnels-007') }}
      </template>
    </CContainer>
    <CyclePostcodeCheckLicenseOwner
      @input="checkPostcode()"
      :postcode-found="postcodeFound"
      :text-content="textContent"
    />
  </CycleFormSet>
</template>
<script>
import CycleFormSet from '../../../forms/CycleFormSet/CycleFormSet'
import { CycleInputContainer } from '../../../forms/CycleInputContainer'
import { CycleTextInput, CycleRadioInput } from '../../../../elements'
import { CycleDateInput } from '../../../../elements/forms'
import CyclePostcodeCheckLicenseOwner from './CyclePostcodeCheckLicenseOwner'
import { pathOr } from 'ramda'
import { dictionaryMixin } from '../../../../../mixins'

export default {
  name: 'LicenseOwner',
  components: {
    CycleFormSet,
    CContainer: CycleInputContainer,
    CDate: CycleDateInput,
    CText: CycleTextInput,
    CRadio: CycleRadioInput,
    CyclePostcodeCheckLicenseOwner
  },
  mixins: [dictionaryMixin],
  inject: ['formData'],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
    min: {
      type: [Date, String],
      default: ''
    },
    max: {
      type: [Date, String],
      default: ''
    }
  },
  data() {
    return {
      postcodeFound: undefined
    }
  },
  methods: {
    checkPostcode() {
      if (this.$jss) {
        this.$jss.gql
          .queryByName('addressFromPostalCodeAndHouseNumber', {
            inputModel: {
              postalCode: this.formData.licenseowner.zipcode,
              houseNumber: this.formData.licenseowner.housenumber
            }
          })
          .then((res) => {
            const foundStreet = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'street'],
              res
            )
            const foundCity = pathOr(
              null,
              ['data', 'addressFromPostalCodeAndHouseNumber', 'town'],
              res
            )
            if (foundStreet && foundCity) {
              this.postcodeFound = true
              this.formData.licenseowner.street = foundStreet
              this.formData.licenseowner.city = foundCity
            } else {
              this.postcodeFound = false
            }
          })
          .catch(() => {
            this.postcodeFound = false
          })
      } else {
        this.postcodeFound = false
      }
    }
  }
}
</script>
