let openedNavigationId = null

const isItemAlreadyOpen = (itemId) => {
  return itemId === openedNavigationId
}

export default (menuElement) => {
  const toggles = menuElement.parentNode.getElementsByClassName('nav-toggle')
  const toggled = () => [...toggles].some((t) => t.checked)

  const bodyEvent = (event) => {
    if (!menuElement.parentNode.contains(event.target)) {
      ;[...toggles].forEach((t) => {
        t.checked = false
        setAria(t.parentNode, 'false')
      })
      openedNavigationId = null
      document.body.removeEventListener(event.type, bodyEvent)
    }
  }
  const setAria = (el, value) => {
    el.querySelector('.nav-trigger')?.setAttribute('aria-expanded', value)
  }

  const dropDownEvent = (event) => {
    event.stopPropagation()
    if (event.target.type === 'radio') {
      if (isItemAlreadyOpen(event.target.id)) {
        openedNavigationId = null
        event.target.checked = false
      } else {
        ;[...toggles].forEach((t) => {
          if (t.id !== event.target.id) {
            setAria(t.parentNode, 'false')
            t.checked = false
          }
        })
        setAria(menuElement, 'true')
        openedNavigationId = event.target.id
      }
    }

    toggled() && document.body.addEventListener('click', bodyEvent)
  }

  menuElement.addEventListener('click', dropDownEvent)
}
