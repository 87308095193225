<template>
  <CycleClientRendering>
    <CycleIframe :href="href" />
  </CycleClientRendering>
</template>
<script>
import CycleClientRendering from '../../cycle-client-rendering/CycleClientRendering'
import CycleIframe from '../../cycle-iframe/CycleIframe'
import { linkMixin } from '../../../mixins'
export default {
  name: 'CycleIframeSitecore',
  components: { CycleIframe, CycleClientRendering },
  mixins: [linkMixin],
  props: {
    link: {
      type: Object,
      required: false
    }
  }
}
</script>
