<template>
  <picture class="picture picture--background">
    <source :key="media" :srcset="srcset" :media="media" v-for="(srcset, media) in sources" />
    <img :src="defaultSrc" :alt="alt" :title="title" loading="eager" :fetchpriority="fetchPriority" />
  </picture>
</template>

<script>
export default {
  name: 'ResponsivePicture',
  props: {
    sources: { type: Object, required: true },
    defaultKey: { type: String, required: true },
    alt: { type: String, default: '' },
    title: { type: String, default: '' },
    fetchPriority: { type: String }
  },
  computed: {
    defaultSrc() {
      return this.sources[this.defaultKey]
    }
  }
}
</script>
