//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleUidMixin } from '@/mixins'
import { NavigationItem } from '@/components/elements/NavigationItem'
import NavigationGroup from './NavigationGroup.vue'

export default {
  name: 'SubNavigationItem',
  mixins: [CycleUidMixin],
  components: {
    NavigationGroup,
    NavigationItem
  },
  props: {
    name: {
      type: String,
      required: true
    },
    navigationHiddenInputId: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    navigationItemHasAnActiveSubItem() {
      return this.navigationItemsWithSubItems?.some((navigationGroup) =>
        navigationGroup?.fields?.items?.some((navigationSubGroup) =>
          navigationSubGroup?.fields?.items?.some(this.isActive)
        )
      )
    },
    menuItemClass() {
      const mainClassName = 'navigation__item'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--dropdown`]: true,
        [`${mainClassName}--active`]: this.navigationItemHasAnActiveSubItem,
        [`${mainClassName}--login`]: this.hasNavigationItemsWithNoSubItems
      }
    },
    classNameForLabel() {
      return {
        'nav-trigger nav-trigger--next': true,
        // this will show an icon only in desktop (for mobile, we get icon + name of the navigation item)
        'nav-trigger--login': this.hasNavigationItemsWithNoSubItems
      }
    },
    navigationToggleInputId() {
      return `toggle_${this._cycleUid}`
    },
    navigationItemsWithSubItems() {
      return this.items?.filter(this.hasSubItems) ?? []
    },
    navigationItemsWithNoSubItems() {
      return this.items?.filter((item) => !this.hasSubItems(item)) ?? []
    },
    hasNavigationItemsWithSubItems() {
      return this.navigationItemsWithSubItems?.length > 0
    },
    hasNavigationItemsWithNoSubItems() {
      return this.navigationItemsWithNoSubItems?.length > 0
    },
    hasAnySubItems() {
      return this.hasNavigationItemsWithSubItems || this.hasNavigationItemsWithNoSubItems
    }
  },
  methods: {
    getItems(item) {
      return item?.fields?.items ?? []
    },
    getGroupLink(item) {
      return item?.fields?.GroupLink ?? {}
    },
    hasSubItems(item) {
      return !!item?.fields?.items?.length
    },
    href(item) {
      return item?.fields?.Link?.value?.href
    },
    title(item) {
      return item?.fields?.Title?.value
    },
    isActive(item) {
      return !!item?.fields?.CurrentItem
    }
  }
}
