//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import format from 'date-fns/format'
import subDays from 'date-fns/subDays'

import { logError } from '../../../../services/Logging'
import CycleCheckout from '../../CycleCheckout/CycleCheckout'
import { CycleCurrency } from '../../../../filters'
import { CycleTextarea, CycleNumberInput, CycleDateInput } from '../../../elements/forms'
import CycleSimpleForm from '../CycleSimpleForm/CycleSimpleForm'
import CycleInputContainer from '../CycleInputContainer/CycleInputContainer'
import { CycleHtml } from '../../../base'
import { dictionaryMixin } from '../../../../mixins'
import CycleHiddenInput from '../../../elements/forms/CycleHiddenInput/CycleHiddenInput'

export default {
  name: 'CycleClaimForm',
  components: {
    CycleHiddenInput,
    CycleHtml,
    CycleSimpleForm,
    CycleTextarea,
    CycleDateInput,
    CContainer: CycleInputContainer,
    CycleNumberInput,
    CycleCheckout
  },
  filters: { CycleCurrency },
  mixins: [dictionaryMixin],
  props: {
    policyNumber: {
      type: String
    },
    insuranceName: {
      type: String
    },
    insuranceHash: {
      type: String
    },
    textContent: {
      type: Object
    },
    formSubmitAction: {
      type: Function
    }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        claimDate: null,
        explanation: null,
        estimatedClaimCost: null,
        policyNumber: this.insuranceHash
      },
      isFormPosted: false,
      postSucceeded: false
    }
  },
  computed: {
    today() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxClaimWindow() {
      return format(subDays(new Date(), 90), 'yyyy-MM-dd')
    }
  },
  methods: {
    format,
    createClaim() {
      this.isFormPosted = true
      const form = {
        ...this.formData,
        policyNumber: this.insuranceHash
      }
      return this.formSubmitAction(form)
        .then(() => (this.postSucceeded = true))
        .catch((res) => {
          this.postSucceeded = false
          logError(res)
        })
    }
  }
}
