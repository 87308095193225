//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleDateInput, CycleSelect, CycleOption, CycleButton } from '@/components/elements'
import {
  CycleFormPage,
  CycleFormSet,
  CycleInputContainer,
  CycleForm
} from '@/components/functional/forms'
import { dictionaryMixin } from '@/mixins'
import { currentYYYYMMDD } from '@/services/DateTime'
import ContentToggle from './ContentToggle'
import { parseISO } from 'date-fns'
const isValidDateValue = (dateString) => !isNaN(parseISO(dateString).getTime())

const currentYear = new Date().getFullYear()
const defaultFilterFromYear = currentYear - 3

export default {
  name: 'DocumentsFilter',
  components: {
    CycleOption,
    ContentToggle,
    CContainer: CycleInputContainer,
    CDate: CycleDateInput,
    CSelect: CycleSelect,
    COption: CycleOption,
    CycleButton,
    CycleFormSet,
    CycleFormPage,
    CycleForm
  },
  mixins: [dictionaryMixin],
  provide() {
    return {
      formData: this.formData
    }
  },
  props: {
    /**
     * options to filter documents on
     */
    documentCodesFilter: {
      type: Array,
      required: true,
      default: () => []
    },
    textContent: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        dateFrom: `${defaultFilterFromYear}-01-01`,
        dateTo: currentYYYYMMDD(),
        documentFilter: []
      }
    }
  },
  computed: {
    today() {
      return currentYYYYMMDD()
    },
    minDate() {
      const minFilterYear = currentYear - 7
      return `${minFilterYear}-01-01`
    },
    minDateTo() {
      if (!isValidDateValue(this.formData.dateFrom) && !isValidDateValue(this.formData.dateTo)) {
        return this.minDate
      }

      return this.formData.dateFrom
    },
    filterOptions() {
      if (this.documentCodesFilter?.length === 0) {
        return []
      }

      return this.documentCodesFilter.filter((option) => option.codes?.length !== 0)
    }
  },
  methods: {
    getFormValues() {
      let filterCodes = []
      if (this.formData?.documentFilter?.length !== 0) {
        filterCodes = this.formData.documentFilter.map(({ code }) => code)
      }
      return {
        documentFilter: filterCodes,
        dateFrom: this.formData?.dateFrom ?? `${defaultFilterFromYear}-01-01`,
        dateTo: this.formData?.dateTo ?? this.today
      }
    },
    filterDocuments() {
      this.$emit('filter-change', this.getFormValues())
    }
  }
}
