<template>
  <div v-if="active && description">
    <CycleHtml :html="description" />
  </div>
</template>

<script>
import { CycleHtml } from '../../../../../base'
import { mixin } from './mixin'

export default {
  name: 'CycleFinalQuestionInfoText',
  components: { CycleHtml },
  mixins: [mixin]
}
</script>
