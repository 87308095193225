<template>
  <CycleFormPage :has-next-button="false">
    <template #header>
      <CycleHeading :weight="3"><ProvidedText name="liability.steps.offers.header" /></CycleHeading>
    </template>

    <CycleFormSet>
      <CycleAdviceBlock v-if="premium" @chosen="handlePremiumChosen" :premium="premium.premium">
        <template #advice-description>
          <ProvidedText name="liability.steps.offers.labels.premiumTaxes" />
          <ProvidedTooltip
            heading-name="liability.steps.offers.headings.premiumTaxes"
            body-name="liability.steps.offers.tooltips.premiumTaxes"
          />
        </template>

        <template #advice-usps>
          <CycleHtml :html="premium.usp" />
        </template>

        <template #advice-button>
          <ProvidedText name="liability.steps.offers.labels.next" />
        </template>
      </CycleAdviceBlock>
    </CycleFormSet>

    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="liability.steps.offers.labels.prev" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleHtml } from '@/components/base'
import { CycleAdviceBlock } from '@/components/cycle-advice-block'
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { ProvidedHtml, ProvidedText, ProvidedTooltip } from '@/components/organisms'

import { ProductPremium } from '../../models'

export default {
  name: 'LiabilityStepOffers',
  components: {
    CycleAdviceBlock,
    CycleTooltip,
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    CycleHtml,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  props: {
    premium: {
      required: false,
      type: ProductPremium
    }
  },
  methods: {
    handlePremiumChosen() {
      this.$emit('submit')
    }
  }
}
</script>
