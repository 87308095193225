<template>
  <div class="form__set">
    <CContainer>
      <template slot="label">
        {{ textContent.zipcodeLabel }}
        <CycleTooltip v-if="textContent.zipcodeTooltip">
          <template slot="tooltip-heading">
            {{ textContent.zipcodeLabel }}
          </template>
          <template slot="tooltip-content">
            <CycleHtml :html="textContent.zipcodeTooltip" />
          </template>
        </CycleTooltip>
      </template>
      <CText
        v-model="formData.driver.zipcode"
        :placeholder="textContent.zipcodePlaceholder"
        postcode
        required
        :disabled="disabled.includes('zipcode')"
      />
    </CContainer>
    <CContainer>
      <template slot="label">
        {{ textContent.housenumberLabel }}
        <CycleTooltip v-if="textContent.housenumberTooltip">
          <template slot="tooltip-heading">
            {{ textContent.housenumberLabel }}
          </template>
          <template slot="tooltip-content">
            <CycleHtml :html="textContent.housenumberTooltip" />
          </template>
        </CycleTooltip>
      </template>
      <CNumber
        v-model="formData.driver.housenumber"
        required
        numeric
        :max-length="25"
        :disabled="disabled.includes('housenumber')"
        class="form__input form__input--inline"
      />
      <CText
        v-model="formData.driver.housenumberAddition"
        placeholder="Optioneel"
        :disabled="disabled.includes('housenumberAddition')"
        graphql-safe
        :max-length="5"
        class="form__input form__input--inline"
      />
    </CContainer>
    <template v-if="postcodeFound !== undefined">
      <CContainer>
        <template slot="label">
          {{ textContent.streetLabel }}
          <CycleTooltip v-if="textContent.streetTooltip">
            <template slot="tooltip-heading">
              {{ textContent.streetLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.streetTooltip" />
            </template>
          </CycleTooltip>
        </template>
        <CText
          v-model="formData.driver.street"
          required
          alpha-ext
          :max-length="25"
          :disabled="!manualEntry || disabled.includes('street')"
        />
      </CContainer>
      <CContainer>
        <template slot="label">
          {{ textContent.cityLabel }}
          <CycleTooltip v-if="textContent.cityTooltip">
            <template slot="tooltip-heading">
              {{ textContent.cityLabel }}
            </template>
            <template slot="tooltip-content">
              <CycleHtml :html="textContent.cityTooltip" />
            </template>
          </CycleTooltip>
        </template>
        <CText
          v-model="formData.driver.city"
          required
          alpha-ext
          :max-length="25"
          :disabled="!manualEntry || disabled.includes('city')"
        />
      </CContainer>
    </template>
  </div>
</template>

<script>
import CycleInputContainer from './../../../forms/CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../../../../elements/forms/CycleNumberInput/CycleNumberInput'
import CycleTooltip from '../../../../cycle-tooltip/CycleTooltip'
import { CycleHtml } from '../../../../base'
import { debounce } from 'lodash'

export default {
  name: 'CyclePostcodeCheckDriver',
  components: {
    CycleHtml,
    CycleTooltip,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData'],
  props: {
    postcodeFound: {
      type: Boolean,
      required: false
    },
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    zipcode() {
      return this.formData.driver.zipcode || ''
    },
    housenumber() {
      return this.formData.driver.housenumber || ''
    },
    zipcode_housenumber() {
      return this.zipcode.length + this.housenumber.length
    },
    manualEntry() {
      return !this.postcodeFound
    }
  },
  watch: {
    zipcode_housenumber() {
      if (this.zipcode.length >= 6 && this.housenumber.length > 0) {
        this.fetchAddress(this.formData.driver)
      }
    }
  },
  methods: {
    fetchAddress: debounce(function (e) {
      this.$emit('input', e)
    }, 1000)
  }
}
</script>
