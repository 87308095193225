//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatusIndicator from '../StatusIndicator'
import { PolicyStatusValue } from '@/models/PolicyStatus'

/**
 * A documents article describes a single document. The first two columns are typically reserved for
 * the documents status and its title. The amount of columns is determined by the type of documents
 * wrapper. By default it has six.
 *
 * This is a controlled component which emits `open` when wanting to be opened. The parent component
 * should then toggle the `isOpen` prop to show the documents details.
 *
 * @since 3.4.0
 */
export default {
  name: 'DocumentsArticle',
  components: { StatusIndicator },
  props: {
    /**
     * Current status for the document, has to be one of `PolicyStatusValues`.
     * Defaults to `StatusIndicator` default status.
     * @see packages\component-documentation\stories\atoms\StatusIndicator.stories.js
     * @see packages\cycle\src\models\PolicyStatus.ts
     */
    status: {
      type: String,
      validator: (value) => Object.values(PolicyStatusValue).includes(value)
    },
    /**
     * Controls whether the details for this document are shown.
     */
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      /**
       * Emitted when the user wants to either show or hide the details. Based on the current
       * `isOpen` prop.
       *
       * @event toggle
       * @type {void}
       */
      this.$emit('toggle')
    }
  }
}
