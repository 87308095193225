//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pathOr } from 'ramda'
import { logError } from '../../services/Logging'
import CycleConfirmation from '../cycle-confirmation/CycleConfirmation'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import CycleSimpleForm from '../functional/forms/CycleSimpleForm/CycleSimpleForm'
import CycleButton from '../elements/CycleButton/CycleButton'
import CycleRadioInput from '../elements/forms/CycleRadioInput/CycleRadioInput'
import { CycleDateInput } from '../elements/forms'
import CycleInputContainer from '../functional/forms/CycleInputContainer/CycleInputContainer'
import CycleTextInput from '../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../elements/forms/CycleNumberInput/CycleNumberInput'
import CycleFormSet from '../functional/forms/CycleFormSet/CycleFormSet'
import CycleTooltip from '../cycle-tooltip/CycleTooltip'
import CycleHtml from '../base/CycleHtml'

export default {
  name: 'CyclePremiumPaymentArrangementRequest',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleTextInput,
    CycleRadioInput,
    CycleNumberInput,
    CycleButton,
    CycleSimpleForm,
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleConfirmation,
    CycleTooltip
  },
  props: {
    textContent: { type: Object, required: true },
    formSubmitAction: { type: Function, required: true }
  },
  provide() {
    return {
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        name: null,
        insuredNumber: null,
        dateOfBirth: null,
        postalCode: null,
        houseNumber: null,
        houseNumberAddition: null,
        phoneNumber: null,
        emailAddress: null,
        emailAddressToValidate: null,
        paymentMethod: null,
        iban: null,
        bic: null
      },
      isFormPosted: null,
      postSucceeded: null
    }
  },
  computed: {
    incassoPaymentChosen() {
      return this.formData.paymentMethod === 'DirectDebit'
    },
    selfPaymentChosen() {
      return this.formData.paymentMethod === 'WireTransfer'
    }
  },
  methods: {
    submitForm() {
      this.isFormPosted = true
      return this.formSubmitAction(this.formData)
        .then((response) => {
          this.postSucceeded = pathOr(
            false,
            ['data', 'requestPremiumPaymentArrangement', 'success'],
            response
          )
        })
        .catch((err) => {
          this.postSucceeded = false
          logError(err)
        })
    }
  }
}
