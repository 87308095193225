<script>
import { get } from 'lodash'

export default {
  name: 'ProvidedText',
  inject: { textContent: 'textContent', textContentMapper: { default: null } },
  functional: true,
  props: {
    name: {
      type: String,
      required: true
    }
  },
  render(_, context) {
    let name = context.props.name
    if (typeof context.injections.textContentMapper === 'function') {
      // Enters a temporary workaround while we refactor labels
      // If all funnels are using the new 'Text Set' system, this can be removed
      // and the re-usable components such as PersonalDataForm can be adjusted

      name = context.injections.textContentMapper(name)
    }

    const text = get(context.injections.textContent, name, name)

    if (Array.isArray(context.children) && context.children.length > 0) {
      return context.children[0](text)
    }
    if (context.children != null) {
      return context.children(text)
    }
    // Use Vue's internal method to create text VNodes -- Maarten Bicknese 05-10-19
    return context._v(text)
  }
}
</script>
