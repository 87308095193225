<template>
  <ul :class="receiptClass">
    <h4 v-if="heading" class="receipt__heading">{{ heading }}</h4>
    <span v-if="description" class="receipt__label">{{ description }}:</span>

    <template v-for="(item, index) in receiptItems">
      <li class="receipt__item" :key="index" v-if="item">
        <span :class="labelClass(item)">{{ item.label }}</span>

        <span v-if="isAmount(item.type)" :class="valueItemClass(item)">
          {{ item.value | cycleCurrency({ locale }) }}
        </span>
        <span v-else-if="isDate(item.type)" :class="valueItemClass(item)">
          {{ item.value | cycleDate({ locale }) }}
        </span>
        <span v-else :class="valueItemClass(item)">
          {{ item.value }}
        </span>

        <ul class="receipt__specification" v-if="item.packages">
          <li class="receipt__item" v-for="(subPackage, index2) in item.packages" :key="index2">
            <span :class="labelClass(subPackage)">{{ subPackage.label }}</span>
            <template v-if="subPackage.value">
              <span :class="valueItemClass(subPackage)" v-if="isAmount(subPackage.type)">
                {{ subPackage.value | cycleCurrency({ locale }) }}
              </span>
              <span v-else-if="isDate(subPackage.type)" :class="valueItemClass(subPackage)">
                {{ subPackage.value | cycleDate({ locale }) }}
              </span>
              <span :class="valueItemClass(subPackage)" v-else> {{ subPackage.value }}</span>
            </template>
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import { CycleCurrency, CycleDate } from '../../filters'
import { intlMixin } from '../../mixins'

const isAmountRegex = new RegExp('^amount$', 'i')
const isDateRegex = new RegExp('^date$', 'i')

export default {
  name: 'CycleReceipt',
  filters: { CycleCurrency, CycleDate },
  mixins: [intlMixin],
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    heading: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    highlighted: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    receiptClass() {
      const _className = 'receipt'
      return {
        [_className]: true,
        [`${_className}--highlighted`]: this.highlighted
      }
    },
    receiptItems() {
      return this.data
    }
  },
  methods: {
    isAmount(type) {
      return isAmountRegex.test(type)
    },
    isDate(type) {
      return isDateRegex.test(type)
    },
    valueItemClass(item) {
      const _className = 'receipt__value'
      return {
        [_className]: true,
        [`${_className}--price`]: item.Type === 'amount' && item.value !== null
      }
    },
    labelClass(item) {
      const _className = 'receipt__label'
      return {
        [_className]: true,
        [`${_className}--highlighted`]: item.packages
      }
    }
  }
}
</script>
