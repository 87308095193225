<script>
import { CyclePagination } from '@/components/cycle-pagination'
import { PaginatedList } from '@/models'

export default {
  name: 'PaginationNav',
  functional: true,
  props: {
    for: {
      type: PaginatedList,
      required: true
    }
  },
  render(createElement, context) {
    return createElement(CyclePagination, {
      ...context.data,
      props: {
        ...context.props,
        totalNumberOfItems: context.props.for.length,
        currentPage: context.props.for.page,
        numberOfItemsOnPage: context.props.for.maxLength
      }
    })
  }
}
</script>
