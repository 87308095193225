//
//
//
//
//
//

import { CycleIcon } from '@/components/elements'
import icons from '@/components/elements/CycleIcon/icons'

export default {
  name: 'HeadingIcon',
  components: { CycleIcon },
  props: {
    icon: {
      type: String,
      required: true,
      validator: (icon) => icons.includes(icon)
    },
    isGreyed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bemClass() {
      let baseClass = 'panel__icon'
      if(this.isGreyed) {
        return `${baseClass}--greyed`
      }

      return baseClass
    }
  }
}
