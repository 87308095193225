<template>
  <CycleTooltip v-if="hasContent">
    <template #tooltip-heading>
      <ProvidedText :name="headingName" />
    </template>
    <template #tooltip-content>
      <ProvidedHtml :name="bodyName" />
    </template>
  </CycleTooltip>
</template>

<script>
import { get } from 'lodash'

import { CycleTooltip } from '../../cycle-tooltip'
import ProvidedHtml from './ProvidedHtml'
import ProvidedText from './ProvidedText'

export default {
  name: 'ProvidedTooltip',
  inject: ['textContent'],
  components: {
    CycleTooltip,
    ProvidedText,
    ProvidedHtml
  },
  props: {
    bodyName: {
      type: String,
      required: true
    },
    headingName: {
      type: String,
      required: true
    }
  },
  computed: {
    hasContent() {
      return this.textContent && get(this.textContent, this.bodyName, '').length > 0
    }
  }
}
</script>
