//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CycleHtml from '../base/CycleHtml'
import CycleList from '../cycle-list/CycleList'
import CycleListItem from '../cycle-list/cycle-list-item/CycleListItem'
import FaqFeedback from '@/components/faq-feedback/FaqFeedback'
import { uuid4 } from '@/services/UUID'
export default {
  name: 'CycleFaqList',
  components: {
    CycleHtml,
    CycleList,
    CycleListItem,
    FaqFeedback
  },
  props: {
    faqData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    uuid() {
      return uuid4()
    }
  },
  methods: {
    getFaqInputId(item) {
      return `${this.uuid}--${item.question}`
    }
  }
}
