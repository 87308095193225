//
//
//
//

import { logWarning } from '../../services/Logging'

export default {
  name: 'CycleGtm',
  mounted() {
    logWarning('<CycleGtm /> is deprecated, please stop using it.')
  }
}
