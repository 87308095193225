//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput, CycleRadioInput, CycleTextInput } from '@/components/elements/forms'
import { CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { ContactDetails } from '@/models'

import ProvidedHtml from '../Content/ProvidedHtml'
import ProvidedText from '../Content/ProvidedText'
import ProvidedTooltip from '../Content/ProvidedTooltip'

export default {
  name: 'ContactDetailsForm',
  components: {
    CycleButton,
    CycleDateInput,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTextInput,
    CycleTooltip,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  inject: ['formData'],
  props: {
    value: {
      type: ContactDetails
    },
    isVerifiedEmailAddress: {
      type: Boolean
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.$set(this.formData, 'contactDetails', value)

        if (this.isVerifiedEmailAddress) {
          this.formData.emailAddressVerify = value.emailAddress
        }
      }
    }
  }
}
