<template>
  <fieldset :class="classList">
    <CycleHeading :weight="3" v-if="heading || $slots.heading">
      <slot name="heading">{{ heading }}</slot>
    </CycleHeading>

    <slot />
    <p class="form__text" v-if="!!subtext">{{ subtext }}</p>
  </fieldset>
</template>

<script>
import { CycleHeading } from '../../../elements/CycleHeading'

export default {
  name: 'CycleFormSet',
  components: { CycleHeading },
  props: {
    heading: {
      type: String,
      default: ''
    },
    subtext: {
      type: String,
      default: ''
    },
    modifiers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    classList() {
      const baseClassName = 'form__set'
      return [baseClassName, ...this.modifiers.map((modifier) => `${baseClassName}--${modifier}`)]
    }
  }
}
</script>
