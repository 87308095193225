//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from 'lodash'

import { dictionaryMixin } from '@/mixins'
import CycleInputContainer from './../../../forms/CycleInputContainer/CycleInputContainer'
import CycleFormConfirmation from '../../../forms/CycleFormConfirmation/CycleFormConfirmation'
import CycleTextInput from '../../../../elements/forms/CycleTextInput/CycleTextInput'
import CycleNumberInput from '../../../../elements/forms/CycleNumberInput/CycleNumberInput'
import CycleTooltip from '../../../../cycle-tooltip/CycleTooltip'
import { CycleHtml } from '../../../../base'
import { CycleFormSet } from '../../CycleFormSet'

export default {
  name: 'CyclePostcodeCheck',
  components: {
    CycleHtml,
    CycleTooltip,
    CycleFormConfirmation,
    CycleFormSet,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData'],
  mixins: [dictionaryMixin],
  props: {
    postcodeFound: {
      type: Boolean,
      required: false
    },
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Array,
      required: false,
      default: () => []
    },
    hasManualEntry: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    manualEntry() {
      return !this.postcodeFound
    },
    showStreetCityInputs() {
      if (!this.hasManualEntry) {
        return this.postcodeFound
      }
      return this.postcodeFound !== undefined
    },
    showErrorMessage() {
      return !this.hasManualEntry && this.postcodeFound === false
    }
  },
  methods: {
    fetchAddress: debounce(function (e) {
      this.$emit('input', e)
    }, 1000),
    handleHousenumberZipcodeInput() {
      if (this.formData.zipcode == null || this.formData.housenumber == null) {
        return
      }

      if (this.formData.zipcode.length >= 6 && this.formData.housenumber.length > 0) {
        this.fetchAddress(this.formData)
      }
    }
  }
}
