//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleUidMixin } from '@/mixins/uid'
import { CycleHeading, CycleIcon } from '@/components/elements'
import { CycleCard } from '@/components/card'
export default {
  name: 'Modal',
  components: {
    CycleHeading,
    CycleIcon,
    CycleCard
  },
  mixins: [CycleUidMixin],
  props: {
    isShown: {
      type: Boolean,
      required: false,
      default: false
    },
    tooltipHasLongText: {
      type: Boolean,
      required: false
    },
    disallow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalId: `modal-${this._cycleUid}`,
      isStatic: false
    }
  },
  watch: {
    isShown(newVal) {
      if (newVal) {
        this.scrollTo()
      }
    }
  },
  computed: {
    tooltipClassList() {
      const mainClassName = 'modal'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--static`]: this.isStatic,
        [`${mainClassName}__overlay`]: !this.tooltipHasLongText,
        [`${mainClassName}__overlay--slider`]: this.tooltipHasLongText,
        [`${mainClassName}__active`]: this.isShown
      }
    }
  },
  mounted() {
    const self = this

    if (this.isIframe()) {
      this.isStatic = true
    }

    window?.addEventListener('keyup', function (e) {
      if (e.key === 'Esc' || e.key === 'Escape') {
        self.close()
      }
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async scrollTo() {
      if (this.isIframe()) {
        await this.$nextTick()
        this.$refs.modalContent.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
    isIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return false
      }
    }
  }
}
