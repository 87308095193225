
import { Address } from '../../../models'

export default {
  name: 'AddressLine',
  functional: true,
  props: {
    address: {
      type: Address,
      required: true
    }
  },
  render(createElement, context) {
    const {
      props: { address }
    } = context

    return createElement('span', {}, [
      [address.street, address.houseNumber, address.houseNumberAddition].join(' '),
      createElement('br'),
      [address.postalCode, address.city].join(' ')
    ])
  }
}
