<template>
  <div class="notify notify--warning">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WarningMessage'
}
</script>
