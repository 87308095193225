import rootStore from '../root'
import communication from '../communication'
import authentication from '../authentication'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

const store = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
  modules: {
    root: {
      ...rootStore,
      namespaced: false
    },
    communication,
    authentication
  }
}

export default store
