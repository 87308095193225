<template>
  <CycleForm>
    <CycleFormPage :on-submit="submitForm" :pre-funnel-button-text="nextButtonText" is-pre-funnel>
      <CycleSelect required v-model="formData.selectedBuildingType">
        <template #option>
          <CycleOption
            :key="chooseBuildingTypeText"
            :value="defaultSelectedBuildingType"
            v-text="chooseBuildingTypeText"
          />
          <CycleOption
            v-for="buildingType in buildingTypes"
            :key="buildingType.key"
            :value="buildingType"
            v-text="buildingType.value"
          />
        </template>
      </CycleSelect>
    </CycleFormPage>
  </CycleForm>
</template>

<script>
import { CycleSelect, CycleOption } from '@/components/elements'
import { CycleForm, CycleFormPage } from '@/components/functional/forms'
import { storeModuleMixinFactory } from '@/mixins'

import store from '@/stores/profile'

export default {
  name: 'HomeContentsPreFunnel',
  components: {
    CycleForm,
    CycleFormPage,
    CycleSelect,
    CycleOption
  },
  mixins: [
    storeModuleMixinFactory({
      moduleNamespace: 'profile',
      storeModule: store,
      actionsMapping: ['submitBuildingTypeKey'],
      gettersMapping: ['prefilledBuildingTypeKey']
    })
  ],
  props: {
    link: {
      type: Object,
      default: () => ({})
    },
    chooseBuildingTypeText: {
      type: String
    },
    buildingTypes: {
      type: Array,
      default: () => []
    }
  },
  provide() {
    return {
      shouldUseDefaultBrowserScrollBehaviour: true,
      formData: this.formData
    }
  },
  data() {
    return {
      formData: {
        selectedBuildingType: {}
      }
    }
  },
  computed: {
    url() {
      return this.link?.href ? this.link?.href : null
    },
    nextButtonText() {
      return this.link?.text || this.link?.title
    },
    defaultSelectedBuildingType() {
      return { key: this.chooseBuildingTypeText, value: null, isInsurable: false }
    }
  },
  mounted() {
    this.formData.selectedBuildingType = this.prefilledBuildingTypeKey
      ? this.buildingTypes.find((bt) => bt.key === this.prefilledBuildingTypeKey)
      : this.defaultSelectedBuildingType
  },
  methods: {
    async submitForm() {
      await this.submitBuildingTypeKey(this.formData.selectedBuildingType.key)
      if (this.url) {
        this.$router.push(this.url)
      }
    }
  }
}
</script>
