//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput, CycleRadioInput, CycleTextInput } from '@/components/elements/forms'
import { CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { Person } from '@/models'
import { Gender, LegalAge, MaxNewPolicyAge } from '@/constants'
import { dictionaryMixin } from '../../../mixins/dictionary'

import ProvidedHtml from '../Content/ProvidedHtml'
import ProvidedText from '../Content/ProvidedText'
import ProvidedTooltip from '../Content/ProvidedTooltip'

export default {
  name: 'PersonalDataForm',
  components: {
    CycleButton,
    CycleDateInput,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTextInput,
    CycleTooltip,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  mixins: [dictionaryMixin],
  inject: ['formData'], // needed for validations
  props: {
    value: {
      required: true,
      type: Person
    },
    disableDateOfBirth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    genders() {
      return Gender
    },
    LegalAge() {
      return LegalAge
    },
    MaxNewPolicyAge() {
      return MaxNewPolicyAge
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.$set(this.formData, 'person', value)
      }
    }
  }
}
