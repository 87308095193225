<template>
  <PersonalData 
    :person="person" 
    :address="address" 
    :postal-address="postalAddress" 
    :show-postal-address="showPostalAddress"
    :edit-route="editRoute" />
</template>

<script>
import { Address, Person, Route } from '../../../models'
import { PersonalData } from '../../organisms'

export default {
  name: 'CyclePersonalDetailsSitecore',
  components: {
    PersonalData
  },
  props: {
    heading: String,
    nameLabel: String,
    emptyName: String,
    addressLabel: String,
    postalAddressLabel: String,
    emptyAddressLine1: String,
    emptyAddressLine2: String,
    birthdayLabel: String,
    emptyBirthday: String,
    mypersonalinfo: {
      type: Object,
      required: false,
      default: () => ({})
    },
    link: {
      type: Object,
      required: true
    }
  },
  provide() {
    return {
      textContent: {
        personalData: {
          header: this.heading,
          labels: {
            name: this.nameLabel,
            address: this.addressLabel,
            dateOfBirth: this.birthdayLabel,
            postalAddress : this.postalAddressLabel,
            edit: this.link.title
          },
          placeholders: {
            name: this.emptyName,
            address: this.emptyAddress,
            dateOfBirth: this.emptyBirthday
          }
        }
      }
    }
  },
  computed: {
    person() {
      if (Object.keys(this.personalData).length === 0) {
        return null
      }

      return new Person(this.personalData)
    },
    dateOfBirth() {
      return this.personalData.dateOfBirth
    },
    address() {
      if (this.personalData.residentialAddress == null) {
        return null
      }

      return Address.fromGraphQL(this.personalData.residentialAddress)
    },
    postalAddress() {
      if (this.personalData?.mailingAddress == null) {
        return null
      }
      return Address.fromGraphQL(this.personalData?.mailingAddress)
    },
    showPostalAddress() {
      return this.personalData?.deviatingMailingAddress
    },
    personalData() {
      return this.mypersonalinfo?.personalData || {}
    },
    editRoute() {
      return Route.fromLink(this.link)
    }
  }
}
</script>
