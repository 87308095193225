<template>
  <CycleCheckboxInput class="toggle-checkbox" :model-value="modelValue" @change="handleChange">
    <ContentToggle
      :open-action-text="openActionText"
      :close-action-text="closeActionText"
      :is-default-open="modelValue"
      is-plain
    />
  </CycleCheckboxInput>
</template>

<script>
import ContentToggle from './ContentToggle'
import { CycleCheckboxInput } from '@/components/elements/forms'

export default {
  name: 'ToggleCheckbox',
  components: { ContentToggle, CycleCheckboxInput },
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    openActionText: {
      type: String,
      default: ''
    },
    closeActionText: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    }
  }
}
</script>
