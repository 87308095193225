//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleButton, CycleHeading, CycleRadioInput } from '@/components/elements'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional'
import { CycleCheckout, CycleCheckoutItem } from '@/components/functional/CycleCheckout'
import { ProvidedText } from '@/components/organisms'
import { HomeContentsCoverageTypes, ProductPremium, Coverages } from '../../models'

import { debounce } from 'lodash'

export default {
  name: 'HomeContentsStepCoverages',
  inject: ['contentProvider', 'textContent', 'formData'],
  components: {
    CycleButton,
    CycleInputContainer,
    CycleRadioInput,
    CycleFormPage,
    CycleHeading,
    ProvidedText,
    CycleFormSet,
    CycleCheckout,
    CycleCheckoutItem
  },
  props: {
    selectedCoverages: {
      type: Coverages,
      default: () => {}
    },
    premium: {
      type: ProductPremium,
      required: false
    },
    jewelryOptions: {
      type: Array,
      required: false
    },
    audioVisualOptions: {
      type: Array,
      required: false
    },
    tenantsOwnershipOptions: {
      type: Array,
      required: false
    },
    specialPossessionsOptions: {
      type: Array,
      required: false
    },
    displayCyberCoverage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      coverageTypes: HomeContentsCoverageTypes,
      localCoverages: {}
    }
  },
  computed: {
    totalPremium() {
      return this.premium?.totalPremium
    }
  },
  watch: {
    selectedCoverages: {
      immediate: true,
      handler(selectedCoverages) {
        this.localCoverages = new Coverages(selectedCoverages)
      }
    }
  },
  methods: {
    updateSelectedCoverages: debounce(function () {
      this.$emit('coverage-update', this.localCoverages)
    }, 500),
    handleSubmit() {},
    premiumCoverage(coverage) {
      return this.premium?.coverages[coverage]
    }
  }
}
