//
//
//
//
//
//

import { NotificationTypes } from './NotificationTypes'
export default {
  name: 'NotificationBar',
  props: {
    notificationType: {
      type: String,
      required: true,
      validator(value) {
        return value in NotificationTypes
      }
    },
    nomargin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classList() {
      return {
        'notify': true,
        'notify--nomargin': this.nomargin,
        [`notify--${this.notificationType}`]: true,
      }
    }
  }
}
