
import { PolicyStatusValue } from '@/models/PolicyStatus.ts'
import { CycleIcon } from '@/components/elements'
/**
 * The status indicator is a visual clue. It tells customers the current state of their policy.
 *
 * Typically one wants to accompany the indicator with a textual representation as well. This can
 * be done through permanent display; mouse over pop-out; or folding open additional information
 * in a table.
 *
 * 3.3.0 has introduced the molecules/StatusLegend which describes the indicators.
 *
 * @displayName Status Indicator
 */
export default {
  name: 'StatusIndicator',
  functional: true,
  components: {
    CycleIcon
  },
  props: {
    /**
     * Current status for corresponding policy
     * @values confirmed, in-progress, pending, error, on-hold
     */
    status: {
      type: String,
      default: 'confirmed',
      validator: (value) => Object.values(PolicyStatusValue).includes(value)
    }
  },
  render(h, context) {
    return h(CycleIcon, {
      attrs: { 'data-status': context.props.status },
      props: { icon: `status-${context.props.status}` }
    })
  }
}
