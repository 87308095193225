//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { addDays, format } from 'date-fns'

import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleDateInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { PaymentDetailsForm, ProvidedText, ProvidedHtml } from '@/components/organisms'

import { dictionaryMixin } from '@/mixins'

export default {
  name: 'HomeOwnerStepPayment',
  components: {
    CycleButton,
    CycleDateInput,
    CycleHeading,
    CycleFormSet,
    CycleFormPage,
    CycleInputContainer,
    ProvidedHtml,
    ProvidedText,
    PaymentDetailsForm
  },
  inject: ['formData', 'contentProvider'],
  mixins: [dictionaryMixin],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    startDate: undefined
  }),
  computed: {
    minStartDate() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    maxStartDate() {
      return format(addDays(new Date(), 365), 'yyyy-MM-dd')
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue == null) {
          return
        }
        this.$set(this.formData, 'startDate', newValue.startDate)
        this.$set(this.formData, 'paymentDetails', newValue.paymentDetails)
      },
      immediate: true
    },
    ['formData.startDate'](newValue) {
      this.startDate = newValue
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        paymentDetails: this.formData.paymentDetails,
        startDate: this.formData.startDate
      })
    }
  }
}
