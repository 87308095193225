//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { pick } from 'lodash'
import { labels, labelProps } from './labels'
import { logError } from '@/services/Logging'
import { toYYYYMMDD } from '@/services/DateTime'
import { jssMixin, intlMixin, dictionaryMixin } from '@/mixins'
import { invoicesContent } from './gql/queries'
import { TODAY, TWO_YEARS_AGO } from './constants'
import { BusyIndicator } from '@/components/molecules'
import { InvoicesTable } from '@/components/templates/Documents'

export default {
  name: 'CycleMyInvoicesSitecore',
  components: { InvoicesTable, BusyIndicator },
  mixins: [jssMixin, intlMixin, dictionaryMixin],
  props: {
    ...labelProps,
    nkcLink: {
      type: Boolean,
      required: false,
      default: false
    },
    numberOfLinesOnPage: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      documents: [],
      isLoading: true, // hide busy indicator by default
      pageNumber: 1, // current page number
      totalNumberOfLines: 0 // default total number of items
    }
  },
  computed: {
    textContent() {
      return {
        ...pick(this, labels)
      }
    }
  },
  beforeMount() {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData() {
      const variables = {
        inputModel: {
          startDate: toYYYYMMDD(TWO_YEARS_AGO),
          endDate: toYYYYMMDD(TODAY),
          pageNumber: `${this.pageNumber}`,
          numberOfLinesOnPage: this.numberOfLinesOnPage.toString()
        }
      }
      try {
        this.isLoading = true
        const response = await this.gql.query(invoicesContent, variables)
        this.documents = response.data.invoices.items
        this.totalNumberOfLines = response.data.invoices.result.totalNumberOfLines
      } catch (error) {
        logError(error)
      } finally {
        this.isLoading = false
      }
    },
    // Change pagenumber and fetch new data
    setPageNumber(number) {
      this.pageNumber = number
      this.fetchTableData()
    }
  }
}
