<template>
  <CycleContentBlock
    :contrast-color="false"
    :cta="cta"
    :centered="centered"
    :contrast="contrast"
    :bordered="bordered"
  >
    <slot v-for="slot in slotKeys" :name="slot" :slot="slot" />
  </CycleContentBlock>
</template>

<script>
import CycleContentBlock from '../cycle-content-block/CycleContentBlock'

export default {
  name: 'CyclePanel',
  components: { CycleContentBlock },
  props: {
    cta: {
      type: Boolean,
      required: false,
      default: false
    },
    centered: {
      type: Boolean,
      required: false,
      default: false
    },
    contrast: {
      type: Boolean,
      required: false,
      default: false
    },
    bordered: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    slotKeys() {
      return Object.keys(this.$slots)
    }
  }
}
</script>
