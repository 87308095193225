//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  CycleFormConfirmation,
  CycleInputContainer,
  CycleFormSet
} from '@/components/functional/forms'
import { CycleTextInput, CycleNumberInput } from '@/components/elements/forms'
import { CycleTooltip } from '@/components/cycle-tooltip'
import { ProvidedText, ProvidedTooltip, ProvidedHtml } from '@/components/organisms'
import { Address } from '@/models'

export default {
  name: 'CyclePostcodeCheck',
  components: {
    CycleTooltip,
    CycleFormConfirmation,
    CycleFormSet,
    ProvidedText,
    ProvidedTooltip,
    ProvidedHtml,
    CContainer: CycleInputContainer,
    CText: CycleTextInput,
    CNumber: CycleNumberInput
  },
  inject: ['formData', 'textContent'],
  props: {
    postalCodeFound: {
      type: Boolean,
      required: false
    },
    foundAddress: {
      type: Address
    },
    hasManualEntry: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: 'main'
    },
    isPostalCodeDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    manualEntry() {
      return !this.postalCodeFound
    },
    showStreetCityInputs() {
      if (!this.hasManualEntry) {
        return this.postalCodeFound
      }
      return this.postalCodeFound !== undefined
    },
    showErrorMessage() {
      return !this.hasManualEntry && this.postalCodeFound === false
    },
    validationKey() {
      return this.name
    },
    formattedPostalCode() {
      return this.formData[this.validationKey].postalCode
        .replace(/^(.{4})(.{2})$/, '$1 $2')
        .toUpperCase()
    },
    currentAddress() {
      return this.formData[this.validationKey]
    },
    isPostalCodeValid() {
      return this.currentAddress.postalCode && this.currentAddress.postalCode.length >= 6
    },
    isHouseNumberValid() {
      return this.currentAddress.houseNumber && this.currentAddress.houseNumber.length > 0
    }
  },
  watch: {
    foundAddress: {
      immediate: true,
      handler(value = new Address()) {
        this.$set(this.formData, this.validationKey, value)

        this.currentAddress.street = value.street
        this.currentAddress.city = value.city
      }
    },
    postalCodeFound: {
      handler(val) {
        if (!val) {
          this.currentAddress.street = ''
          this.currentAddress.city = ''
        }
      }
    }
  },
  methods: {
    handleHousenumberZipcodeInput() {
      if (this.isPostalCodeValid) {
        this.currentAddress.postalCode = this.formattedPostalCode
        if (this.isHouseNumberValid) {
          this.$emit('input', this.currentAddress)
        }
      }
      return
    },
    handleManualEntryAddress() {
      if (this.manualEntry) {
        this.$emit('input-manual', this.currentAddress)
      }
    }
  }
}
