//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { intlMixin } from '@/mixins'
import { CycleCurrency } from '@/filters'
import { CycleTextInput } from '@/components/elements/forms'
import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedHtml, ProvidedText, ProvidedTooltip } from '../Content'
import { PaymentDetails, PaymentTerm } from '@/models'

export default {
  name: 'PaymentDetailsForm',
  components: {
    CycleTextInput,
    CycleTooltip,
    CycleFormPage,
    CycleFormSet,
    CycleInputContainer,
    ProvidedHtml,
    ProvidedText,
    ProvidedTooltip
  },
  inject: ['formData'],
  filters: {
    CycleCurrency
  },
  mixins: [intlMixin],
  props: {
    paymentDetails: {
      type: PaymentDetails,
      required: true
    },
    existingCustomer: {
      type: Boolean,
      default: false
    },
    premiumInfo: {
      type: String,
      default: 'paymentDetails.content.premiumInfoProlongation'
    }
  },
  data: () => ({
    PaymentTerm
  }),
  watch: {
    paymentDetails: {
      immediate: true,
      handler(value) {
        this.$set(this.formData, 'paymentDetails', value)
      }
    }
  }
}
