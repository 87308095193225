//
//
//
//
//
//

import { services } from './constants'

export default {
  name: 'VideoContainer',
  data() {
    return {
      defaultService: 'youtube'
    }
  },
  props: {
    width: {
      type: [String, Object],
      default: '1920'
    },
    height: {
      type: [String, Object],
      default: '1080'
    },
    service: {
      type: String,
      default: 'youtube'
    },
    videoCode: {
      type: String
    },
    playerId: {
      type: String
    },
    accountId: {
      type: String
    }
  },
  computed: {
    videoUrl() {
      let serviceUrl = services?.[this.service]?.url || services?.[this.defaultService]?.url
      serviceUrl = serviceUrl.replace('[videoCode]', this.videoCode?.value || this.videoCode)
      if (this.playerId) {
        serviceUrl = serviceUrl.replace('[playerid]', this.playerId?.value || this.playerId)
      }
      if (this.accountId) {
        serviceUrl = serviceUrl.replace('[accountid]', this.accountId?.value || this.accountId)
      }
      return serviceUrl
    },
    defaultTags() {
      return services?.[this.service]?.defaultTags || services?.[this.defaultService]?.defaultTags
    },
    cssVars() {
      if (!isNaN(this.width) && !isNaN(this.height)) {
        return {
          '--aspect-ratio': this.width / this.height
        }
      }
    }
  }
}
