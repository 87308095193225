//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleFormPage } from '@/components/functional/forms'
import { CycleCurrency, CycleDate } from '@/filters'
import { dictionaryMixin, intlMixin } from '@/mixins'

export default {
  name: 'HomeOwnerStepOverview',
  components: { CycleFormPage },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [dictionaryMixin, intlMixin],
  inject: ['contentProvider', 'formData'],
  props: {
    overviewData: {
      required: true,
      type: Object
    },
    value: { type: Object, required: true }
  },
  computed: {
    isAbroad() {
      return this.formData.homelocation === false
    },
    riskAddressHasHouseNumberAddition() {
      return this.overviewData.riskAddress.houseNumberAddition
    },
    clientAddressHasHouseNumberAddition() {
      return this.overviewData.clientAddress.houseNumberAddition
    },
    isHouseVolumeManuallyFilled() {
      return !this.overviewData.isHouseVolumeRetrieved && this.overviewData.houseVolume
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', this.value)
    }
  }
}
