//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleCheckoutLabel } from './CycleCheckoutLabel'
import { CycleHeading } from '../../elements'
import { CycleFormSet } from '../forms/CycleFormSet'

export default {
  name: 'CycleCheckout',
  components: { CycleHeading, CycleFormSet, CycleCheckoutLabel },
  props: {
    heading: {
      type: String
    },
    hideCheckoutLabel: {
      type: Boolean,
      default: false
    },
    amount: {
      type: [String, Number]
    },
    amountDescription: {
      type: String
    },
    amountSubtext: {
      type: String
    }
  },
  data() {
    return {}
  }
}
