import { OverviewGroupModel, OverviewEntryTypes } from '../../../shared'

export function licenseholder({ textContent, formData }) {
  return new OverviewGroupModel({
    title: 'Gegevens kentekenhouder',
    entries: [
      {
        label: 'Naam',
        value: formData.licenseowner.fullName
      },
      {
        label: textContent.birthdateLabel,
        value: formData.licenseowner.birthdate,
        type: OverviewEntryTypes.DATE
      },
      {
        label: textContent.zipcodeLabel,
        value: formData.licenseowner.zipcode
      },
      {
        label: textContent.housenumberLabel,
        value: [formData.licenseowner.housenumber, formData.licenseowner.housenumberAddition]
          .join(' ')
          .trim()
      },
      {
        label: textContent.streetLabel,
        value: formData.licenseowner.street
      },
      {
        label: textContent.cityLabel,
        value: formData.licenseowner.city
      }
    ]
  })
}
