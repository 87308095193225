//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { isPolicyResultSuccess } from '@/services/PolicyResults'
import { CycleForm } from '@/components/functional/forms'

export default {
  name: 'FormContainer',
  components: {
    CycleForm
  },
  props: {
    storeNamespace: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function
    }
  },
  data() {
    return {
      page: Math.max(Number(this.$route.query.page || 1) - 1, 0)
    }
  },
  computed: {
    isCommunicating() {
      return this.$store.getters[`${this.storeNamespace}/isCommunicating`]
    }
  },
  mounted() {
    /**
     * This is quite the dirty watcher which has too much knowledge on what is happening
     * in our communication layer. Yet it seems to be the only reliable way at this point
     * to check whether the form is successful or not. It works for now and might need a
     * refactor when communications or requirements change.
     */
    this.$watch(
      `$store.state.${this.storeNamespace}.graphqlResults`,
      (results) => {
        if (results?.submitFunnel == null) {
          return
        }

        if (isPolicyResultSuccess(results.submitFunnel.write?.policyResults?.[0]?.result)) {
          this.$emit('submit-success')
        }
      },
      { deep: true }
    )
  },
  methods: {
    async proxySubmit(...args) {
      this.$emit('submit', ...args)
      await this.onSubmit(...args)
    },
    async handlePageView(page) {
      const { location } = this.$router.resolve(this.$route)
      const { query } = location

      this.page = page
      try {
        await this.$router.push({ ...location, query: { ...query, page } })
      } catch (e) {
        if (!Vue.config.silent) {
          console.warn('Route change failed: ', e.message || e)
        }
      }
      this.$emit('page-view', page)
    }
  }
}
