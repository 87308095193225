//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleHtml } from '@/components/base'
import { CycleCard } from '@/components/card'
import { CycleNav } from '@/components/cycle-nav'
import { CycleHeading } from '@/components/elements'
import { IbanPrivacy } from '@/components/molecules'
import { CycleButtonLink } from '@/components/cycle-link'
import ProvidedText from '../../Content/ProvidedText'
import { InformationLines, NamedInformation } from '@/components/atoms'

export default {
  inject: ['textContent', 'button'],
  name: 'BankAccount',
  components: {
    CycleHtml,
    CycleCard,
    CycleNav,
    CycleHeading,
    IbanPrivacy,
    InformationLines,
    NamedInformation,
    ProvidedText,
    CycleButtonLink
  },
  data: () => ({
    censored: true
  }),
  computed: {
    loading() {
      return this.loadingBankAccount > 0
    },
    hasBankAccount() {
      return this.bankAccount?.found
    },
    hasPaymentMethod() {
      return this.bankAccount?.paymentMethod?.value
    }
  },
  methods: {
    toggleCensor() {
      this.censored = !this.censored
    }
  },
  props: {
    loadingBankAccount: {
      type: Number,
      required: true,
    },
    bankAccount: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
