<template>
  <div class="documents__article" :class="{ open: isOpen }" @click="handleClick()">
    <div class="documents__status">
      <slot name="status"></slot>
    </div>
    <slot name="items" />
    <div :class="{ 'hide-S': isHidden }">
      <span v-if="!isHidden" class="documents__detail-toggle"></span>
    </div>
    <div class="documents__detail" v-if="!isHidden">
      <slot name="details" />
    </div>
  </div>
</template>

<script>
import { CycleIcon } from '@/components/elements'

export default {
  name: 'DocumentsItem',
  components: { CycleIcon },
  props: {
    isOpen: Boolean,
    isHidden: Boolean
  },
  methods: {
    handleClick() {
      this.$emit('open')
    }
  }
}
</script>
