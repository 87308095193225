//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BreadcrumbItem from './BreadcrumbItem.vue'
export default {
  name: 'BreadcrumbItemList',
  components: {
    BreadcrumbItem
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasBreadcrumbs() {
      return this.breadcrumbs?.length > 0
    }
  }
}
