<template>
  <DocumentClaims>
    <DocumentsHeading>
      <template #status>
        <div>
          {{ textContent.statusLabel }}
        </div>
      </template>
      <template>
        <div>{{ textContent.dateLabel }}</div>
        <div class="hide-S">{{ textContent.numberLabel }}</div>
        <div class="hide-S">{{ textContent.insuranceLabel }}</div>
        <div>{{ textContent.claimTypeLabel }}</div>
        <div></div>
      </template>
    </DocumentsHeading>
    <DocumentsItem v-for="(document, index) in documents" :key="index">
      <template #status>
        <StatusIndicator :status="document.dottype"></StatusIndicator>
      </template>
      <template #items>
        <div>{{ document.date }}</div>
        <div class="hide-S">{{ document.number }}</div>
        <div class="hide-S">{{ document.insurance }}</div>
        <div>{{ document.claimType }}</div>
      </template>
      <template #details>
        <InformationLines>
          <NamedInformation>
            <label>{{ textContent.statusLabel }}</label>
            {{ document.status }}
          </NamedInformation>
          <NamedInformation>
            <label>{{ textContent.amountLabel }}</label>
            {{ document.amount }}
          </NamedInformation>
          <NamedInformation show-on-mobile>
            <label>{{ textContent.numberLabel }}</label>
            {{ document.number }}
          </NamedInformation>
          <NamedInformation show-on-mobile>
            <label>{{ textContent.insuranceLabel }}</label>
            {{ document.insurance }}
          </NamedInformation>
        </InformationLines>
      </template>
    </DocumentsItem>
  </DocumentClaims>
</template>

<script>
import {
  StatusIndicator,
  InformationLines,
  NamedInformation,
  DocumentsHeading,
  DocumentClaims
} from '../../atoms'
import { DocumentsItem } from '../../molecules'

export default {
  name: 'ClaimsTable',
  components: {
    DocumentClaims,
    DocumentsHeading,
    DocumentsItem,
    StatusIndicator,
    NamedInformation,
    InformationLines
  },
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
