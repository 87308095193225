//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
 * REMARK: <a target="_self" disables the navigation interceptor and forces full refresh
 */

import { LooseLanguageSwitchLanguageOptions } from './constants.js'
export default {
  name: 'LanguageSwitch',
  props: {
    isForNavigation: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    languageSwitchItems() {
      return !this.hasLocalizedPaths
        ? []
        : this.isForNavigation
        ? this.localizedPaths.filter((a) => a.isVisibleInNavigation)
        : this.localizedPaths
    },
    localizedPaths() {
      return Object.entries(this.languages).map(([locale]) => {
        return {
          path: this.getPath(locale),
          label: this.getLabel(locale),
          title: this.getTitle(locale),
          class: this.getClassNameForLanguageSwitchItem(locale),
          isVisibleInNavigation: this.isForNavigation && locale !== this.currentLanguage,
          hreflang: this.getHrefLang(locale)
        }
      })
    },
    className() {
      const mainClassName = 'language-switch'
      return {
        [mainClassName]: true,
        [`${mainClassName}--navigation`]: this.isForNavigation
      }
    },
    currentLanguage() {
      return this.$store.getters['siteSettings/currentLanguage']
    },
    languages() {
      return this.$store.getters['siteSettings/availableLanguages']
    },
    hasLanguageSwitchItems() {
      return this.languageSwitchItems.some((a) => a)
    },
    hasLocalizedPaths() {
      return this.localizedPaths.some((a) => a)
    }
  },
  methods: {
    getClassNameForLanguageSwitchItem(locale) {
      const languageSwitchItemClassName = 'language-switch__item'
      return {
        [languageSwitchItemClassName]: true,
        [`${languageSwitchItemClassName}--active`]: locale === this.currentLanguage
      }
    },
    getLabel(locale) {
      return this.isForNavigation
        ? this.languages[locale].label
        : LooseLanguageSwitchLanguageOptions[locale]
    },
    getTitle(locale) {
      return this.isForNavigation
        ? this.languages[locale].title
        : LooseLanguageSwitchLanguageOptions[locale]
    },
    getHrefLang(locale) {
      return this.isForNavigation
        ? this.languages[locale].hrefLang
        : LooseLanguageSwitchLanguageOptions[locale]
    },
    getPath(locale) {
      return this.languages[locale]?.currentTargetPath
    }
  }
}
