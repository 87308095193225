<template>
  <CycleFormPage :on-submit="handleSubmit">
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="steps.personalData.title" />
      </CycleHeading>
    </template>

    <PersonalDataForm v-model="person" :disable-date-of-birth="true" />
    <ContactDetailsForm
      v-model="contactDetails"
      :is-verified-email-address="isVerifiedEmailAddress"
    >
      <template #postalcode>
        <slot name="postalcode" />
      </template>
    </ContactDetailsForm>

    <CycleInputContainer>
      <template #label>
        <ProvidedText name="newsletter.signUp.label" />
      </template>
      <CycleCheckboxInput v-model="approvedMailingList">
        <ProvidedText name="newsletter.signUp.value" />
      </CycleCheckboxInput>
    </CycleInputContainer>

    <template #next-button>
      <CycleButton modifiers="next" v-testable="'nextButton'">
        <ProvidedText name="steps.personalData.nextButton" />
      </CycleButton>
    </template>
    <template #prev-button="{ onClick }">
      <CycleButton
        :modifiers="['back', 'link']"
        @click="onClick"
        type="button"
        v-testable="'prevButton'"
      >
        <ProvidedText name="steps.personalData.backButton" />
      </CycleButton>
    </template>
  </CycleFormPage>
</template>

<script>
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleCheckboxInput } from '@/components/elements/forms'
import { CycleFormPage, CycleInputContainer } from '@/components/functional/forms'
import {
  ProvidedText,
  PersonalDataForm,
  ContactDetailsForm,
  DetailDataOverview
} from '@/components/organisms'
import { Address, Person, ContactDetails } from '@/models'
import { NotificationBar } from '@/components/atoms'

export default {
  name: 'HomeContentsStepPersonalData',
  components: {
    ContactDetailsForm,
    CycleButton,
    CycleCheckboxInput,
    CycleFormPage,
    CycleHeading,
    CycleInputContainer,
    PersonalDataForm,
    DetailDataOverview,
    ProvidedText,
    NotificationBar
  },
  props: {
    value: {
      type: Object,
      required: false
    },
    addressValue: {
      type: Address
    },
    personValue: {
      type: Person
    },
    contactDetailsValue: {
      type: ContactDetails
    },
    isVerifiedEmailAddress: {
      type: Boolean
    }
  },
  data() {
    return {
      address: new Address(),
      approvedMailingList: false,
      person: new Person(),
      contactDetails: new ContactDetails()
    }
  },
  watch: {
    addressValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.address = newValue
      }
    },
    personValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.person = newValue
      }
    },
    contactDetailsValue: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.contactDetails = newValue
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        address: this.address,
        person: this.person,
        contactDetails: this.contactDetails,
        approvedMailingList: this.approvedMailingList
      })
    }
  }
}
</script>
