<template>
  <div class="coreui coreui__main">
    <CycleSection>
      <template slot="header">
        <CycleHeading>The section heading</CycleHeading>
      </template>
    </CycleSection>
    <CycleSubGrid>
      <slot />
    </CycleSubGrid>
  </div>
</template>
<script>
import CycleSection from '../cycle-section/CycleSection'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import CycleSubGrid from '../cycle-section/CycleSubGrid'
export default {
  name: 'StoryBookCycleIframe',
  components: { CycleSubGrid, CycleHeading, CycleSection }
}
</script>
