import { cloneDeep } from 'lodash'

import { getService, serviceNames } from '../../services/data'
import { WRITE_POLICY } from './communication-types'
import {
  SET_COMMUNICATION_FAILURE,
  SET_COMMUNICATION_START,
  SET_COMMUNICATION_SUCCESS
} from '@/stores/communication/mutation-types'

const setFormData = ({ commit, dispatch, state }, { formData }) => {
  const oldState = { ...state }
  commit('setFormData', cloneDeep(formData))
  dispatch('processStateChanges', { newState: state, oldState })
}

const processStateChanges = (/* context, { newState, oldState } */) => {
  // override this action in a specific module to handle changes
}

const submitFunnel = async ({ state, getters, commit }, mapToInputModel) => {
  commit(SET_COMMUNICATION_START, { key: WRITE_POLICY })
  try {
    const result = await getService(serviceNames.FunnelService).writePolicy(
      mapToInputModel(state, getters),
      getters.formDefinition
    )
    commit('setGraphqlResult', { key: 'submitFunnel', data: result.data })
    commit(SET_COMMUNICATION_SUCCESS, { key: WRITE_POLICY, result })
  } catch (error) {
    commit(SET_COMMUNICATION_FAILURE, { key: WRITE_POLICY, reason: error })
  }
}

export default {
  processStateChanges,
  setFormData,
  submitFunnel
}
