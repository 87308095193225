//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleLink } from '@/components/cycle-link'
import { CycleList, CycleListItem } from '@/components/cycle-list'
import { CycleHtml } from '@/components/base'
import CycleHeading from '@/components/elements/CycleHeading/CycleHeading'

export default {
  name: 'CycleFooter',
  components: {
    CycleHtml,
    CycleLink,
    CycleList,
    CycleListItem,
    CycleHeading
  },
  props: {
    menus: {
      type: Array,
      required: true
    },
    terms: {
      type: Array,
      required: true
    },
    bodyText: {
      type: String,
      required: false,
      default: ''
    },
    logo: {
      type: Object,
      required: false
    }
  },
  computed: {
    hasTerms() {
      return this.terms?.length > 0
    },
    hasMenuItems() {
      return this.menus?.length > 0
    },
    logoSrc() {
      return this.logo?.src
    },
    logoAlt() {
      return this.logo?.alt
    },
    logoTitle() {
      return this.logo?.title || this.logo?.alt || this.logo?.name
    }
  },
  methods: {
    menuHasSubItems(menu) {
      return menu?.items?.length > 0
    }
  }
}
