//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ResponsivePicture } from '@/components/atoms'
import { CoreUIBreakpoints, ImageFocusDirections } from '@/constants'
import mapPictureData from './mapPictureData'

export default {
  name: 'CycleHeader',
  components: { ResponsivePicture },
  props: {
    myzone: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isCurved: {
      type: Boolean,
      default: false
    },
    isRational: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    defaultImageSize() {
      return CoreUIBreakpoints.medium
    },
    transformImagesObject() {
      return this.image?.pictures ? mapPictureData(this.image?.pictures) : {}
    },
    focus() {
      return this.image ? this.image.focus : ImageFocusDirections.left
    },
    imageAlt() {
      return this.image?.alt || this.image?.title || this.image?.name || ''
    },
    imageTitle() {
      return this.image?.title || this.image?.name || this.image?.alt || ''
    },
    classList() {
      const mainClassName = 'coreui__hero'
      return {
        [`${mainClassName}`]: true,
        [`${mainClassName}--curved`]: this.isCurved,
        [`${mainClassName}--compact`]: this.myzone,
        [`${mainClassName}--rational`]: this.isRational
      }
    }
  }
}
