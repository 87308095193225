//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uniq, merge } from 'lodash'

import { CycleHtml } from '../../../base'
import ModalTooltip from '@/components/molecules/ModalTooltip'
import { dictionaryMixin } from '../../../../mixins/dictionary'

export default {
  name: 'CycleInputContainer',
  components: { ModalTooltip, CycleHtml },
  mixins: [dictionaryMixin],
  props: {
    label: {
      type: String,
      required: false
    },
    validationLabel: {
      type: String,
      required: false
    },
    subtext: {
      type: String,
      required: false
    },
    tooltip: {
      type: String,
      required: false
    },
    renderInline: {
      type: Boolean,
      required: false
    },
    renderList: {
      type: Boolean,
      required: false
    },
    renderHorizontal: {
      type: Boolean,
      required: false
    },
    nolabel: {
      type: Boolean,
      required: false
    },
    survey: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showCustomErrorMessage: {
      type: Boolean,
      default: false
    },
    showSuccessMessage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      childComponents: []
    }
  },
  inject: ['formErrors', 'pageName'],
  computed: {
    activeErrors() {
      return Object.keys(this.ownErrors.$params).filter((e) => this.hasError(e))
    },
    anchorName() {
      if (!this.childComponents.length) {
        return null
      }

      return `form.${this.pageName}.${this.childComponents[0].name}`
    },
    childComponentVModels() {
      return uniq(this.childComponents.map((vm) => vm.nestedName))
    },
    defaultMessages() {
      return {
        required: this.phrase('input-validation-error-IVE001'),
        requiredIf: this.phrase('input-validation-error-IVE001'),
        alpha: this.phrase('input-validation-error-IVE002'),
        alphanum: this.phrase('input-validation-error-IVE003'),
        alphaExt: this.phrase('input-validation-error-IVE004'),
        graphqlSafe: this.phrase('input-validation-error-IVE005'),
        numeric: this.phrase('input-validation-error-IVE006'),
        email: this.phrase('input-validation-error-IVE008'),
        phone: this.phrase('input-validation-error-IVE009'),
        phoneDutch: this.phrase('input-validation-error-IVE009'),
        phoneInternational: this.phrase('custom-validation-error-CST001'),
        phoneDutchOrInternational: this.phrase('custom-validation-error-CST002'),
        postcode: this.phrase('input-validation-error-IVE010'),
        ibanDutch: this.phrase('input-validation-error-IVE016'),
        ibanOhi: this.phrase('input-validation-error-IVE016'),
        max: this.phrase('input-validation-error-IVE031'),
        min: this.phrase('input-validation-error-IVE032'),
        date: this.phrase('error-messages-funnels-008'),
        sameAs: this.phrase('input-validation-error-IVE035'),
        accept: this.phrase('error-messages-file-upload-invalid-file-format'),
        maxFileSize: this.phrase('error-messages-file-upload-invalid-file-size'),
        mustBeChecked: this.phrase('input-validation-error-IVE001'),
        validName: this.phrase('input-validation-error-IVE047'),
        basicTextWithNumbersAndSpecialCharacters: this.phrase('input-validation-error-IVE048')
      }
    },
    hasErrors() {
      return !!this.ownErrors
    },
    ownErrors() {
      return this.childComponentVModels.reduce((acc, elem) => {
        return this.mergeErrors(acc, this.formErrors(elem))
      }, {})
    },
    hasLabel() {
      return (
        this.label != null ||
        !!this.$slots.label ||
        !!(this.$scopedSlots.label && this.$scopedSlots.label())
      )
    }
  },
  methods: {
    registerChildComponent(component) {
      this.childComponents.push(component)
    },
    unRegisterChildComponent(component) {
      const componentIndex = this.childComponents.indexOf(component)

      if (componentIndex < 0) {
        return
      }

      this.childComponents.splice(componentIndex, 1)
    },
    mergeErrors(errorObj1, errorObj2) {
      return merge(errorObj1, errorObj2)
    },
    hasError(key) {
      return typeof this.ownErrors[key] !== 'undefined' && !this.ownErrors[key]
    }
  }
}
