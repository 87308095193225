//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isValid, parseISO } from 'date-fns'

import { dictionaryMixin } from '@/mixins'
import { logWarning } from '@/services/Logging'
import BaseInput from '../BaseInput'

const isValidDate = (date) => isValid(new Date(date))
const isValidDateValue = (dateString) => !isNaN(parseISO(dateString).getTime())

export default {
  name: 'CycleDateInput',
  mixins: [dictionaryMixin],
  extends: BaseInput,
  props: {
    value: {
      type: [String, Date, Object],
      default: null
    },
    dayPlaceholder: {
      type: String,
      default: 'dd'
    },
    monthPlaceholder: {
      type: String,
      default: 'mm'
    },
    yearPlaceholder: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      day: '',
      month: '',
      year: '',
      hasCorrectDateAndLocale: false,
      patternDay: '[0-9]{2}',
      patternMonth: '[0-9]{2}',
      patternYear: '[0-9]{4}',
      datePartElements: []
    }
  },
  computed: {
    dateString() {
      const hasDate = (this.year && this.month && this.day) || ''
      return (
        hasDate &&
        [this.year.padStart(4, '0'), this.month.padStart(2, '0'), this.day.padStart(2, '0')].join(
          '-'
        )
      )
    },
    stringValue() {
      if (!(this.value instanceof Date)) {
        return this.value || ''
      }
      if (isValidDate(this.value)) {
        return this.value.toISOString().split('T')[0]
      }
      return undefined
    },
    isValueValid() {
      return isValidDate(this.value)
    },
    isDateFilled() {
      return this.day !== '' && this.month !== '' && this.year !== ''
    },
    isAnyDatePartFilled() {
      return this.day !== '' || this.month !== '' || this.year !== ''
    },
    yearPlaceholderText() {
      return this.yearPlaceholder || this.phrase('myzone-forms-year', 'jjjj')
    },
  },
  watch: {
    value: {
      immediate: false,
      handler() {
        this.handleUpdate()
      }
    }
  },
  mounted() {
    this.datePartElements = [this.$refs.day, this.$refs.month, this.$refs.year]
    this.provision()
  },
  methods: {
    handleDayKeyup(e) {
      if (!isNaN(e.key) && e.target.value.length === 2) {
        // remark: somehow this events chatters and fires also at month
        //   used timeout to prevent this.
        setTimeout(() => this.$refs.month.focus(), 50)
      }
    },
    handleMonthKeyup(e) {
      if (!isNaN(e.key) && e.target.value.length === 2) {
        //   used timeout to prevent this.
        setTimeout(() => this.$refs.year.focus(), 50)
      }
    },
    handleSectionChange() {
      this.changeDate(this.dateString)
    },
    ariaInvalid(isInvalid) {
      if (isInvalid && !this.isDateFilled) {
        return
      }

      this.datePartElements.forEach((el) => {
        el.setAttribute('aria-invalid', isInvalid.toString())
      })
    },

    changeDate(date) {
      const isValid = isValidDateValue(date)
      if (isValid) {
        this.$emit('input', date)
      } else if (this.isAnyDatePartFilled) {
        this.$emit('input', undefined)
      } else {
        this.$emit('input', null)
      }

      this.ariaInvalid(!isValid)
    },

    updateValue() {
      if (this.isValueValid) {
        ;[this.year, this.month, this.day] = this.stringValue.split(/\W/)
        this.year = this.year.replace(/^[0]+/g, '') // correct leading zeroes
      } else {
        logWarning('date input cannot handle invalid date.', this.value)
      }

      // Simon made me do this -- RH 2020-04-26
      this.changeDate(this.dateString)
    },

    handleUpdate() {
      if (this.value == null) {
        return
      }

      this.updateValue()
    },

    provision() {
      if (this.value == null) {
        ;[this.year, this.month, this.day] = ['', '', '']
        return
      }

      this.updateValue()
    }
  }
}
