//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { CycleHeading } from '@/components/elements'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { CycleCurrency, CycleDate } from '@/filters'
import { ProductPremium, Coverages } from '../../models'
import { FamilyType } from '../../helpers/constants'
import { intlMixin } from '@/mixins'

export default {
  name: 'FunnelTravelStepOverviewMultiTrip',
  inject: ['formData', 'textContent'],
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    familyType: {
      required: false,
      type: String
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    coverages: {
      type: Coverages
    },
    startDate: {
      required: false,
      type: [String, Date]
    }
  },
  computed: {
    familyTypes() {
      return FamilyType
    },
    totalPremium() {
      return this.premium?.totalPremium
    },
    selectedCoverages() {
      const orderCoverages = [
        'baseCoverage',
        'luggage',
        'medicalCosts',
        'replacementTransportAndRepatriation',
        'accidents',
        'specialSports',
        'cancellation',
        'excessDeductibleRentalCar'
      ]
      if (this.premium && this.premium.coverages) {
        return Object.keys(this.premium.coverages)
          .sort((a, b) => {
            return orderCoverages.indexOf(a) - orderCoverages.indexOf(b)
          })
          .filter((key) => {
            return this.coverages[key]
          })
          .reduce((acc, coverage) => {
            return {
              ...acc,
              [coverage]: this.premium.coverages[coverage]
            }
          }, {})
      }
    }
  },
  methods: {
    optionCoverage(coverage) {
      const text = this.textContent.overview.coverages
      const optionalCoverages = ['cancellation', 'luggage', 'baseCoverage', 'accidents']
      const hasOption = optionalCoverages.includes(coverage)
      const option = `${coverage}Option`
      const optedValue = this.coverages[option]
      return hasOption ? ` (${text[coverage][optedValue]})` : ''
    },
    pageSubmit() {}
  }
}
