//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CycleReceiptGroup',
  props: {
    groupTitle: {
      type: String,
      required: false,
      default: ''
    },
    labelHeading: {
      type: String,
      required: false,
      default: ''
    },
    valueHeading: {
      type: String,
      required: false,
      default: ''
    }
  }
}
