//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleLink } from '../cycle-link'
import CycleHeading from '../elements/CycleHeading/CycleHeading'
import { CycleList, CycleListItem } from '../cycle-list/'
export default {
  name: 'CyclePanelBar',
  components: {
    CycleLink,
    CycleHeading,
    CycleList,
    CycleListItem
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: null
    }
  },
  computed: {
    filteredItems() {
      return this.items
        .filter(
          (item) => item.id && item.fields.Title && item.fields.Title.value && item.fields.items
        )
        .map((item) => {
          const id = item.id
          const title = item.fields.Title.value
          const items = item.fields.items
            .filter((linkItem) => linkItem.name && linkItem.fields.Link.value.href)
            .map((linkItem) => {
              const title = linkItem.name
              const href = linkItem.fields.Link.value.href
              const target =
                typeof linkItem.fields.Link.value.target === 'string'
                  ? linkItem.fields.Link.value.target
                  : null

              return {
                title,
                href,
                target
              }
            })

          return {
            title,
            items,
            id
          }
        })
    }
  }
}
