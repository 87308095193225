<template>
  <div :class="tagClass"><slot /></div>
</template>

<script>
export default {
  name: 'CycleTag',
  props: {
    inProgress: {
      type: Boolean,
      required: false,
      default: () => false
    },
    pending: {
      type: Boolean,
      required: false,
      default: () => false
    },
    rejected: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    tagClass() {
      const classconst = 'tag'
      return {
        [classconst]: true,
        [`${classconst}--inprogress`]: this.inProgress,
        [`${classconst}--pending`]: this.pending,
        [`${classconst}--rejected`]: this.rejected
      }
    }
  }
}
</script>
