//
//
//
//
//
//
//
//
//
//

import BaseInput from '../BaseInput'
export default {
  name: 'CycleNumberInput',
  extends: BaseInput,
  methods: {
    updateValue(event) {
      const maxLength = this.$el.getAttribute('maxlength')
      if (event.target.value.length > maxLength && !!maxLength){
        event.target.value = event.target.value.slice(0, maxLength)
      }
      this.$emit('input', event.target.value)
    }
  }
}
