<template>
  <li>
    <slot />
  </li>
</template>
<script>
export default {
  name: 'CycleListItem'
}
</script>
