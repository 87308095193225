<template>
  <div v-if="active">
    <CycleHtml :html="description" />
    <CycleInputContainer>
      <CycleRadioInput
        v-model="formData.finalQuestions[code]"
        :name="name"
        value="J"
        :label="labelYes"
        required
      />
      <CycleRadioInput
        v-model="formData.finalQuestions[code]"
        :name="name"
        value="N"
        :label="labelNo"
        required
      />
    </CycleInputContainer>
  </div>
</template>

<script>
import { CycleHtml } from '../../../../../base'
import { CycleInputContainer } from '../../../CycleInputContainer'
import { CycleRadioInput } from '../../../../../elements'
import { mixin } from './mixin'

export default {
  name: 'CycleFinalQuestionBoolean',
  components: {
    CycleHtml,
    CycleInputContainer,
    CycleRadioInput
  },
  mixins: [mixin],
  props: {
    labelYes: {
      type: String,
      required: false,
      default: 'Ja'
    },
    labelNo: {
      type: String,
      required: false,
      default: 'Nee'
    }
  }
}
</script>
