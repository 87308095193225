//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CycleTooltip } from '@/components/cycle-tooltip'
import { CycleHeading, CycleButton } from '@/components/elements'
import { CycleRadioInput } from '@/components/elements/forms'
import { CycleFormPage, CycleFormSet, CycleInputContainer } from '@/components/functional/forms'
import { ProvidedText, ProvidedTooltip } from '@/components/organisms'
import { CycleCurrency } from '@/filters'
import { intlMixin } from '@/mixins'

import { FamilyType, InsurableAmounts } from '../../constants'

export default {
  name: 'LiabilityStepSetup',
  inject: ['formData'],
  components: {
    CycleButton,
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    CycleInputContainer,
    CycleRadioInput,
    CycleTooltip,
    ProvidedText,
    ProvidedTooltip
  },
  filters: { CycleCurrency },
  mixins: [intlMixin],
  props: {
    value: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      familyType: null,
      insuredAmount: null
    }
  },
  computed: {
    familyTypes() {
      return FamilyType
    },
    insurableAmounts() {
      return InsurableAmounts
    }
  },
  watch: {
    value: {
      handler(newValue) {
        if (typeof newValue !== 'object') {
          return
        }

        this.familyType = newValue.familyType
        this.insuredAmount = newValue.insuredAmount
      },
      immediate: true
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        familyType: this.familyType,
        insuredAmount: this.insuredAmount
      })
    }
  }
}
