<template>
  <div class="piechart">
    <div class="piechart__segments">
      <div
        v-for="(segment, index) in styledSegments"
        :key="index"
        class="piechart__segment"
        :style="segment"
      />

      <div v-if="piechartLabel" class="piechart__mask">
        <h3 class="piechart__label piechart__label--highlighted">
          &euro; {{ piechartLabel.value }}
        </h3>
        <p class="piechart__label">{{ piechartLabel.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyclePieChart',
  props: {
    segments: {
      type: Array,
      required: true
    }
  },
  computed: {
    piechartLabel() {
      const lastIndex = this.segments.length - 1
      return this.segments[lastIndex] || null
    },
    totalValues() {
      return this.segments.reduce((acc, seg) => acc + seg.value, 0)
    },
    styledSegments() {
      return this.segments.reduce((acc, segment, index) => {
        return [
          ...acc,
          {
            '--bg': segment.color,
            '--value': (segment.value / this.totalValues) * 100,
            '--offset': index > 0 ? this.getOffset(acc, index - 1) : 0,
            '--over50': (segment.value / this.totalValues) * 100 > 50 ? 1 : 0
          }
        ]
      }, [])
    }
  },
  methods: {
    labelClass(label) {
      const _className = 'piechart__label'
      return {
        [_className]: true,
        [`${_className}--highlighted`]: label.isHighlighted
      }
    },
    getOffset(arr, index) {
      return arr[index]['--offset'] + arr[index]['--value']
    }
  }
}
</script>
