<template>
  <CycleFormPage
    :next-button-text="textContent.page6NextButton"
    :prev-button-text="textContent.page6PrevButton"
  >
    <template #header>
      <CycleHeading :weight="3">
        <ProvidedText name="overview.header" />
      </CycleHeading>
    </template>

    <CycleFormSet :heading="textContent.overview.general.heading">
      <dl>
        <dt><ProvidedText name="overview.general.traveltype" /></dt>
        <dd>
          <ProvidedText name="overview.general.traveltypeShort" />
        </dd>
        <dt><ProvidedText name="overview.general.householdComposition" /></dt>
        <dd>
          <ProvidedText
            name="householdCompositionSingle"
            v-if="familyTypes.Single === familyType"
          />
          <ProvidedText
            name="householdCompositionMultiple"
            v-if="familyTypes.Multiple === familyType"
          />
        </dd>

        <dt><ProvidedText name="overview.general.departureDate" /></dt>
        <dd>
          {{ departureDate | CycleDate({ locale }) }}
        </dd>

        <dt><ProvidedText name="overview.general.returnDate" /></dt>
        <dd>
          {{ returnDate | CycleDate({ locale }) }}
        </dd>

        <template v-for="(coveragePremium, coverageName, index) in selectedCoverages">
          <dt :key="index">
            <ProvidedText :name="`overview.general.${coverageName}`" />
            <span>{{ optionCoverage(coverageName) }}</span>
          </dt>
          <template>
            <dd :key="coverageName">
              {{ coveragePremium | CycleCurrency({ locale }) }}
            </dd>
          </template>

          <template v-if="coverageName === 'cancellation'">
            <dt :key="coverageName + 'bookingDate'">
              <ProvidedText name="overview.general.bookingDate" />
            </dt>
            <dd :key="coverageName + 'bookingDateValue'">
              {{ bookingDate | CycleDate({ locale }) }}
            </dd>

            <dt :key="coverageName + 'travelSum'">
              <ProvidedText name="overview.general.travelSum" />
            </dt>
            <dd :key="coverageName + 'travelSumValue'">
              {{ travelSum | CycleCurrency({ locale }) }}
            </dd>
          </template>
        </template>

        <dt><ProvidedText name="overview.general.paymentTermSingle" /></dt>
        <dd>
          <span v-if="paymentDetails">
            {{ paymentDetails.premium | CycleCurrency({ locale }) }}
          </span>
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet :heading="textContent.overview.personalData.heading">
      <dl>
        <dt><ProvidedText name="overview.personalData.salutation" /></dt>
        <dd>
          <ProvidedText name="salutationOptionMale" v-if="isMale" />
          <ProvidedText name="salutationOptionFemale" v-if="isFemale" />
        </dd>
        <dt><ProvidedText name="overview.personalData.initials" /></dt>
        <dd>
          {{ person.initials }}
        </dd>
        <template v-if="person.tussenvoegsel && person.tussenvoegsel.trim()">
          <dt><ProvidedText name="overview.personalData.tussenvoegsel" /></dt>
          <dd>
            {{ person.tussenvoegsel }}
          </dd>
        </template>
        <dt><ProvidedText name="overview.personalData.lastName" /></dt>
        <dd>
          {{ person.lastName }}
        </dd>
        <dt><ProvidedText name="overview.personalData.dateOfBirth" /></dt>
        <dd>
          {{ person.dateOfBirth | CycleDate({ locale }) }}
        </dd>
        <template v-if="address">
          <dt><ProvidedText name="overview.personalData.postalCode" /></dt>
          <dd>
            {{ address.postalCode }}
          </dd>
          <dt><ProvidedText name="overview.personalData.houseNumber" /></dt>
          <dd>
            <FullHouseNumber :address="address" />
          </dd>
          <dt><ProvidedText name="overview.personalData.street" /></dt>
          <dd>
            {{ address.street }}
          </dd>
          <dt><ProvidedText name="overview.personalData.city" /></dt>
          <dd>
            {{ address.city }}
          </dd>
        </template>
        <dt><ProvidedText name="overview.personalData.emailAddress" /></dt>
        <dd>
          {{ contactDetails.emailAddress }}
        </dd>
        <dt><ProvidedText name="overview.personalData.mailingList" /></dt>
        <dd>
          <ProvidedText name="overview.personalData.mailingListYes" v-if="approvedMailingList" />
          <ProvidedText name="overview.personalData.mailingListNo" v-else />
        </dd>
        <dt><ProvidedText name="overview.personalData.telephoneNumber" /></dt>
        <dd>
          {{ contactDetails.phoneNumber }}
        </dd>
      </dl>
    </CycleFormSet>

    <CycleFormSet :heading="textContent.overview.paymentDetails.heading">
      <dl>
        <dt><ProvidedText name="overview.paymentDetails.startDate" /></dt>
        <dd>
          {{ departureDate | CycleDate({ locale }) }}
        </dd>
        <dt><ProvidedText name="overview.paymentDetails.iban" /></dt>
        <dd>
          {{ paymentDetails.iban }}
        </dd>
        <dt><ProvidedText name="overview.paymentDetails.paymentTerm" /></dt>
        <dd>
          <ProvidedText name="overview.paymentDetails.paymentTermSingle" />
        </dd>
        <dt><ProvidedText name="overview.paymentDetails.paymentType" /></dt>
        <dd>
          <ProvidedText name="overview.paymentDetails.paymentAutomaticDebit" />
        </dd>
      </dl>
    </CycleFormSet>
    <slot />
  </CycleFormPage>
</template>

<script>
import { CycleFormPage, CycleFormSet } from '@/components/functional/forms'
import { CycleHeading } from '@/components/elements'
import { ProvidedText, ProvidedHtml } from '@/components/organisms'
import { Person, PaymentDetails, ContactDetails, Address } from '@/models'
import { CycleCurrency, CycleDate } from '@/filters'
import { ProductPremium, Coverages } from '../../models'
import { FamilyType } from '../../helpers/constants'
import { Gender } from '@/constants'
import { intlMixin } from '@/mixins'

export default {
  name: 'FunnelTravelStepOverviewSingleTrip',
  inject: ['formData', 'textContent'],
  components: {
    CycleFormPage,
    CycleFormSet,
    CycleHeading,
    ProvidedText,
    ProvidedHtml
  },
  filters: {
    CycleCurrency,
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    departureDate: {
      required: false,
      type: [String, Date]
    },
    returnDate: {
      required: false,
      type: [String, Date]
    },
    bookingDate: {
      required: false,
      type: [String, Date]
    },
    travelSum: {
      required: false,
      type: String
    },
    address: {
      required: false,
      type: Address
    },
    approvedMailingList: {
      required: true,
      type: Boolean
    },
    contactDetails: {
      required: true,
      type: ContactDetails
    },
    familyType: {
      required: false,
      type: String
    },
    person: {
      required: true,
      type: Person
    },
    paymentDetails: {
      required: true,
      type: PaymentDetails
    },
    premium: {
      required: false,
      type: ProductPremium
    },
    coverages: {
      required: true,
      type: Coverages
    }
  },
  computed: {
    familyTypes() {
      return FamilyType
    },
    isMale() {
      return this.person.gender === Gender.Male
    },
    isFemale() {
      return this.person.gender === Gender.Vemale
    },
    selectedCoverages() {
      const orderCoverages = [
        'baseCoverage',
        'luggage',
        'medicalCosts',
        'replacementTransportAndRepatriation',
        'accidents',
        'specialSports',
        'cancellation',
        'excessDeductibleRentalCar'
      ]
      if (this.premium && this.premium.coverages) {
        return Object.keys(this.premium.coverages)
          .sort((a, b) => {
            return orderCoverages.indexOf(a) - orderCoverages.indexOf(b)
          })
          .filter((key) => {
            return this.coverages[key]
          })
          .reduce((acc, coverage) => {
            return {
              ...acc,
              [coverage]: this.premium.coverages[coverage]
            }
          }, {})
      }
    }
  },
  methods: {
    optionCoverage(coverage) {
      const text = this.textContent.overview.coverages
      const optionalCoverages = ['luggage', 'accidents']
      const hasOption = optionalCoverages.includes(coverage)
      const option = `${coverage}Option`
      const optedValue = this.coverages[option]
      return hasOption ? ` (${text[coverage][optedValue]})` : ''
    },
    pageSubmit() {}
  }
}
</script>
